import { Navigate, Outlet } from "react-router-dom"

import { getFromLS } from "@flash-hros/utility"
import { useEmployeePermissions } from "../utils/permissions"

export const RoutesGuard = ({
  children,
  adminPages,
}: {
  children?: JSX.Element
  adminPages?: boolean
}) => {
  const accessToken = getFromLS("hrosAccessToken")
  const havePermission = useEmployeePermissions([
    "operations_view_card_orders",
    "operations_view_cards",
    "operations_create_card_order",
    "operations_create_batch_card_order",
  ])

  if (adminPages && !havePermission) {
    return <Navigate to={"/home"} />
  }

  if (!accessToken) {
    return <Navigate to={"/authentication/login"} />
  }

  return children ? children : <Outlet />
}
