import { gql } from "@apollo/client"

export const DELETE_CARD_DELIVERY_ADDRESS = gql`
  mutation DeleteCardDeliveryAddress(
    $deleteCardDeliveryAddress: DeleteCardDeliveryAddressInput!
  ) {
    deleteCardDeliveryAddress(
      deleteCardDeliveryAddress: $deleteCardDeliveryAddress
    ) {
      acknowledged
      deletedCount
    }
  }
`
