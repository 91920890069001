import { gql } from "@apollo/client"

export const CREATE_CARD_ORDER = gql`
  mutation CreateCardOrder($createCardOrder: CreateCardOrderInput!) {
    createCardOrder(createCardOrder: $createCardOrder) {
      externalCardOrderId
      status
      cardAmount
      createdAt
      deadline
    }
  }
`
