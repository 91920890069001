import { gql } from "@apollo/client"

export const DELETE_VIRTUAL_CARD_PCI = gql`
  mutation deleteVirtualCardPci(
    $deleteVirtualCardPci: Employee_DeleteVirtualCardPciInput!
  ) {
    deleteVirtualCardPci(
      deleteVirtualCardPci: $deleteVirtualCardPci
    )
  }
`
