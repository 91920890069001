import { Tag } from "@flash-tecnologia/hros-web-ui-v2"
import { CardStatusEnum } from "../interfaces"

export const CardStatusCell = ({ status }: { status: CardStatusEnum }) => {
  const cardStatusMap: Record<
    CardStatusEnum,
    { variant: "green" | "yellow" | "gray"; text: string }
  > = {
    [CardStatusEnum.active]: {
      variant: "green",
      text: "Ativo",
    },
    [CardStatusEnum.locked]: {
      variant: "yellow",
      text: "Bloqueado",
    },
    [CardStatusEnum.hardLocked]: {
      variant: "yellow",
      text: "Bloqueado",
    },
    [CardStatusEnum.canceled]: {
      variant: "gray",
      text: "Excluído",
    },
    [CardStatusEnum.inactive]: {
      variant: "gray",
      text: "Excluído",
    },
    [CardStatusEnum.disabled]: {
      variant: "gray",
      text: "Excluído",
    },
    [CardStatusEnum.lost]: {
      variant: "gray",
      text: "Excluído",
    },
  }
  return (
    <Tag variant={cardStatusMap[status].variant} hasLeftDotIcon={true}>
      {cardStatusMap[status].text}
    </Tag>
  )
}
