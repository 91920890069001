import gql from "graphql-tag"

export const GET_CARD_ORDERS = gql`
  query GetCompanyCardOrders(
    $filter: GetCardOrdersFilterInput
    $searchQuery: GetCardOrdersSearchQueryInput
    $limit: Int
    $skip: Int
    $sortBy: GetCardsOrdersSortByInput
    $providers: [String]
  ) {
    getCompanyCardOrders(
      limit: $limit
      skip: $skip
      filter: $filter
      searchQuery: $searchQuery
      sortBy: $sortBy
      providers: $providers
    ) {
      cardOrders {
        externalCardOrderId
        status
        cardAmount
        createdAt
        cardDeliveryInconsistency
      }
      totalCardOrders
    }
  }
` // TODO: Add deadline to the query

export interface CardOrder {
  externalCardOrderId: string;
  status: string;
  cardAmount: number;
  createdAt: string;
  deadline: string;
  cardDeliveryInconsistency: boolean;
}

export interface CardOrderData {
  getCompanyCardOrders: {
    cardOrders: CardOrder[];
    totalCardOrders: number;
  };
}
