import { useEffect, useState } from 'react'
import { isFeatureEnabled, UnleashFeatureEnum } from '../../services/unleash.service'

export const useIsNewCardsXpEnabled = () => {
  const [isEnabled, setIsEnabled] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const checkFeature = async () => {
      try {
        setIsLoading(true)
        const enabled = await isFeatureEnabled(
          UnleashFeatureEnum.FLASH_OS_XP_CARDS_NEW_CARDS_PAGE,
        )
        setIsEnabled(enabled)
      } catch (err) {
        setError(err instanceof Error ? err : new Error('An error occurred'))
      } finally {
        setIsLoading(false)
      }
    }

    checkFeature()
  }, [])

  return { isEnabled, isLoading, error }
}
