import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility"

export function useEmployeePermissions(permissionNameList: string[]) {
  const { selectedCompany } = useSelectedCompany()
  const userPermissions = usePermissions().companies.find(
    (c) => c.id === selectedCompany.id
  )?.permissions
  const haveSpecificPermissions = permissionNameList.some((permissionName) =>
    userPermissions?.includes(permissionName)
  )
  return (
    userPermissions &&
    (userPermissions.includes("*") || haveSpecificPermissions)
  )
}
