import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const ModalFooterStyled = styled.div`
  width: Fill (480px) px;
  height: Hug (72px) px;
  display: flex;
  flex-direction: row;
  padding: 12px 40px;
  gap: 0px;
  justify-content: space-between;
  align-items: baseline;
`

export const ModalHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`

export const ModalBodyStyled = styled.div``

export const TableHeaderContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
`

export const TableTitle = styled(Typography)`
  font-family: Cabinet Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #53464f;
`

export const StyledTableCell = styled.div`
  display: flex;
  justify-content: center;
  text-wrap: nowrap;
`

export const ActionsCellStyled = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  gap: 60px;
`
