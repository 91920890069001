import { gql } from "@apollo/client"

export const CREATE_CARD_DELIVERY_ADDRESS = gql`
  mutation CreateCardDeliveryAddress(
    $createCardDeliveryAddress: CreateCardDeliveryAddressInput!
  ) {
    createCardDeliveryAddress(
      createCardDeliveryAddress: $createCardDeliveryAddress
    ) {
      companyId
      externalAddressId
      addressName
      address
      addressNumber
      city
      complement
      neighborhood
      referencePoint
      state
      zipCode
    }
  }
`
