const THREE_DIGITS_REGEX = /^\d{0,3}$/
const FOUR_DIGITS_REGEX = /^\d{0,4}$/
const FOUR_NONCONSECUTIVE_DIGITS_REGEX = /^(?!.*(\d)\1)\d\d\d\d$/

export const hasNonConsecutiveDigits = (transactionPassword: string) => 
    FOUR_NONCONSECUTIVE_DIGITS_REGEX.test(transactionPassword)

export const hasFourDigits = (transactionPassword: string) =>
    FOUR_DIGITS_REGEX.test(transactionPassword)

export const hasThreeDigits = (cvv: string) =>
    THREE_DIGITS_REGEX.test(cvv)
