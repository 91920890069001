import { gql } from "@apollo/client"

export const CARD_LOCK_PCI = gql`
  mutation cardLockPci(
    $cardLockPci: Employee_CardLockPciInput!
  ) {
    cardLockPci(
      cardLockPci: $cardLockPci
    )
  }
`

export interface Employee_CardLockPciInput {
  printedId: String
  lockReason: String
  origin: String
  financeCardId: String
  transactionPassword: String
}
