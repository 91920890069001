import { useMutation } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useContext, useState } from "react"
import { ACTIVATE_CARD } from "../../../../api/mutations/activate-card"
import dispatchToast from "../../../../utils/dispatchToast"
import { MyCardsPageContext } from "../../Context/MyCardsContext"
import { TextInputContainerStyled } from "../styled"
import { hasFourDigits, hasNonConsecutiveDigits } from "../../../../utils/psswd"
import { ModalHeaderStyled } from "../../Tables/styled"
import { DescriptionStyled, InputLabelStyled, MessageStyled, ModalBodyStyled, ModalContainerStyled, ModalTitleStyled, WarningStyled, WarningTextStyled } from "./styled"
import { isVisa } from "../../../../utils/cardProviders"


export const ConfirmTransactionPasswordOnActivationModal = ({ onClose, onConfirm, getBack }) => {
  const { printedId, cvv, groupCardModel } = useContext(MyCardsPageContext)

  const [transactionPassword, setTransactionPassword] = useState("")

  const [
    activateCard,
    { loading: activateCardLoading, error: activateCardError },
  ] = useMutation(ACTIVATE_CARD, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      dispatchToast({
        type: "success",
        title: "Novo cartão físico ativado com sucesso",
        description:
          'Lembre-se: seu rosinha deve ser usado sempre na função "Crédito à vista"',
      })
      onConfirm()
    },
    onError: (error) => {
      // TODO: fazer mapeamento de erro para o toast
      dispatchToast({
        type: "error",
        title:
          "Parece que estamos enfrentando alguns problemas técnicos no momento",
        description: "Sentimos muito. Tente novamente mais tarde.",
      })
      onClose()
    },
  })

  const handleTransactionPasswordChange = (value: string) => {
    if (hasFourDigits(value)) {
      setTransactionPassword(value)
    }
  }

  return (
    <Modal.Root onClose={onClose} open={true}>
      <>
        <Modal.Content>
        <ModalHeaderStyled>
            <WarningStyled>
              <Icons
                name="IconAlertCircle"
                fill="transparent"
                color="orange"
                size={16}
              />
              <WarningTextStyled variant="body4">Atenção!</WarningTextStyled>
            </WarningStyled>
            <MessageStyled>
              <ModalTitleStyled variant="headline8">{title}</ModalTitleStyled>
              <DescriptionStyled>
                {description}
              </DescriptionStyled>
            </MessageStyled>
          </ModalHeaderStyled>

          <ModalContainerStyled>
            <ModalBodyStyled>
              <InputLabelStyled variant="headline9">
                {instructionTitle}
              </InputLabelStyled>
              <TextInputContainerStyled>
                <PasswordField
                  style={{ width: "422px" }}
                  label={transactionPasswordLabel}
                  onChange={({ target: { value } }) => {
                    handleTransactionPasswordChange(value)
                  }}
                  error={transactionPassword && !hasNonConsecutiveDigits(transactionPassword)}
                  helperText={transactionPasswordHelperText}
                  value={transactionPassword}
                ></PasswordField>
              </TextInputContainerStyled>
            </ModalBodyStyled>
          </ModalContainerStyled>
        </Modal.Content>
        <Modal.Footer style={{ paddingTop: "12px", marginTop: "12px" }}>
          <LinkButton variant="neutral" onClick={getBack}>
            <Icons name="IconArrowLeft"></Icons>
            {backButtonText}
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            onClick={() =>
              activateCard({
                variables: {
                  transactionPassword,
                  printedId,
                  ...(!isVisa(groupCardModel) && { cvv }),
                },
              })
            }
            disabled={!hasNonConsecutiveDigits(transactionPassword)}
            loading={activateCardLoading}
          >
            {buttonText}
            <Icons name="IconCheck"></Icons>
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const title = "Ativar novo cartão físico"
const description = "Ao ativar novo cartão físico, seu cartão antigo será cancelado e não poderá ser utilizado novamente."
const instructionTitle = "Insira sua senha de transação para confirmar a ação"
const transactionPasswordLabel = "Insira sua senha de transação"
const transactionPasswordHelperText =
"A mesma senha que você utiliza para realizar as compras físicas com o cartão e transações no app"
const backButtonText = "Voltar"
const buttonText = "Ativar novo cartão físico"
