import * as XLSX from "xlsx"
import {
  capitalizeWords,
  flatten,
  isValidCep,
  isValidCpf,
  isValidEmail,
  isValidPhone,
  sanitizedZipCode,
} from "../../../../../../../utils/helpers"
import { stateMap } from "../../../../../../../utils/maps"

interface SheetsError {
  errorMessage: string;
  row: number;
}

interface SanitizedOrders {
  deliveryAddress: {
    address: string;
    addressNumber: string;
    complement: string;
    city: string;
    neighborhood: string;
    referencePoint: string;
    state: string;
    zipCode: string;
  };
  cardAmount: number;
  recipientEmail: string;
  recipientName: string;
  recipientPhone: string;
  recipientDocument: string;
}

export interface SheetsValidation {
  isValid: boolean;
  errors?: SheetsError[];
  sanitizedOrders?: SanitizedOrders[];
}

interface CardOrdersSheetPayload {
  "QUANTIDADE DE CARTÕES*": string;
  "ENDEREÇO*": string;
  "NÚMERO*": string;
  COMPLEMENTO: string;
  "BAIRRO*": string;
  "CEP*": string;
  "CIDADE*": string;
  "ESTADO*": string;
  "PONTO DE REFERÊNCIA": string;
  "DESTINATÁRIO*": string;
  "E-MAIL DO DESTINATÁRIO*": string;
  "TELEFONE DO DESTINATÁRIO  (PREFERENCIALMENTE WHATSAPP)": string;
  "CPF DO DESTINATÁRIO": string;
}

interface ValidateCardOrders {
  validation: SheetsValidation;
}

const SKIPPED_LINES = 4
const HEADER_LINE = 3

const validationHeaders = [
  "QUANTIDADE DE CARTÕES*",
  "ENDEREÇO*",
  "BAIRRO*",
  "NÚMERO*",
  "COMPLEMENTO",
  "CEP*",
  "CIDADE*",
  "ESTADO*",
  "PONTO DE REFERÊNCIA",
  "DESTINATÁRIO*",
  "E-MAIL DO DESTINATÁRIO*",
  "TELEFONE DO DESTINATÁRIO  (PREFERENCIALMENTE WHATSAPP)",
]

export const validateCardOrdersWorksheet = async (
  data: string,
  cardQuantity: number,
): Promise<ValidateCardOrders> => {
  const workbook = XLSX.read(data, { type: "binary" })
  const [workSheetName] = workbook.SheetNames
  const workSheet = workbook.Sheets[workSheetName]
  const json = XLSX.utils.sheet_to_json<CardOrdersSheetPayload>(workSheet, {
    range: HEADER_LINE - 1,
    defval: "",
  })
  const result = validateCardOrdersJson(json, SKIPPED_LINES, cardQuantity)
  return {
    validation: result,
  }
}

const validateCardOrdersJson = (
  json: CardOrdersSheetPayload[],
  skippedLines: number,
  cardQuantity: number,
) => {
  const firstRowWithData = 1
  const orders = json.slice(firstRowWithData)
  const states = Object.keys(stateMap).map((stateBrazil) =>
    stateBrazil.toUpperCase()
  )

  const [sheetObservationsRow] = json
  const specificFieldsToMatch = ["QUANTIDADE DE CARTÕES*", "CEP*", "CIDADE*"]
  const matchedFields = specificFieldsToMatch.filter((fieldToMatch) => {
    return sheetObservationsRow[fieldToMatch] === "Campo obrigatório"
  })

  if (matchedFields.length === 0) {
    return {
      isValid: false,
      errors: [
        {
          row: skippedLines,
          errorMessage: "Linha de descrição dos campos não pode ser apagada.",
        },
      ],
    }
  }

  const sanitizedOrders: SanitizedOrders[] = []
  const errors = []

  const columnHeaders = Object.keys(json[0]).filter(
    (header) => !header.includes("EMPTY")
  )
  const missingHeaders = validationHeaders.filter(
    (headers) => !columnHeaders.includes(headers)
  )

  if (missingHeaders.length > 0) {
    errors.push({
      errorMessage: `A planilha deve conter os seguinte(s) título(s): ${missingHeaders.join(
        ", ",
      )}`,
      row: 3,
    })
  }
  let totalAmount = 0
  orders.forEach((row, index) => {
    const errorsPerRow: SheetsError[] = []

    try {
      const {
        ["QUANTIDADE DE CARTÕES*"]: cardAmount,
        ["ENDEREÇO*"]: address,
        ["BAIRRO*"]: neighborhood,
        ["NÚMERO*"]: addressNumber = "S/N",
        ["COMPLEMENTO"]: complement,
        ["CEP*"]: zipCode,
        ["CIDADE*"]: city,
        ["ESTADO*"]: state,
        ["PONTO DE REFERÊNCIA"]: referencePoint,
        ["DESTINATÁRIO*"]: recipientName,
        ["E-MAIL DO DESTINATÁRIO*"]: recipientEmail,
        ["TELEFONE DO DESTINATÁRIO  (PREFERENCIALMENTE WHATSAPP)"]:
          recipientPhone,
        ["CPF DO DESTINATÁRIO"]: recipientDocument,
      } = row

      totalAmount += Number(cardAmount)
      if (missingHeaders.length === 0) {
        try {
          const cardAmountTypeValidation = Number(cardAmount)
          const isCardAmountNan = isNaN(cardAmountTypeValidation)
          if (isCardAmountNan || cardAmountTypeValidation <= 0) {
            errorsPerRow.push({
              errorMessage: "Quantidade cartões deve ser maior que 0.",
              row: index + skippedLines + firstRowWithData,
            })
          }
        } catch {
          errorsPerRow.push({
            errorMessage: "Quantidade cartões deve ser maior que 0.",
            row: index + skippedLines + firstRowWithData,
          })
        }

        try {
          if (!state?.length || !states.includes(state.toUpperCase())) {
            errorsPerRow.push({
              errorMessage:
                "Digite um estado válido e/ou remova os caracteres especiais inválidos.",
              row: index + skippedLines + firstRowWithData,
            })
          }
        } catch {
          errorsPerRow.push({
            errorMessage:
              "Digite um estado válido e/ou remova os caracteres especiais inválidos.",
            row: index + skippedLines + firstRowWithData,
          })
        }

        if (!neighborhood?.length) {
          errorsPerRow.push({
            errorMessage: "Bairro é um campo obrigatório.",
            row: index + skippedLines + firstRowWithData,
          })
        }

        if (!city?.length) {
          errorsPerRow.push({
            errorMessage: "Cidade é um campo obrigatório.",
            row: index + skippedLines + firstRowWithData,
          })
        }

        if (!recipientName?.length) {
          errorsPerRow.push({
            errorMessage: "Destinatário é um campo obrigatório.",
            row: index + skippedLines + firstRowWithData,
          })
        }

        if (!address?.length) {
          errorsPerRow.push({
            errorMessage: "Endereço é um campo obrigatório.",
            row: index + skippedLines + firstRowWithData,
          })
        }

        if (!addressNumber?.toString()?.length) {
          errorsPerRow.push({
            errorMessage:
              "Nº de endereço é um campo obrigatório, caso não haja preencha o campo com S/N).",
            row: index + skippedLines + firstRowWithData,
          })
        }

        if (
          !recipientEmail?.length ||
          !isValidEmail(recipientEmail?.trim() || "")
        ) {
          errorsPerRow.push({
            errorMessage: "Digite um e-mail válido.",
            row: index + skippedLines + firstRowWithData,
          })
        }

        if (
          recipientPhone &&
          !isValidPhone(recipientPhone?.toString()?.trim() || "")
        ) {
          errorsPerRow.push({
            errorMessage: "O telefone deve seguir o padrão DD 99999-9999",
            row: index + skippedLines + firstRowWithData,
          })
        }

        try {
          const sanitizedCep = sanitizedZipCode(zipCode)

          if (!isValidCep(sanitizedCep)) {
            errorsPerRow.push({
              errorMessage: "Digite um CEP válido.",
              row: index + skippedLines + firstRowWithData,
            })
          }
        } catch {
          errorsPerRow.push({
            errorMessage: "Digite um CEP válido.",
            row: index + skippedLines + firstRowWithData,
          })
        }

        try {
          if (recipientDocument) {
            if (!isValidCpf(recipientDocument)) {
              errorsPerRow.push({
                errorMessage: "Digite um CPF válido.",
                row: index + skippedLines + firstRowWithData,
              })
            }
          }
        } catch (error) {
          errorsPerRow.push({
            errorMessage: "Digite um CPF válido.",
            row: index + skippedLines + firstRowWithData,
          })
        }
      }

      if (errorsPerRow.length === 0) {
        sanitizedOrders.push({
          deliveryAddress: {
            address: capitalizeWords(address?.replace(/\s{1,}|;/g, " ")),
            addressNumber: capitalizeWords(
              addressNumber?.toString().replace(/\s{1,}|;/g, " "),
            ),
            complement: capitalizeWords(
              complement?.toString().replace(/\s{1,}|;/g, " "),
            ),
            city: capitalizeWords(city?.toString().replace(/\s{1,}|;/g, " ")),
            neighborhood: capitalizeWords(
              neighborhood?.toString().replace(/\s{1,}|;/g, " "),
            ),
            referencePoint: capitalizeWords(
              referencePoint
                ?.toString()
                .slice(0, 255)
                .replace(/\s{1,}|;/g, " "),
            ),
            state: (state || "").toUpperCase(),
            zipCode,
          },
          cardAmount: Number(cardAmount),
          recipientEmail: recipientEmail?.trim(),
          recipientName: capitalizeWords(recipientName),
          recipientPhone: recipientPhone?.toString()?.trim(),
          recipientDocument: recipientDocument?.toString()?.trim(),
        })
      }
    } catch {
      errorsPerRow.push({
        row: index + skippedLines + firstRowWithData,
        errorMessage:
          "Erro processando a linha, verifique o documento novamente.",
      })
    }
    errors.push(errorsPerRow)
  })

  if (totalAmount !== cardQuantity) {
    errors.unshift([
      {
        row: 0,
        errorMessage: `A soma da quantidade de cartões (${totalAmount.toString()}) é incompatível com a inserida no formulário (${cardQuantity.toString()}).`,
      },
    ])
  }

  const result: Array<SheetsError> = flatten(errors)
  if (result.length > 0) {
    return {
      isValid: false,
      errors: result,
    }
  } else {
    return {
      isValid: true,
      sanitizedOrders,
    }
  }
}
