import styled from "styled-components"

export const ReportBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;

  gap: 24px;
`

export const ReportContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  gap: 24px;
`
