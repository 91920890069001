import { Box, Drawer } from "@mui/material"
import { getActiveCompanyFromLS } from "../../../utils/localStorage"
import { CardOrderProvider } from "../Stepper/CreateCardOrderStepper/contexts/CardOrderContext"
import { CreateCardOrderStepper } from "../Stepper/CreateCardOrderStepper/CreateCardOrderStepper"
import { DrawerTitle } from "./styled"

interface CreateCardOrderProps {
  open: boolean;
  toggleDrawer: (
    open: boolean,
    event?: React.KeyboardEvent | React.MouseEvent,
  ) => void;
}

export const CreateCardOrder = ({
  open,
  toggleDrawer,
}: CreateCardOrderProps) => {
  const onClose = () => {
    toggleDrawer(false)
  }

  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <Box sx={{ width: "700px" }}>
        <DrawerTitle>Solicitar Cartões</DrawerTitle>
        <CardOrderProvider>
          <CreateCardOrderStepper onClose={onClose} />
        </CardOrderProvider>
      </Box>
    </Drawer>
  )
}
