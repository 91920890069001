import styled from "styled-components"

export const CardsInfoText = styled.div`
  margin: 20px 50px;
  font-size: 18px;
  font-weight: 700;
  color: #15191c;
`

export const CardsInfoValue = styled.div`
  margin: 20px 50px;
  font-size: 18px;
  font-weight: 700;
  color: #15191c;
`

export const InfoCardsBox = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ededee;
  align-items: center;
  /* max-height: 65px; */
`

export const InputBox = styled.div`
  max-width: 175px;
  margin: 20px;
`

export const TypeOrderSelection = styled.div``

export const BoxSpace = styled.div`
  padding: 10px;
`
