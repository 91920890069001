import { IFetchProps } from "@flash-tecnologia/hros-web-ui-v2"
import { Filter } from "./components/TableFilters"
import { SearchQuery } from "./components/TableSearch"

export const pageSizeOptions = [
  {
    label: "10 por página",
    value: 10,
  },
  {
    label: "50 por página",
    value: 50,
  },
  {
    label: "100 por página",
    value: 100,
  },
]

export type TableUpdate =
  | {
      update: Filter;
      type: "filter";
    }
  | {
      update: SearchQuery;
      type: "search";
    }
  | {
      update: IFetchProps;
      type: "sorting";
    };
