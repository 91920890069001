import { useMutation } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useContext, useEffect, useState } from "react"
import { TOGGLE_NFC_PCI } from "../../../api/mutations/toggle-nfc-pci"
import dispatchToast from "../../../utils/dispatchToast"
import { CardOperationsContext } from "../Context/CardOperationsContext"
import { ModalHeaderStyled } from "../Tables/styled"
import {
  ForgotPasswordMessageStyled,
  ModalContentStyled,
  ModalDescriptionStyled,
  ModalMessageStyled,
  ModalTitleStyled,
  TextInputContainerStyled,
} from "./styled"
import { hasNonConsecutiveDigits, hasFourDigits } from "../../../utils/psswd"

export const NFC_MODAL = 'toggleNfc'

export const ToggleNfcPciModal = ({ handleOnCloseModals }) => {
  const {
    toggleNfcVariables,
    setToggleNfcVariables,
  } = useContext(CardOperationsContext)

  const [transactionPassword, setTransactionPassword] = useState(null)
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const [
    toggleNfcPciMutation, 
    { loading }
  ] = useMutation(
    TOGGLE_NFC_PCI,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        result 
          ? dispatchToast({
            type: "success",
            title: toggleNfcVariables.nfc
              ? "Compras por aproximação desativadas com sucesso"
              : "Compras por aproximação ativadas com sucesso",
          })
          : errorToast()
        handleOnClose(true)
      },
      onError: (error) => {
        handleOnClose(false)
        errorToast()
      }
    }
  )

  const errorToast = () =>
    dispatchToast({
      type: "error",
      title: toggleNfcVariables.nfc
        ? "Erro ao desativar compras por aproximação"
        : "Erro ao ativar compras por aproximação",
      description: "Tente novamente mais tarde"
    })

  const handleToggleNfcPciInfo = () => {
    toggleNfcPciMutation({
      variables: {
        cardNfcTogglePci: {
          enabled: !toggleNfcVariables.nfc,
          origin: "flashos",
          financeCardId: toggleNfcVariables.financeCardId,
          transactionPassword,
          ...(toggleNfcVariables.printedId && {
            printedId: toggleNfcVariables.printedId,
          }),
        },
      },
    })
  }

  const handleOnClose = (needRefetch: boolean) => {
    handleOnCloseModals(NFC_MODAL, needRefetch)
    setToggleNfcVariables(null)
    setTransactionPassword(null)
  }

  const handlePasswordChange = (value: string) => {
    if (hasFourDigits(value)) {
      setTransactionPassword(value)
    }
  }

  useEffect(() => {
    if (hasFourDigits(transactionPassword)) {
      setConfirmDisabled(false)
    }
  }, [transactionPassword])

  return (
    <Modal.Root onClose={() => handleOnClose(false)} open={true}>
      <>
        <ModalHeaderStyled>
          <ModalMessageStyled>
            <ModalTitleStyled variant="headline8">{toggleNfcVariables.nfc ? untoggleTitle : toggleTitle}</ModalTitleStyled>
            <ModalDescriptionStyled>{toggleNfcVariables.nfc ? untoggleTitleDescription : toggleTitleDescription}</ModalDescriptionStyled>
          </ModalMessageStyled>
        </ModalHeaderStyled>
        <Modal.Content>
          <ModalContentStyled>
            <TextInputContainerStyled>
              <PasswordField
                style={{ width: "422px" }}
                label={transactionPasswordInputLabel}
                onChange={({ target: { value } }) => {
                  handlePasswordChange(value)
                }}
                error={transactionPassword && !hasNonConsecutiveDigits(transactionPassword)}
                value={transactionPassword}
                helperText={transactionPasswordInputDescription}
              ></PasswordField>
            </TextInputContainerStyled>
            <ForgotPasswordMessageStyled variant="caption">
              {forgotPasswordLabel}
              <LinkButton variant={"default"} size="small">
                {forgetPasswordEmail}
              </LinkButton>
            </ForgotPasswordMessageStyled>
          </ModalContentStyled>
        </Modal.Content>
        <Modal.Footer>
          <LinkButton variant="default" onClick={() => handleOnClose(false)}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            disabled={confirmDisabled}
            loading={loading}
            onClick={handleToggleNfcPciInfo}
          >
            {confirmButton}
            <Icons
              name="IconCheck"
              fill="transparent"
              color="#ffffff"
              size={14}
            />
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const toggleTitle = "Ativar compras por aproximação"
const untoggleTitle = "Desativar compras por aproximação"
const toggleTitleDescription =
  "Ative essa função para permitir compras por aproximação no seu cartão."
const untoggleTitleDescription =
  "Desative essa função para não permitir compras por aproximação no seu cartão."
const transactionPasswordInputLabel = "Insira sua senha de transação"
const transactionPasswordInputDescription =
  "A mesma senha que você utiliza para realizar as compras físicas com o cartão e transações no app."
const forgotPasswordLabel =
  "Não lembra da sua senha? Entre em contato com o suporte pelo e-mail"
const forgetPasswordEmail = "falecom@flashapp.com.br"
const confirmButton = "Confirmar"
