import { Action } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types"

export enum CardStatusEnum {
  active = "active",
  locked = "locked",
  disabled = "disabled",
  hardLocked = "hardLocked",
  canceled = "canceled",
  inactive = "inactive",
  lost = "lost",
}

export enum BusinessUnitEnum {
  EXPENSE_MANAGEMENT = "EXPENSE_MANAGEMENT",
  BENEFITS = "BENEFITS",
  PHYSICAL = "PHYSICAL",
}

export enum AssociatedBalanceEnum {
  expense = "Corporativo",
  benefits = "Benefícios",
  physical = "Benefícios/Corporativo",
}

export enum CardProviderNameEnum {
  FLASH = "Flash",
  MASTER = "Master",
}

export type VirtualCardColumn = {
  provider: CardProviderNameEnum
  cardNumber: string
  nameOnCard: string
  expirationDate: string
  businessUnit: AssociatedBalanceEnum
  status: CardStatusEnum
  financeCardId: string
  cvv: string
  showPci: boolean
  international: boolean
  nfc: boolean
  bpCardId: string
  options: Action<VirtualCardColumn>[] 
}

export type PhysicalCardColumn = {
  provider: CardProviderNameEnum
  printedId: string
  nameOnCard?: string
  businessUnit: AssociatedBalanceEnum
  status: CardStatusEnum
  financeCardId: string
  international: boolean
  nfc: boolean
  options: Action<PhysicalCardColumn>[]
}
