import styled from "styled-components"

export const DrawerSubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-left: 30px;
  color: #83727d;
`

export const DrawerTitle = styled.div`
  margin: 30px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-wrap: break-word;

  font-size: 24px;
  font-weight: 700;

  gap: 24px;

  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`
