import { gql } from "@apollo/client"

export const ACTIVATE_CARD = gql`
  mutation ActivateCard(
    $printedId: String!
    $transactionPassword: String!
    $cvv: String
  ) {
    activateCard(
      printedId: $printedId
      transactionPassword: $transactionPassword
      cvv: $cvv
    ) {
      success
    }
  }
`
