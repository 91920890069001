import { useMutation } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useContext, useEffect, useState } from "react"
import dispatchToast from "../../../utils/dispatchToast"
import { ModalHeaderStyled } from "../Tables/styled"
import {
  ForgotPasswordMessageStyled,
  ModalContentStyled,
  ModalMessageStyled,
  ModalTitleStyled,
  ModalWarningStyled,
  ModalWarningTextStyled,
  TextInputContainerStyled,
} from "./styled"
import { CardOperationsContext } from "../Context/CardOperationsContext"
import { UPDATE_TRANSACTION_PSSWD } from "../../../api/mutations/update-transaction-password-pci"
import { hasNonConsecutiveDigits, hasFourDigits } from "../../../utils/psswd"

export const UpdateTransactionPasswordPciModal = () => {
  const {
    setUpdateTransacPsswdModalVisible,
    updateTransacPsswdVariables,
    setUpdateTransacPsswdVariables,
  } = useContext(CardOperationsContext)

  const [actualTransactionPassword, setActualTransactionPassword] = useState(null)
  const [newTransactionPassword, setNewTransactionPassword] = useState(null)
  const [confirmNewTransactionPassword, setConfirmNewTransactionPassword] = useState(null)
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const [
    updateTransactionPsswdPciMutation, 
    { loading }
  ] = useMutation(
    UPDATE_TRANSACTION_PSSWD,
    {
      onCompleted: (result) => {
        result 
          ? dispatchToast({
            type: "success",
            title: "Senha de transação alterada com sucesso",
          })
          : errorToast()
        handleOnClose()
      },
      onError: (error) => {
        setUpdateTransacPsswdModalVisible(false)
        errorToast()
      }
    }
  )

  const errorToast = () =>
    dispatchToast({
      type: "error",
      content: "Erro ao alterar a senha de transação. Tente novamente mais tarde",
    })

  const handleUpdateTransacPsswdPciInfo = () => {
    updateTransactionPsswdPciMutation({
      variables: {
        updateTransactionPasswordPci: {
          financeCardId: updateTransacPsswdVariables.financeCardId,
          oldTransactionPassword: actualTransactionPassword,
          newTransactionPassword,
        },
      },
    })
  }

  const handleOnClose = () => {
    setUpdateTransacPsswdModalVisible(false)
    setUpdateTransacPsswdVariables(null)
    setActualTransactionPassword(null)
    setNewTransactionPassword(null)
    setConfirmNewTransactionPassword(null)
  }

  const handleActualPasswordChange = (value: string) => {
    if (hasFourDigits(value)) {
      setActualTransactionPassword(value)
    }
  }

  const handleNewPasswordChange = (value: string) => {
    if (hasFourDigits(value)) {
      setNewTransactionPassword(value)
    }
  }

  const handleConfirmNewPasswordChange = (value: string) => {
    if (hasFourDigits(value)) {
      setConfirmNewTransactionPassword(value)
    }
  }

  const passwordMatches =
    newTransactionPassword === confirmNewTransactionPassword

  useEffect(() => {
    if (actualTransactionPassword && newTransactionPassword && confirmNewTransactionPassword) {
        if(hasNonConsecutiveDigits(newTransactionPassword) && hasNonConsecutiveDigits(confirmNewTransactionPassword) && passwordMatches) {
          setConfirmDisabled(false)
        } else {
          setConfirmDisabled(true)
        }
    }
  }, [actualTransactionPassword, newTransactionPassword, confirmNewTransactionPassword])
  
  return (
    <Modal.Root onClose={handleOnClose} open={true}>
      <>
        <ModalHeaderStyled>
          <ModalWarningStyled>
            <Icons
              name="IconAlertCircle"
              fill="transparent"
              color="orange"
              size={16}
            />
            <ModalWarningTextStyled variant="body4">
              Atenção!
            </ModalWarningTextStyled>
          </ModalWarningStyled>
          <ModalMessageStyled>
            <ModalTitleStyled variant="headline8">{title}</ModalTitleStyled>
          </ModalMessageStyled>
        </ModalHeaderStyled>
        <Modal.Content>
          <ModalContentStyled>
            <TextInputContainerStyled>
              <PasswordField
                style={{ width: "422px" }}
                label={actualTransactionPasswordLabel}
                onChange={({ target: { value } }) => {
                  handleActualPasswordChange(value)
                }}
                value={actualTransactionPassword}
                helperText={actualTransactionPasswordHelperText}
              ></PasswordField>
              <PasswordField
                style={{ width: "422px" }}
                label={createTransactionPasswordLabel}
                onChange={({ target: { value } }) => {
                  handleNewPasswordChange(value)
                }}
                value={newTransactionPassword}
                error={newTransactionPassword && !hasNonConsecutiveDigits(newTransactionPassword)}
                helperText={createTransactionPasswordHelperText}
              ></PasswordField>
              <PasswordField
                style={{ width: "422px" }}
                label={createTransactionPasswordConfirmLabel}
                onChange={({ target: { value } }) => {
                  handleConfirmNewPasswordChange(value)
                }}
                value={confirmNewTransactionPassword}
                error={confirmNewTransactionPassword && !passwordMatches}
                helperText={
                  confirmNewTransactionPassword && !passwordMatches &&
                  passwordMismatchMessage
                }
              ></PasswordField>
            </TextInputContainerStyled>
            <ForgotPasswordMessageStyled variant="caption">
              {forgotPasswordLabel}
              <LinkButton variant={"default"} size="small">
                {forgotPasswordEmail}
              </LinkButton>
            </ForgotPasswordMessageStyled>
          </ModalContentStyled>
        </Modal.Content>
        <Modal.Footer>
          <LinkButton variant="default" onClick={handleOnClose}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            disabled={confirmDisabled}
            loading={loading}
            onClick={handleUpdateTransacPsswdPciInfo}
          >
            {confirmButton}
            <Icons
                  name="IconCheck"
                  fill="transparent"
                  color="#ffffff"
                  size={14}
                />
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const title = "Alterar senha de transação"
const actualTransactionPasswordLabel = "Senha de transação atual"
const actualTransactionPasswordHelperText =
  "A mesma senha que você utiliza para realizar as compras físicas com o cartão e transações no app."
const createTransactionPasswordLabel = "Nova senha de transação"
const createTransactionPasswordConfirmLabel = "Confirmar nova senha de transação"
const createTransactionPasswordHelperText =
  "Deve ter 4 dígitos numéricos não consecutivos"
const forgotPasswordLabel =
  "Não lembra da sua senha? Entre em contato com o suporte pelo e-mail"
const forgotPasswordEmail = "falecom@flashapp.com.br"
const confirmButton = "Confirmar"
const passwordMismatchMessage = "As senhas não coincidem"
