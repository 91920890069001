import { useMutation } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useContext, useEffect, useState } from "react"
import { CARD_UNLOCK_PCI } from "../../../api/mutations/card-unlock-pci"
import dispatchToast from "../../../utils/dispatchToast"
import {
  CardOperationsContext,
  CardTypeEnum,
} from "../Context/CardOperationsContext"
import { ModalHeaderStyled } from "../Tables/styled"
import {
  ForgotPasswordMessageStyled,
  ModalContentStyled,
  ModalDescriptionStyled,
  ModalMessageStyled,
  ModalTitleStyled,
  ModalWarningStyled,
  ModalWarningTextStyled,
  TextInputContainerStyled,
} from "./styled"
import { hasNonConsecutiveDigits, hasFourDigits } from "../../../utils/psswd"

export const UNLOCK_MODAL = 'unlock'

export const CardUnlockPciModal = ({ handleOnCloseModals }) => {
  const {
    cardUnlockPciVariables,
    setCardUnlockPciVariables,
  } = useContext(CardOperationsContext)

  const [transactionPassword, setTransactionPassword] = useState(null)
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const [cardUnlockPciMutation, { loading }] = useMutation(CARD_UNLOCK_PCI, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      result
        ? dispatchToast({
            type: "success",
            title: "Cartão desbloqueado com sucesso",
          })
          : errorToast()
        handleOnClose(true)
      },
      onError: () => {
        handleOnClose(false)
        errorToast()
      }
    }
  )

  const errorToast = () =>
    dispatchToast({
      type: "error",
      title: "Erro ao desbloquear o cartão",
      description: "Tente novamente mais tarde"
    })

  const handleCardLockPciInfo = () => {
    cardUnlockPciMutation({
      variables: {
        cardUnlockPci: {
          origin: "flashos",
          financeCardId: cardUnlockPciVariables.financeCardId,
          transactionPassword,
          ...(cardUnlockPciVariables.printedId && {
            printedId: cardUnlockPciVariables.printedId,
          }),
        },
      },
    })
  }

  const handleOnClose = (needRefetch: boolean) => {
    handleOnCloseModals(UNLOCK_MODAL, needRefetch)
    setCardUnlockPciVariables(null)
    setTransactionPassword(null)
  }

  const handlePasswordChange = (value: string) => {
    if (hasFourDigits(value)) {
      setTransactionPassword(value)
    }
  }

  useEffect(() => {
    if (hasFourDigits(transactionPassword)) {
      setConfirmDisabled(false)
    }
  }, [transactionPassword])

  return (
    <Modal.Root onClose={() => handleOnClose(false)} open={true}>
      <>
        <ModalHeaderStyled>
          <ModalWarningStyled>
            <Icons
              name="IconAlertCircle"
              fill="transparent"
              color="orange"
              size={16}
            />
            <ModalWarningTextStyled variant="body4">Atenção!</ModalWarningTextStyled>
          </ModalWarningStyled>
          <ModalMessageStyled>
            <ModalTitleStyled variant="headline8">{cardUnlockPciVariables?.cardType === CardTypeEnum.physical ? physicalCardTitle : virtualCardTitle}</ModalTitleStyled>
            <ModalDescriptionStyled>{titleDescription}</ModalDescriptionStyled>
          </ModalMessageStyled>
        </ModalHeaderStyled>
        <Modal.Content>
          <ModalContentStyled>
            <TextInputContainerStyled>
              <PasswordField
                style={{ width: "422px" }}
                label={transactionPasswordInputLabel}
                onChange={({ target: { value } }) => {
                  handlePasswordChange(value)
                }}
                error={transactionPassword && !hasNonConsecutiveDigits(transactionPassword)}
                value={transactionPassword}
                helperText={transactionPasswordInputDescription}
              ></PasswordField>
            </TextInputContainerStyled>
            <ForgotPasswordMessageStyled variant="caption">
              {forgotPasswordLabel}
              <LinkButton variant={"default"} size="small">
                {forgetPasswordEmail}
              </LinkButton>
            </ForgotPasswordMessageStyled>
          </ModalContentStyled>
        </Modal.Content>
        <Modal.Footer>
          <LinkButton variant="default" onClick={() => handleOnClose(false)}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            disabled={confirmDisabled}
            loading={loading}
            onClick={handleCardLockPciInfo}
          >
            {confirmButton}
            <Icons
              name="IconCheck"
              fill="transparent"
              color="#4c2900"
              size={14}
            />
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const physicalCardTitle = "Desbloquear cartão"
const virtualCardTitle = "Desbloquear cartão virtual"
const titleDescription =
  "Ao desbloquear, seu cartão ficará liberado para transações."
const transactionPasswordInputLabel = "Insira sua senha de transação"
const transactionPasswordInputDescription =
  "A mesma senha que você utiliza para realizar as compras físicas com o cartão e transações no app."
const forgotPasswordLabel =
  "Não lembra da sua senha? Entre em contato com o suporte pelo e-mail"
const forgetPasswordEmail = "falecom@flashapp.com.br"
const confirmButton = "Confirmar"
