import { gql } from "@apollo/client"

export const CARD_UNLOCK_PCI = gql`
  mutation cardUnlockPci(
    $cardUnlockPci: Employee_CardUnlockPciInput!
  ) {
    cardUnlockPci(
      cardUnlockPci: $cardUnlockPci
    )
  }
`

export interface Employee_CardUnlockPciInput {
  printedId: String
  origin: String
  financeCardId: String
  transactionPassword: String
}
