import { gql } from "@apollo/client"

export const GET_CARDS = gql`
  query GetCards(
    $filter: GetCardsFilterInput
    $searchQuery: GetCardsSearchQueryInput
    $limit: Int
    $skip: Int
    $providers: [String]
  ) {
    getCards(
      limit: $limit
      skip: $skip
      filter: $filter
      searchQuery: $searchQuery
      providers: $providers
    ) {
      cards {
        cardId
        status
        cpf
        employeeName
        externalCardOrderId
      }
      totalCards
    }
  }
`

export type Card = {
  cardId: string;
  status: string;
  cpf: string;
  employeeName: string;
  externalCardOrderId: string;
};

export type CardData = {
  getCards: {
    cards: Card[];
    totalCards: number;
  };
};
