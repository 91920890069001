// import { Radio } from "@flash-tecnologia/hros-web-ui-v2";
import { FormControlLabel, RadioGroup, Radio } from "@mui/material"
import { useEffect, useState } from "react"
import { getActiveCompanyFromLS } from "../../../../../../../utils/localStorage"
import { useCardOrderContext } from "../../../contexts/CardOrderContext"
import { CardOrderInfoTitle } from "../styled"
import { TypeOrderSelection } from "./styled"

interface SelectTypeOfCardOrderProps {}

const SelectTypeOfCardOrder = (_: SelectTypeOfCardOrderProps) => {
  const [showSelection, setShowSelection] = useState(false)
  const { cardOrderType, handleChangeCardOrderType } = useCardOrderContext()
  useEffect(() => {
    const company = getActiveCompanyFromLS()
    if (company?.subOrdersEnabled) {
      setShowSelection(true)
    }
    if (!cardOrderType) {
      handleChangeCardOrderType("formOrder")
    }
  }, [])

  return (
    showSelection && (
      <TypeOrderSelection>
        <CardOrderInfoTitle margin="20px 0px 20px 0px">
          Modalidade de Entrega
        </CardOrderInfoTitle>
        <RadioGroup
          aria-labelledby="card-order-radio-buttons-group-label"
          name="card-order-radio-buttons-group"
          value={cardOrderType}
          onChange={(e) => handleChangeCardOrderType(e.target.value)}
        >
          <FormControlLabel
            value="formOrder"
            control={<Radio />}
            label="Envie meus cartões para um endereço único"
          />
          <FormControlLabel
            value="sheetsOrder"
            control={<Radio />}
            label="Envie meus cartões para diversos endereços"
          />
        </RadioGroup>
      </TypeOrderSelection>
    )
  )
}

export { SelectTypeOfCardOrder }
