import { gql } from "@apollo/client"

export const GET_EMPLOYEE_NAME = gql`
  query GetEmployeeName($employeeId: String!) {
    getEmployeeName(employeeId: $employeeId) {
      name
    }
  }
`

export type GetEmployeeNameData = {
  getEmployeeName: {
    name: string;
  };
};
