import gql from "graphql-tag"

export const GET_CARD_DELIVERY_ADDRESSES = gql`
  query GetCardDeliveryAddress(
    $searchQuery: GetCardDeliveryAddressSearchQueryInput
    $limit: Int
    $skip: Int
  ) {
    getCardDeliveryAddress(
      limit: $limit
      skip: $skip
      searchQuery: $searchQuery
    ) {
      addresses {
        companyId
        externalAddressId
        addressName
        address
        addressNumber
        city
        complement
        neighborhood
        referencePoint
        state
        zipCode
      }
    }
  }
`

export interface CardDeliveryAddress {
  companyId: string;
  externalAddressId: string;
  addressName: string;
  address: string;
  addressNumber: string;
  city: string;
  complement?: string;
  neighborhood: string;
  referencePoint?: string;
  state: string;
  zipCode: string;
}

export interface CardDeliveryAddressData {
  getCardDeliveryAddress: {
    addresses: CardDeliveryAddress[] | null;
  };
}
