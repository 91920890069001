import { useQuery } from "@apollo/client"
import {
  Button,
  Icons,
  Menu,
  PillButton,
  Table,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Action } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import {
  GET_EMPLOYEE_NAME,
  GetEmployeeNameData,
} from "../../../api/queries/get-employee-name"
import { GetEmployeePhysicalCardsData } from "../../../api/queries/get-employee-physical-cards"
import dispatchToast from "../../../utils/dispatchToast"
import { cardProviderToProviderNameMap } from "../../../utils/maps"
import { useDidUpdateEffect } from "../../../utils/react-hooks"
import {
  CardOperationsContext,
  CardTypeEnum,
} from "../Context/CardOperationsContext"
import { useMyCardsPageContext } from "../Context/MyCardsContext"
import { CardStatusEnum, PhysicalCardColumn } from "../interfaces"
import { ActivateFirstCardFlow } from "../Modals/ActivateFirstCardFlow/ActivateFirstCardFlow"
import { ACTIVATE_NEW_CARD_MODAL, ActivateNewCardFlow } from "../Modals/ActivateNewCardFlow/ActivateNewCardFlow"
import { CardHardUnlockModal } from "../Modals/CardHardUnlockModal"
import { CardLockPciModal, LOCK_MODAL } from "../Modals/CardLockPciModal"
import { INTERNATIONAL_MODAL, ToggleInternationalPciModal } from "../Modals/CardToggleInternationalPciModal"
import { NFC_MODAL, ToggleNfcPciModal } from "../Modals/CardToggleNfcPciModal"
import { CardUnlockPciModal, UNLOCK_MODAL } from "../Modals/CardUnlockPciModal"
import { UpdateTransactionPasswordPciModal } from "../Modals/UpdateTransactionPasswordPciModal"
import { CardProviderLogo } from "../Utils/CardProviderLogo"
import { CardStatusCell } from "../Utils/CardStatusCell"
import { getPhysicalCardsBusinessUnit } from "../Utils/physicalCardUtils"
import { StyledTableCell, TableTitle } from "./styled"

const disabledStatus = [CardStatusEnum.canceled, CardStatusEnum.inactive, CardStatusEnum.disabled, CardStatusEnum.lost]

export const MyPhysicalCardsTable = () => {
  const {
    setFetchConfigStateForPhysicalCardsTable,
    fetchConfigStateForPhysicalCardsTable,
    setPhysicalCardsPagination,
    physicalCardsPagination,
    selectedEmployeeId,
    getEmployeePhysicalCardsData,
    getEmployeePhysicalCardsLoading,
    refetchEmployeePhysicalCards,
  } = useMyCardsPageContext()

  const {
    cardLockPciPhysicalModalVisible,
    setCardLockPciPhysicalModalVisible,
    cardUnlockPciPhysicalModalVisible,
    setCardUnlockPciPhysicalModalVisible,
    setCardLockPciVariables,
    setCardUnlockPciVariables,
    cardHardUnlockModalVisible,
    setCardHardUnlockModalVisible,
    toggleInternationalPhysicalModalVisible,
    setToggleInternationalPhysicalModalVisible,
    setToggleInternationalVariables,
    toggleNfcPhysicalModalVisible,
    setToggleNfcPhysicalModalVisible,
    setToggleNfcVariables,
    setUpdateTransacPsswdVariables,
    setUpdateTransacPsswdModalVisible,
    updateTransacPsswdModalVisible
  } = useContext(CardOperationsContext)

  const navigate = useNavigate()

  const [isActivateFirstCardFlowVisible, setIsActivateFirstCardFlowVisible] =
    useState(false)

  const [isActivateNewCardFlowVisible, setIsActivateNewCardFlowVisible] =
    useState(false)

  const { data: getEmployeeNameData, loading: getEmployeeNameLoading } =
    useQuery<GetEmployeeNameData>(GET_EMPLOYEE_NAME, {
      fetchPolicy: "network-only",
      variables: {
        employeeId: selectedEmployeeId,
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro ao buscar os dados dos cartões.",
        })
      },
    })

  useDidUpdateEffect(() => {
    refetchEmployeePhysicalCards()
  }, [fetchConfigStateForPhysicalCardsTable])

  const refetchWithPagination = (pageSize: number, pageIndex: number) => {
    const limit = pageSize
    const skip = (pageIndex - 1) * pageSize

    setPhysicalCardsPagination({ pageNumber: pageIndex, pageSize })

    setFetchConfigStateForPhysicalCardsTable((previousState) => ({
      ...previousState,
      limit,
      skip,
    }))
  }

  const buildTableData = (
    data: GetEmployeePhysicalCardsData,
  ): PhysicalCardColumn[] => {
    const cards = data?.getEmployeePhysicalCards?.cards || []
    const hasBenefitsBalance = data?.getEmployeePhysicalCards?.hasBenefitsBalance
    const hasExpenseBalance = data?.getEmployeePhysicalCards?.hasExpenseBalance

    return cards.map((card) => ({
      ...card,
      options: [],
      provider: cardProviderToProviderNameMap[card.provider],
      businessUnit: getPhysicalCardsBusinessUnit(hasBenefitsBalance, hasExpenseBalance),
      nameOnCard: getEmployeeNameData?.getEmployeeName?.name?.toUpperCase() || "",
    }))
  }

  const handleCardLockPci = (data: PhysicalCardColumn) => {
    setCardLockPciVariables({
      printedId: data.printedId,
      financeCardId: data.financeCardId,
      cardType: CardTypeEnum.physical,
    })
    setCardLockPciPhysicalModalVisible(true)
  }

  const hanldeCardUnlockPci = (data: PhysicalCardColumn) => {
    setCardUnlockPciVariables({
      printedId: data.printedId,
      financeCardId: data.financeCardId,
      cardType: CardTypeEnum.physical,
    })
    setCardUnlockPciPhysicalModalVisible(true)
  }

  const hanldeCardHardUnlock = () => {
    setCardHardUnlockModalVisible(true)
  }

  const handleToggleInternationalPci = (data: PhysicalCardColumn) => {
    setToggleInternationalVariables({
      printedId: data.printedId,
      financeCardId: data.financeCardId,
      cardType: CardTypeEnum.physical,
      international: data.international,
    })
    setToggleInternationalPhysicalModalVisible(true)
  }

  const handleToggleNfcPci = (data: PhysicalCardColumn) => {
    setToggleNfcVariables({
      printedId: data.printedId,
      financeCardId: data.financeCardId,
      cardType: CardTypeEnum.physical,
      nfc: data.nfc,
    })
    setToggleNfcPhysicalModalVisible(true)
  }

  const handleUpdateTransacPsswdPci = (data: PhysicalCardColumn) => {
    setUpdateTransacPsswdVariables({
      financeCardId: data.financeCardId,
    })
    setUpdateTransacPsswdModalVisible(true)
  }

  const renderActions = (columnData: PhysicalCardColumn) => {
    const options: Action<PhysicalCardColumn>[] = []
    if(columnData.status === CardStatusEnum.active) {
      options.push({
        label: "Bloquear cartão",
        key: "",
        onClick: () => handleCardLockPci(columnData),
      })
    }

    if (columnData.status === CardStatusEnum.locked) {
      options.push({
        label: "Desbloquear cartão",
        key: "",
        onClick: () => hanldeCardUnlockPci(columnData),
      })
    }

    if (columnData.status === CardStatusEnum.hardLocked) {
      options.push({
        label: "Desbloquear cartão",
        key: "",
        onClick: () => hanldeCardHardUnlock(),
      })
    }

    if (!disabledStatus.includes(columnData.status)) {
      columnData.international
        ? options.push({
            label: "Desativar compras internacionais",
            key: "",
            onClick: () => handleToggleInternationalPci(columnData),
          })
        : options.push({
            label: "Ativar compras internacionais",
            key: "",
            onClick: () => handleToggleInternationalPci(columnData),
          })

      columnData.nfc
        ? options.push({
            label: "Desativar compras por aproximação",
            key: "",
            onClick: () => handleToggleNfcPci(columnData),
          })
        : options.push({
            label: "Ativar compras por aproximação",
            key: "",
            onClick: () => handleToggleNfcPci(columnData),
          })
    }

    options.push({
      label: "Ver extrato da conta",
      key: "",
      onClick: () => navigate(`/corporateCard/statement`),
    })

    return options
  }

  // Ideal sugerir a implementação deste comportamento no componente Table do DS
  const renderActionButton = (row: PhysicalCardColumn) => {
    const options = renderActions(row)
    if (options.length === 0) {
      return <span></span>
    }

    return (
      <Menu
        type="select"
        options={options.map((option) => ({
          key: option.key,
          onClick: () => option.onClick(row),
          children: (
            <MenuOption>
              {option.icon && <Icons name={option.icon} fill="transparent" />}
              {option.label}
            </MenuOption>
          ),
        }))}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 50, horizontal: -150 }}
      >
        <PillButton
          variant="default"
          size="small"
          icon="IconDotsVertical"
        />
      </Menu>
    )
  }

  const handleOnCloseModals = (modalName: string, needRefetch: boolean) => {
    switch(modalName) {
      case LOCK_MODAL: setCardLockPciPhysicalModalVisible(false)
      case UNLOCK_MODAL: setCardUnlockPciPhysicalModalVisible(false)
      case NFC_MODAL: setToggleNfcPhysicalModalVisible(false)
      case INTERNATIONAL_MODAL: setToggleInternationalPhysicalModalVisible(false)
      case ACTIVATE_NEW_CARD_MODAL: setIsActivateNewCardFlowVisible(false)
      default: break
    }

    if (needRefetch) {
      setFetchConfigStateForPhysicalCardsTable((previousState) => ({
        ...previousState
      }))
    }
  }

  const table = tableControllers.useTableColumns<PhysicalCardColumn>({
    defaultColumn: {
      minSize: 200,
    },
    total:
      getEmployeePhysicalCardsData?.getEmployeePhysicalCards?.totalCards || 0,
    columns: [
      {
        header: "Emissora",
        accessorKey: "provider",
        cell: ({ row }) => (
          <StyledTableCell>
            <CardProviderLogo provider={row.original.provider} />
          </StyledTableCell>
        ),
        size: 120,
      },
      {
        header: "Cartão",
        accessorKey: "printedId",
        size: 160,
      },
      {
        header: "Nome no cartão",
        accessorKey: "nameOnCard",
        minSize: 325,
      },
      {
        header: "Saldo Associado",
        accessorKey: "businessUnit",
        minSize: 300,
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => <CardStatusCell status={row.original.status} />,
        minSize: 215,
      },
      {
        header: "Ações",
        accessorKey: "options",
        cell: ({ row }) => renderActionButton(row.original),
        size: 93,
        sticky: "right",
      },
    ],
    data: buildTableData(getEmployeePhysicalCardsData),
    pagination: physicalCardsPagination,
    onPaginationChange: () => {},
  })

  return (
    <>
      <Table.Root>
        <MyPhysicalCardsTableContainerStyled>
          <TableTitle variant="headline8">Físico</TableTitle>
          <Button
            variant="primary"
            minWidth="244px"
            minHeight="40px"
            size="small"
            onClick={() => setIsActivateNewCardFlowVisible(true)}
          >
            Ativar novo cartão físico
            <Icons name="IconPlus"></Icons>
          </Button>
        </MyPhysicalCardsTableContainerStyled>
        <Table.Grid.Root
          loading={getEmployeePhysicalCardsLoading || getEmployeeNameLoading}
        >
          <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          {table.rows.map((row, index) => (
            <Table.Grid.Row key={index + row.id} row={row} />
          ))}
        </Table.Grid.Root>
        <Table.Pagination
          count={
            getEmployeePhysicalCardsData?.getEmployeePhysicalCards
              ?.totalCards || 0
          }
          onPaginationChange={({ pageSize, pageNumber }) =>
            refetchWithPagination(pageSize, pageNumber)
          }
          pagination={physicalCardsPagination}
          pageSizeOptions={pageSizeOptions}
        />
      </Table.Root>
      {cardLockPciPhysicalModalVisible && <CardLockPciModal handleOnCloseModals={handleOnCloseModals}/>}
      {cardUnlockPciPhysicalModalVisible && <CardUnlockPciModal handleOnCloseModals={handleOnCloseModals}/>}
      {cardHardUnlockModalVisible && <CardHardUnlockModal />}
      {toggleInternationalPhysicalModalVisible && <ToggleInternationalPciModal handleOnCloseModals={handleOnCloseModals} />}
      {toggleNfcPhysicalModalVisible && <ToggleNfcPciModal handleOnCloseModals={handleOnCloseModals}/>}
      {updateTransacPsswdModalVisible && <UpdateTransactionPasswordPciModal />}
      {isActivateFirstCardFlowVisible && (
        <ActivateFirstCardFlow
          onClose={() => setIsActivateFirstCardFlowVisible(false)}
        />
      )}
      {isActivateNewCardFlowVisible && (
        <ActivateNewCardFlow
          onClose={() => setIsActivateNewCardFlowVisible(false)}
          handleOnCloseModals={handleOnCloseModals}
        />
      )}
    </>
  )
}

const pageSizeOptions = [
  {
    label: "5 itens",
    value: 5,
  },
]

const MyPhysicalCardsTableContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px 12px 0px;
  gap: 0px;
`

const MenuOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`