import { BoxAdornment, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

interface ReportBoxInterface {
  title: JSX.Element;
  description: JSX.Element;
}

export const ReportBox = ({ title, description }: ReportBoxInterface) => (
  <CustomBoxAdornment
    title={title}
    description={description}
    leftAdornment
    style={{ borderColor: "var(--color-neutral-light2)", padding: "0" }}
  />
)

const CustomBoxAdornment = styled(BoxAdornment)`
  width: 320px;
  height: 120px;
`

export const BigNumber = ({ number }: { number: number }) => (
  <Typography
    variant="headline6"
    style={{ fontWeight: 800, paddingTop: "10px", color: "#53464F" }}
  >
    {number ?? "-"}
  </Typography>
)

export const ReportBoxDescription = ({
  description,
}: {
  description: string;
}) => (
  <Typography variant="body2" style={{ paddingTop: "5px", color: "#83727D" }}>
    {description}
  </Typography>
)
