import { useMutation } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useState } from "react"
import styled from "styled-components"
import { ACTIVATE_CARD } from "../../../../api/mutations/activate-card"
import { isVisa } from "../../../../utils/cardProviders"
import dispatchToast from "../../../../utils/dispatchToast"
import { useMyCardsPageContext } from "../../Context/MyCardsContext"
import { TextInputContainerStyled } from "../styled"

const FOUR_DIGITS_REGEX = /^\d{0,4}$/
const FOUR_NONCONSECUTIVE_DIGITS_REGEX = /^(?!.*(\d)\1)\d\d\d\d$/

export const CreateTransactionPasswordModal = ({
  onClose,
  onConfirm,
  onError,
}) => {
  const { printedId, cvv, groupCardModel } = useMyCardsPageContext()

  const [transactionPassword, setTransactionPassword] = useState("")
  const [transactionPasswordConfirmation, setTransactionPasswordConfirmation] =
    useState("")

  const [
    activateCard,
    { loading: activateCardLoading, error: activateCardError },
  ] = useMutation(ACTIVATE_CARD, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      dispatchToast({
        type: "success",
        title: "Cartão físico ativado com sucesso",
        description:
          'Lembre-se: seu rosinha deve ser usado sempre na função "Crédito à vista"',
      })
      onConfirm()
    },
    onError: (error) => {
      // TODO: fazer mapeamento de erro para o toast
      dispatchToast({
        type: "error",
        title:
          "Parece que estamos enfrentando alguns problemas técnicos no momento",
        description: "Sentimos muito. Tente novamente mais tarde.",
      })
      onError()
    },
  })

  const handleTransactionPasswordChange = (value: string) => {
    if (FOUR_DIGITS_REGEX.test(value)) {
      setTransactionPassword(value)
    }
  }

  const handleTransactionPasswordConfirmationChange = (value: string) => {
    if (FOUR_DIGITS_REGEX.test(value)) {
      setTransactionPasswordConfirmation(value)
    }
  }

  const isValidTransactionPassword = (transactionPassword: string) =>
    FOUR_NONCONSECUTIVE_DIGITS_REGEX.test(transactionPassword)

  const passwordMatches =
    transactionPassword === transactionPasswordConfirmation

  return (
    <Modal.Root onClose={onClose} open={true}>
      <>
        <Modal.Content>
          <ModalContainerStyled>
            <ModalTitleStyled variant="headline6">{title}</ModalTitleStyled>
            <ModalBodyStyled>
              <ModalDescriptionContainerStyled>
                <DescriptionHeaderStyled variant="headline8">
                  {descriptionHeader}
                </DescriptionHeaderStyled>
                <DescriptionBodyStyled variant="body3">
                  {descriptionBody}
                </DescriptionBodyStyled>
              </ModalDescriptionContainerStyled>
              <TextInputContainerStyled>
                <PasswordField
                  style={{ width: "422px" }}
                  label={createTransactionPasswordLabel}
                  onChange={({ target: { value } }) => {
                    handleTransactionPasswordChange(value)
                  }}
                  error={transactionPassword && !isValidTransactionPassword(transactionPassword)}
                  helperText={createTransactionHelperText}
                  value={transactionPassword}
                ></PasswordField>
              </TextInputContainerStyled>
              <TextInputContainerStyled>
                <PasswordField
                  style={{ width: "422px" }}
                  label={confirmTransactionPasswordLabel}
                  onChange={({ target: { value } }) => {
                    handleTransactionPasswordConfirmationChange(value)
                  }}
                  error={transactionPasswordConfirmation && !passwordMatches}
                  helperText={
                    transactionPasswordConfirmation &&
                    !passwordMatches &&
                    passwordMismatchMessage
                  }
                  value={transactionPasswordConfirmation}
                ></PasswordField>
              </TextInputContainerStyled>
            </ModalBodyStyled>
          </ModalContainerStyled>
        </Modal.Content>
        <Modal.Footer style={{ paddingTop: "12px", marginTop: "12px" }}>
          <LinkButton variant="neutral" onClick={onClose}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            onClick={() =>
              activateCard({
                variables: {
                  transactionPassword,
                  printedId,
                  ...(isVisa(groupCardModel) ? {} : { cvv }),
                },
              })
            }
            disabled={
              !(
                isValidTransactionPassword(transactionPassword) &&
                passwordMatches
              )
            }
            loading={activateCardLoading}
          >
            {buttonText}
            <Icons name="IconCheck"></Icons>
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const buttonText = "Continuar"
const title = "Ativar cartão físico"
const descriptionHeader = "Preencha os dados do cartão novo"
const descriptionBody =
  "Essa senha será utilizada para realizar as compras físicas com o cartão, transações no app e para validar alterações nos seus cartões."
const createTransactionPasswordLabel = "Criar senha de transação"
const createTransactionHelperText =
  "Deve ter 4 dígitos numéricos não consecutivos"
const confirmTransactionPasswordLabel = "Confirmar senha de transação"
const passwordMismatchMessage = "As senhas não coincidem."

const ModalContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ModalTitleStyled = styled(Typography)`
  font-family: Cabinet Grotesk;
  font-size: 38px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: #1d161b;
`

const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ModalDescriptionContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const DescriptionHeaderStyled = styled(Typography)`
  font-family: Cabinet Grotesk;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #53464f;
`

const DescriptionBodyStyled = styled(Typography)`
  font-family: Nunito;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6b5b66;
`
