import { useContext, useState } from "react"
import { MyCardsPageContext } from "../../Context/MyCardsContext"
import { ActivateNewCardIntroModal } from "./ActivateNewCardIntroModal"
import { ConfirmTransactionPasswordOnActivationModal } from "./ConfirmTransactionPassword"
import { ValidateCardModal } from "./ValidateCardModal"

export const ACTIVATE_NEW_CARD_MODAL = "ActivateNewCardModal"

export const ActivateNewCardFlow = ({ onClose, handleOnCloseModals }) => {
  const { setCvv, setTransactionPassword, setPrintedId } =
    useContext(MyCardsPageContext)

  const [flowStep, setFlowStep] = useState(1)

  const handleOnClose = () => {
    setTransactionPassword(undefined)
    setCvv(undefined)
    setPrintedId("")
    setFlowStep(0)
    onClose()
  }

  const handleOnConfirm = () => {
    setTransactionPassword(undefined)
    setCvv(undefined)
    setPrintedId("")
    setFlowStep(0)
    handleOnCloseModals(ACTIVATE_NEW_CARD_MODAL, true)
  }

  const handleComeBackStep = (actualStep: number) => {
    switch (actualStep) {
      case 1: {
        onClose()
        setFlowStep(0)
        break
      }
      case 2: {
        setCvv(undefined)
        setPrintedId("")
        setFlowStep(1)
        break
      }
      case 3: {
        setTransactionPassword(undefined)
        setFlowStep(2)
        break
      }
    }
  }

  return (
    <>
      {flowStep === 1 && (
        <ActivateNewCardIntroModal
          onClose={handleOnClose}
          onConfirm={() => setFlowStep(2)}
        />
      )}
      {flowStep === 2 && (
        <ValidateCardModal
          getBack={() => handleComeBackStep(2)}
          onClose={handleOnClose}
          onConfirm={() => setFlowStep(3)}
        />
      )}
      {flowStep === 3 && (
        <ConfirmTransactionPasswordOnActivationModal
          getBack={() => handleComeBackStep(3)}
          onClose={handleOnClose}
          onConfirm={handleOnConfirm}
        />
      )}
    </>
  )
}
