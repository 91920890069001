import { DownloadButton, Dropzone } from "@flash-tecnologia/hros-web-ui-v2"
import FileSaver from "file-saver"
import { FieldErrors, useFormContext } from "react-hook-form"
import { CARD_ORDER_TEMPLATE_FILE } from "../../../../../../../assets/encodedCardOrderTemplate"
import { getSheetsBytes } from "../../../../../../../utils/decode"
import { readFile } from "../../../../../../../utils/file-helper"
import { useCardOrderContext } from "../../../contexts/CardOrderContext"
import { ICreateCardOrderForm } from "../../../CreateCardOrderForm/createCardOrderForm.interfaces"
import {
  CardOrderInfoText,
  CardOrderInfoTitle,
} from "../../SelectCardOrderType/styled"

type SheetsCardOrderFields = "cardOrders";

export const SHEETS_CARD_ORDER_FIELDS: SheetsCardOrderFields[] = ["cardOrders"]

export const checkSheetsCardOrderFields = (
  errors: FieldErrors<ICreateCardOrderForm>,
) => {
  const result = Object.entries(errors).filter(
    ([error]) =>
      SHEETS_CARD_ORDER_FIELDS.indexOf(error as SheetsCardOrderFields) >= 0
  )

  return result.reduce((prev, [key, value]) => (prev[key] = value), {})
}

export const SheetsCardOrderForm = () => {
  const { setValue } = useFormContext()

  const { submittedSheets, handleSetSubmittedSheets } = useCardOrderContext()

  const handleDownload = () => {
    const byteArray = getSheetsBytes(CARD_ORDER_TEMPLATE_FILE)
    FileSaver.saveAs(
      new Blob(byteArray, { type: "application/vnd.ms-excel" }),
      "modelo_pedido_de_cartao.xlsx"
    )
  }

  return (
    <>
      <CardOrderInfoTitle>Dados da Entrega</CardOrderInfoTitle>
      <CardOrderInfoText>
        Baixe nosso modelo de planilha no link abaixo.
      </CardOrderInfoText>
      <DownloadButton
        fileName="modelo_pedido_de_cartao.xlsx"
        onClick={handleDownload}
      />
      <CardOrderInfoText>
        Preencha-o com os dados para a entrega, depois clique no box abaixo ou
        arraste para enviar.
      </CardOrderInfoText>
      <Dropzone
        title="Pedido de Cartão"
        onChange={async (e) => {
          if (e.length > 0) {
            const file = await readFile(e[0].file)
            setValue("cardOrders", file)
            handleSetSubmittedSheets(e)
          } else {
            handleSetSubmittedSheets([])
          }
        }}
        initialFiles={submittedSheets || []}
        onRemove={() => {
          setValue("cardOrders", undefined)
        }}
        style={{ padding: "5px" }}
      />
    </>
  )
}
