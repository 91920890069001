import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useState } from "react"
import styled from "styled-components"
import flashPhysicalCardImageSrc from "../../../assets/images/flash_physical_card.png"
import { ActivateFirstCardFlow } from "../Modals/ActivateFirstCardFlow/ActivateFirstCardFlow"

export const ActivateFirstCardScreen = () => {
  const [isActivateFirstCardFlowVisible, setIsActivateFirstCardFlowVisible] = useState(false)

  const handleActivateCardClick = () => {
    setIsActivateFirstCardFlowVisible(true)
  }

  return (
    <>
      <BackgroundContainerStyled>
        <ContentContainerStyled>
          <CardImageContainerStyled>
            <CardImageStyled
              src={flashPhysicalCardImageSrc}
              width={164}
              height={260}
              alt={`Cartão físico Flash`}
            />
          </CardImageContainerStyled>
          <RightSideContainerStyled>
            <MessageContainerStyled>
              <TitleStyled variant={"headline8"}>
                Ative seu novo cartão Flash!
              </TitleStyled>
              <TextStyled variant={"body4"}>
                Se você já está com o cartão em mãos, aproveite para ativá-lo agora. É bem rapidinho.
                Caso ainda não tenha, procure seu gestor para solicitar o seu cartão Flash.
              </TextStyled>
            </MessageContainerStyled>
            <ButtonContainerStyled>
              <Button
                variant={"primary"}
                style={{ width: "312px", height: "48px" }}
                onClick={handleActivateCardClick}
              >
                Ativar cartão físico
              </Button>
            </ButtonContainerStyled>
          </RightSideContainerStyled>
        </ContentContainerStyled>
      </BackgroundContainerStyled>
      {isActivateFirstCardFlowVisible && (
        <ActivateFirstCardFlow
          onClose={() => setIsActivateFirstCardFlowVisible(false)}
        />
      )}
    </>
  )
}

const BackgroundContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff5fa;
  height: 364px;
  min-width: 615px;
  border-radius: 10px;
  border-color: #ebe6e9;
  border-width: 1px;
  border-style: solid;
  padding-left: 52px;
  padding-right: 52px;
`

const ContentContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 508px;
  min-height: 260px;
  gap: 32px;
`

const CardImageContainerStyled = styled.div`
  height: 260px;
  line-height: 260px;
  text-align: center;
`

const CardImageStyled = styled.img`
  box-shadow: 0px 16px 40px 0px #FE2B8F52;
  border-radius: 15px;
`

const RightSideContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 44px;
  height: 214px;
  width: 312px;
`

const MessageContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const TitleStyled = styled(Typography)`
  font-family: Cabinet Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #53464f;
`

const TextStyled = styled(Typography)`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #83727d;
`

const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`