import {
  Button,
  Icons,
  LinkButton,
  Modal,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"
import { ModalHeaderStyled } from "../../Tables/styled"
import { ModalHightLightStyled, ModalHightLightTextStyled } from "../styled"

export const ActivateFirstCardIntroModal = ({ onClose, onConfirm }) => {
  return (
    <Modal.Root onClose={onClose} open={true}>
      <>
        <Modal.Content>
          <ModalHeaderStyled>
            <ModalHightLightStyled>
              <ShapeIcon
                variant="default"
                name="IconSparkles"
                size={48}
                style={{ color: "#f20d7a" }}
              />
              <ModalHightLightTextStyled variant="body4">
                Atenção!
              </ModalHightLightTextStyled>
            </ModalHightLightStyled>
            <ModalMessageStyled>
              <ModalTitleStyled variant="headline6">{title}</ModalTitleStyled>
              <ModalDescriptionStyled variant="body3">
                {description}
              </ModalDescriptionStyled>
            </ModalMessageStyled>
          </ModalHeaderStyled>
        </Modal.Content>
        <Modal.Footer style={{ paddingTop: "12px", marginTop: "12px" }}>
          <LinkButton variant="neutral" onClick={onClose}>
            Cancelar
          </LinkButton>
          <Button variant="primary" size="large" onClick={onConfirm}>
            {buttonText}
            <Icons name="IconCheck"></Icons>
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const title = "Ativar cartão físico"
const description =
  "Se você já está com o cartão em mãos, aproveite para ativá-lo agora. É bem rapidinho. "
const buttonText = "Continuar"

export const ModalMessageStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ModalTitleStyled = styled(Typography)`
  font-family: Cabinet Grotesk;
  font-size: 38px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: #1d161b;
`

export const ModalDescriptionStyled = styled(Typography)`
  font-family: Nunito Sans;
  font-weight: 400;
  text-align: left;
  color: #83727d;
`
