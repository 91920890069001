import { useLazyQuery } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"
import {
  CHECK_CARD_ON_ACTIVATION,
  CheckCardOnActivationReponse,
} from "../../../../api/queries/check-card-on-activation"
import { isVisa } from "../../../../utils/cardProviders"
import dispatchToast from "../../../../utils/dispatchToast"
import { useMyCardsPageContext } from "../../Context/MyCardsContext"
import { TextInputContainerStyled } from "../styled"

const THREE_DIGITS_REGEX = /^\d{0,3}$/

export const ValidateCardModal = ({
  onClose,
  onConfirm,
  onCardAlreadyActive,
}) => {
  const {
    selectedEmployeeId,
    printedId,
    setPrintedId,
    cvv,
    setCvv,
    groupCardModel,
  } = useMyCardsPageContext()

  const [checkCardOnActivation, { loading: checkCardOnActivationLoading }] =
    useLazyQuery<CheckCardOnActivationReponse>(CHECK_CARD_ON_ACTIVATION, {
      fetchPolicy: "network-only",
      onCompleted: ({ checkCardOnActivation }) => {
        if (!checkCardOnActivation.isValidCard) {
          dispatchToast({
            type: "error",
            title: checkCardOnActivation.error.title,
            description: checkCardOnActivation.error.message,
          })
          if (checkCardOnActivation.error.title === "Seu cartão já está ativo") {
            onCardAlreadyActive()
          }
          return
        }
        onConfirm()
      },
      onError: (error) => {
        dispatchToast({
          type: "error",
          title:
            "Parece que estamos enfrentando alguns problemas técnicos no momento",
          description: "Sentimos muito. Tente novamente mais tarde.",
        })
      },
    })

  const handlePrintedIdChange = (value: string) => {
    const trimmedValue = value.trim()
    if (trimmedValue.length < 9) {
      setPrintedId(trimmedValue)
    }
  }
  const handleCvvChange = (value: string) => {
    if (THREE_DIGITS_REGEX.test(value)) {
      setCvv(value)
    }
  }

  const isValidPrintedId = () => printedId?.length === 8
  const isValidCvv = () => cvv?.length === 3
  const isValidInput = () => isVisa(groupCardModel)
    ? isValidPrintedId()
    : isValidPrintedId() && isValidCvv()

  return (
    <Modal.Root onClose={onClose} open={true}>
      <>
        <Modal.Content>
          <ModalContainerStyled>
            <ModalTitleStyled variant="headline6">
              Ativar cartão físico
            </ModalTitleStyled>
            <ModalBodyStyled>
              <InputLabelStyled variant="headline8">
                Preencha os dados do cartão novo
              </InputLabelStyled>
              <TextInputContainerStyled>
                <PasswordField
                  style={{ width: "422px" }}
                  label="Número da conta"
                  onChange={({ target: { value } }) => {
                    handlePrintedIdChange(value)
                  }}
                  helperText="São os 8 números localizados abaixo do código QR do seu cartão"
                  error={printedId && !isValidPrintedId()}
                  value={printedId}
                ></PasswordField>
              </TextInputContainerStyled>
              {!isVisa(groupCardModel) && (
                <TextInputContainerStyled>
                  <PasswordField
                    style={{ width: "422px" }}
                    label="Código de verificação (CVV)"
                    onChange={({ target: { value } }) => {
                      handleCvvChange(value)
                    }}
                    error={cvv && !isValidCvv()}
                    helperText="O código de verificação (CVV) impresso no verso do seu cartão Flash"
                    value={cvv}
                  ></PasswordField>
                </TextInputContainerStyled>
              )}
            </ModalBodyStyled>
          </ModalContainerStyled>
        </Modal.Content>
        <Modal.Footer style={{ paddingTop: "12px", marginTop: "12px" }}>
          <LinkButton variant="neutral" onClick={onClose}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            onClick={() =>
              checkCardOnActivation({
                variables: {
                  printedId,
                  employeeId: selectedEmployeeId,
                  cvv,
                },
              })
            }
            disabled={!isValidInput()}
            loading={checkCardOnActivationLoading}
          >
            Continuar
            <Icons name="IconCheck"></Icons>
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const ModalContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ModalTitleStyled = styled(Typography)`
  font-family: Cabinet Grotesk;
  font-size: 38px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: #1d161b;
`

const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const InputLabelStyled = styled(Typography)`
  font-family: Cabinet Grotesk;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #53464f;
`
