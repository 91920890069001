import { gql } from "@apollo/client"

export const CHECK_EMPLOYEE_CORPORATE_CARD_ENABLED = gql`
  query CheckEmployeeCorporateCardEnabled {
    checkEmployeeCorporateCardEnabled {
      hasCorporateCardsEnabled
    }
  }
`

export type CheckEmployeeCorporateCardEnabledResponse = {
  checkEmployeeCorporateCardEnabled: {
    hasCorporateCardsEnabled: boolean;
  };
};
