import { useEffect, useState } from "react"
import { SheetsValidation } from "../../CreateCardOrderForm/sections/validateCardOrdersWorksheet"
import { CardOrderInfoTitle } from "../../SelectCardOrderType/styled"
import {
  AlertMessage,
  MessageContainer,
  MessageDisplay,
  MessageErrorRow,
  MessageErrors,
  MessageErrorTitle,
  MessageIconError,
  MessageIconSuccess,
} from "../../../../../../../components/AlertMessages/styled"

interface SheetCardOrderValidationProps {
  validation: SheetsValidation;
}

const getToastMessage = (isValid: boolean) =>
  isValid
    ? "Sua planilha está pronta para ser enviada!"
    : "Sua planilha precisa de ajustes :("

export const SheetCardOrderValidation = ({
  validation,
}: SheetCardOrderValidationProps) => {
  const [validationType, setValidationType] = useState("error")

  useEffect(() => {
    setValidationType(validation.isValid ? "success" : "error")
  }, [validation])

  return (
    <>
      <CardOrderInfoTitle>Validação</CardOrderInfoTitle>
      <MessageDisplay type={validationType}>
        <MessageContainer>
          {validation.isValid ? (
            <MessageIconSuccess name="IconCircleCheck" size={24} />
          ) : (
            <MessageIconError name="IconCircleX" size={24} />
          )}
          <AlertMessage type={validationType}>
            {getToastMessage(validation.isValid)}
          </AlertMessage>
        </MessageContainer>
      </MessageDisplay>
      {validation.errors && (
        <MessageErrors>
          <MessageErrorTitle>Atenção</MessageErrorTitle>
          {validation.errors.map((error, index) => (
            <MessageErrorRow key={`error_${index}_${error.row.toString()}`}>
              {error.row > 0
                ? `Linha ${error.row.toString()} - ${error.errorMessage}`
                : `${error.errorMessage}`}
            </MessageErrorRow>
          ))}
        </MessageErrors>
      )}
    </>
  )
}
