import {
  Auth,
  getAccessTokenPayloadSync,
} from "@flash-tecnologia/hros-web-utility"
import dispatchToast from "../utils/dispatchToast"

export const useEmployeeId = () => {
  const accessToken = getAccessTokenPayloadSync()

  if (!accessToken?.employeeId) {
    forceSignOut()
    return null
  }
  return accessToken.employeeId
}

const forceSignOut = () => {
  dispatchToast({
    content: "Sessão expirada. Por favor, realize o login novamente.",
    type: "error",
  })
  Auth.signOut()
}
