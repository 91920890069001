import styled from "@emotion/styled"
import { TagFilter, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useMyCardsPageContext } from "../Context/MyCardsContext"
import { BusinessUnitEnum, CardStatusEnum } from "../interfaces"

export const MyCardsFilters = () => {
  const {
    setFetchConfigStateForPhysicalCardsTable,
    setFetchConfigStateForVirtualCardsTable,
    setPhysicalCardsPagination,
    setVirtualCardsPagination,
  } = useMyCardsPageContext()

  const handleFilterUpdate = (
    filterName: string,
    filterValues: (string | number)[],
  ) => {
    const newFilters = {
      ...(filterName === "bu" &&
        filterValues && {
          businessUnit: filterValues,
        }),
      ...(filterName === "status" &&
        filterValues && {
          cardStatus: filterValues,
        }),
    }
    setFetchConfigStateForPhysicalCardsTable((previousState) => ({
      ...previousState,
      skip: 0,
      filter: {
        ...previousState.filter,
        ...newFilters,
      },
    }))
    setFetchConfigStateForVirtualCardsTable((previousState) => ({
      ...previousState,
      skip: 0,
      filter: {
        ...previousState.filter,
        ...newFilters,
      },
    }))
    setPhysicalCardsPagination({ pageNumber: 1, pageSize: 5 })
    setVirtualCardsPagination({ pageNumber: 1, pageSize: 5 })
  }

  const handleClearFilters = () => {
    setFetchConfigStateForPhysicalCardsTable((previousState) => ({
      ...previousState,
      skip: 0,
      filter: {},
    }))
    setFetchConfigStateForVirtualCardsTable((previousState) => ({
      ...previousState,
      skip: 0,
      filter: {},
    }))
    setPhysicalCardsPagination({ pageNumber: 1, pageSize: 5 })
    setVirtualCardsPagination({ pageNumber: 1, pageSize: 5 })
  }

  return (
    <MyCardsFiltersContainer>
      <MyCardsFilterTypography variant="body3">
        {" "}
        Filtrar por:{" "}
      </MyCardsFilterTypography>
      <StyledFilters>
        <TagFilter
          variant="secondary"
          filterLabel="Saldo Associado"
          hasLeftIcon={false}
          options={cardBUFilterOptions}
          optionIconType="checkbox"
          disableOptionsSearch={true}
          onApply={(values) => handleFilterUpdate("bu", values)}
          onClear={handleClearFilters}
        />
        <TagFilter
          variant="secondary"
          filterLabel="Status do Cartão"
          hasLeftIcon={false}
          options={cardStatusFilterOptions}
          optionIconType="checkbox"
          disableOptionsSearch={true}
          onApply={(values) => handleFilterUpdate("status", values)}
          onClear={handleClearFilters}
        />
      </StyledFilters>
    </MyCardsFiltersContainer>
  )
}

const cardBUFilterOptions = [
  {
    label: "Benefícios",
    value: BusinessUnitEnum.BENEFITS,
  },
  // This option is not currently available. Activate only when the benefits and corporate cards pages are merged
  // {
  //   label: "Corporativo",
  //   value: BusinessUnitEnum.EXPENSE_MANAGEMENT,
  // },
]

const cardStatusFilterOptions = [
  {
    label: "Ativo",
    value: CardStatusEnum.active,
  },
  {
    label: "Bloqueado",
    value: CardStatusEnum.locked,
  },
  {
    label: "Excluído",
    value: CardStatusEnum.disabled,
  },
]

const MyCardsFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  gap: 0px;
  justify-content: flex-start;
`

const MyCardsFilterTypography = styled(Typography)`
  color: #6b5b66;
`

const StyledFilters = styled.div`
  display: flex;
  padding: 8px 16px 8px 16px;
  gap: 8px;
`
