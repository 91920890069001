import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const ModalTitle = styled(Typography)`
  font-family: Cabinet Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #1d161b;
`

export const ModalSubTitle = styled(Typography)`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #6b5b66;
`

export const ModalContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TextInputContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const TexInputHelperStyled = styled(Typography)`
  padding-left: 16px;
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #83727d;
`

export const ForgotPasswordMessageStyled = styled(Typography)`
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #6b5b66;
`

export const ContactLinkStyled = styled(LinkButton)`
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
`

export const ModalWarningStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 112px;
  gap: 4px;
`

export const ModalWarningTextStyled = styled(Typography)`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #c96c01;
`

export const ModalHightLightStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

export const ModalHightLightTextStyled = styled(Typography)`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #f20d7a;
`

export const ModalMessageStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 112px;
  margin-bottom: 30px;
`

export const ModalTitleStyled = styled(Typography)`
  font-family: Cabinet Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #1d161b;
`

export const ModalDescriptionStyled = styled.div`
  justify-content: left;
  padding-right: 112px;
  gap: 8px;
`
