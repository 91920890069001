import { gql } from "@apollo/client"
import { CardProviderEnum } from "../../pages/globalInterfaces"
import {
  BusinessUnitEnum,
  CardStatusEnum,
} from "../../pages/MyCards/interfaces"

export const GET_EMPLOYEE_VIRTUAL_CARD_PCI = gql`
  query GetEmployeeVirtualCardPci(
    $financeCardId: String!
    $transactionPassword: String!
  ) {
    getEmployeeVirtualCardPci(
      financeCardId: $financeCardId
      transactionPassword: $transactionPassword
    ) {
      provider
      cardNumber
      nameOnCard
      expirationDate
      cvv
      businessUnit
      status
      financeCardId
    }
  }
`
export type GetEmployeeVirtualCardPciData = {
  getEmployeeVirtualCardPci: VirtualCardPciInfo
}

export type VirtualCardPciInfo = {
  provider: CardProviderEnum
  cardNumber: string
  nameOnCard: string
  expirationDate: string
  cvv: string
  businessUnit: BusinessUnitEnum
  status: CardStatusEnum
  financeCardId: string
}
