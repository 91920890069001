import styled from "styled-components"
import { MyCardsFilters } from "../Filters/MyCardsFilters"
import { MyPhysicalCardsTable } from "../Tables/MyPhysicalCardsTable"
import { MyVirtualCardsTable } from "../Tables/MyVirtualCardsTable"

export const MyCardsTablesScreen = () => {
  return (
    <>
      <MyCardsTablesScreenContainer>
        <MyCardsFilters />
        <div>
          <MyPhysicalCardsTable />
          <MyVirtualCardsTable />
        </div>
      </MyCardsTablesScreenContainer>
    </>
  )
}

const MyCardsTablesScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`
