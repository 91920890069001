import {
  Button,
  Icons,
  LinkButton,
  Modal,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Box } from "@mui/material"
import { useContext } from "react"
import styled from "styled-components"
import { ModalDescriptionStyled, ModalMessageStyled, ModalTitleStyled, ModalWarningStyled, ModalWarningTextStyled } from "./styled"
import { CardOperationsContext } from "../Context/CardOperationsContext"
import { ModalHeaderStyled } from "../Tables/styled"

export const CardHardUnlockModal = () => {
  const { setCardHardUnlockModalVisible } = useContext(CardOperationsContext)

  const handleOnClose = () => {
    setCardHardUnlockModalVisible(false)
  }

  return (
    <Modal.Root onClose={handleOnClose} open={true}>
      <>
        <ModalHeaderStyled>
          <ModalWarningStyled>
            <Icons
              name="IconAlertCircle"
              fill="transparent"
              color="orange"
              size={16}
            />
            <ModalWarningTextStyled variant="body4">Atenção!</ModalWarningTextStyled>
          </ModalWarningStyled>
          <ModalMessageStyled>
            <ModalTitleStyled variant="headline8">{title}</ModalTitleStyled>
            <ModalDescriptionStyled>{description}</ModalDescriptionStyled>
          </ModalMessageStyled>
        </ModalHeaderStyled>
        <Modal.Content>
          <ModalContentStyled>
            {contactSupportMessage + " "}
            <LinkButton variant={"default"} size="small">
              {emailSupport + " "}
            </LinkButton>
            ou via
            <LinkButton variant={"default"} size="small" href="LINKWPP">
              {" " + whatsappSupport}
              <Icons
                name="IconBrandWhatsapp"
                fill="transparent"
                color="#f20d7a"
                size={14}
              />
            </LinkButton>
          </ModalContentStyled>
        </Modal.Content>
        <Modal.Footer>
          <Box sx={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            width:"100%",
          }}>
            <Button
              variant="primary"
              size="large"
              onClick={handleOnClose}
            >
              {confirmButton}
              <Icons
                name="IconCheck"
                fill="transparent"
                color="#ffffff"
                size={14}
              />
            </Button>
          </Box>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const title = "Desbloquear cartão"
const description =
  "Não é possível seguir com o desbloqueio do seu cartão via plataforma. Entre em contato com o time de atendimento para realizar o desbloqueio."
const contactSupportMessage =
  "Entre em contato com o suporte pelo e-mail"
const emailSupport = "falecom@flashapp.com.br"
const whatsappSupport = "Whatsapp"
const confirmButton = "Ok, entendi"

const ModalContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2%;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: justify;
  color: #6b5b66;
  width: 110%;
`
