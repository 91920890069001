import { SearchField } from "@flash-tecnologia/hros-web-ui-v2"
import { useState } from "react"
import styled from "styled-components"

export interface SearchQuery {
  [key: string]: string;
}

export interface SearchQueryField {
  id: string;
  label: string;
  width?: string;
}

export const TableSearchContainer = styled.div`
  align-items: center;
`

export interface SearchInput {
  id: string;
  value: string;
}

export function TableSearch({
  fields,
  onSearch,
}: {
  fields: SearchQueryField[];
  onSearch: (search: SearchQuery) => void;
}) {
  const [searchState, setSearchState] = useState({})

  const handleSearch = (searchInput: SearchInput) => {
    const currentSearchState = searchState
    const newSearch = {}

    if (searchInput.value === "") {
      delete currentSearchState[searchInput.id]
    } else {
      newSearch[searchInput.id] = searchInput.value
    }

    const newSearchState = {
      ...currentSearchState,
      ...newSearch,
    }

    setSearchState(newSearchState)
    onSearch(newSearchState)
  }

  const searchList = fields
    ? fields?.map((field, index) => {
        return (
          <SearchField
            key={index}
            style={{
              marginBottom: "16px",
              ...(field.width && { width: field.width }),
            }}
            label={field?.label}
            onChange={(e) =>
              handleSearch({ id: field?.id, value: e.target.value })
            }
          />
        )
      })
    : []

  return <TableSearchContainer>{searchList}</TableSearchContainer>
}
