import { useLazyQuery } from "@apollo/client"
import { DataGrid } from "@flash-tecnologia/hros-web-ui-v2"
import { Skeleton } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { Card, CardData, GET_CARDS } from "../../../../api/queries/get-cards"
import {
  GridPagination,
  RefetchProps,
} from "../../../../components/GridPagination/GridPagination"
import { capitalizeWords, cpfMask } from "../../../../utils/helpers"
import { CardsPageContext } from "../../Context/CardsContext"
import { pageSizeOptions, TableUpdate } from "../common"
import { FilterField, TableFilters } from "../components/TableFilters"
import { SearchQueryField, TableSearch } from "../components/TableSearch"
import { TableContainer } from "../styles"

export const CardsTable = () => {
  const { groupCardModel } = useContext(CardsPageContext)
  const INITIAL_PAGE_SIZE = 10
  const initialFetchConfigState = {
    searchQuery: {},
    filter: {},
    limit: INITIAL_PAGE_SIZE,
    skip: 0,
    sortBy: {
      createdAt: "desc",
    },
    providers: groupCardModel?.cardProviders,
  }
  const [fetchConfigState, setFetchConfigState] = useState(
    initialFetchConfigState
  )
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE)
  const [selectedPage, setSelectedPage] = useState(1)

  const [getCards, { data, loading: loadingGetCards }] = useLazyQuery<CardData>(
    GET_CARDS,
    {
      fetchPolicy: "network-only",
      variables: { ...initialFetchConfigState },
    }
  )

  const handleTableUpdate = (tableUpdate: TableUpdate) => {
    setSelectedPage(1)
    setFetchConfigState((previousState) => ({
      ...previousState,
      limit: pageSize,
      skip: 0,
      providers: groupCardModel?.cardProviders,
      ...(tableUpdate.type === "filter" && { filter: tableUpdate.update }),
      ...(tableUpdate.type === "search" && { searchQuery: tableUpdate.update }),
    }))
  }

  const builtTableData = (data: CardData) => {
    if (data?.getCards?.cards.length > 0) {
      const {
        getCards: { cards },
      } = data
      return cards.map((cardOrder: Card) => {
        return {
          cardId: cardOrder.cardId || "-",
          status: cardOrder.status || "-",
          cpf: cardOrder.cpf ? cpfMask(cardOrder.cpf) : "-",
          employeeName: cardOrder.employeeName
            ? capitalizeWords(cardOrder.employeeName)
            : "-",
          externalCardOrderId: cardOrder.externalCardOrderId || "-",
        }
      })
    }
    return []
  }

  const onPageClick = (page: number) => {
    setSelectedPage(page)
    refetchWithPagination({ pageIndex: page, pageSize })
  }

  const onRowsPerPageClick = (itemsPerPage: number) => {
    setSelectedPage(1)
    setPageSize(itemsPerPage)
    refetchWithPagination({ pageIndex: 1, pageSize: itemsPerPage })
  }

  const refetchWithPagination = ({ pageIndex, pageSize }: RefetchProps) => {
    const limit = pageSize
    const skip = (pageIndex - 1) * pageSize

    setPageSize(pageSize)
    setFetchConfigState((previousState) => ({
      ...previousState,
      limit,
      skip,
    }))
  }

  useEffect(() => {
    getCards({
      variables: fetchConfigState,
    })
  }, [fetchConfigState])

  return (
    <TableContainer>
      <TableSearch
        fields={searchFields}
        onSearch={(search) =>
          handleTableUpdate({ type: "search", update: search })
        }
      />
      <TableFilters
        fields={tableFilters}
        onFilterUpdate={(filter) =>
          handleTableUpdate({ type: "filter", update: filter })
        }
      />
      {loadingGetCards ? (
        <Skeleton
          variant="rectangular"
          style={{
            marginBottom: "40px",
            borderRadius: "20px",
            maxHeight: "828px",
            display: "flex",
            flexGrow: 1,
          }}
        />
      ) : (
        <DataGrid
          columns={columns}
          data={builtTableData(data)}
          pageCount={data?.getCards?.totalCards / pageSize}
          hasPagination={true}
          fetchData={(input) =>
            handleTableUpdate({ type: "sorting", update: input })
          }
          customPagination={(paginationInput) => (
            <GridPagination
              rowsPerPage={pageSize}
              selectedPage={selectedPage}
              onPageClick={onPageClick}
              rowsPerPageOptions={pageSizeOptions}
              onRowsPerPageClick={onRowsPerPageClick}
              isLoading={loadingGetCards}
              totalItems={data?.getCards?.totalCards}
              setPageSize={paginationInput.setPageSize}
            />
          )}
        />
      )}
    </TableContainer>
  )
}

const searchFields: SearchQueryField[] = [
  {
    id: "cardId",
    label: "Número da conta",
  },
]

const tableFilters: FilterField[] = [
  {
    id: "status",
    checked: false,
    label: "Status",
    options: [
      {
        label: "Ativo",
        value: "activated",
      },
      {
        label: "Bloqueado",
        value: "locked",
      },
      {
        label: "Desabilitado",
        value: "disabled",
      },
      {
        label: "Em estoque",
        value: "inStock",
      },
    ],
  },
]

const columns = [
  {
    Header: "Número da conta",
    accessor: "cardId",
    disableSortBy: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableSortBy: true,
  },
  {
    Header: "CPF",
    accessor: "cpf",
    disableSortBy: true,
  },
  {
    Header: "Nome do colaborador",
    accessor: "employeeName",
    disableSortBy: true,
  },
  {
    Header: "ID do pedido",
    accessor: "externalCardOrderId",
    disableSortBy: true,
  },
]
