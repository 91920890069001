import { useLazyQuery } from "@apollo/client"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import { useEffect, useState } from "react"
import {
  GetCardOrderMetadataResponse,
  GET_CARD_ORDER_METADATA,
} from "../../../../../../api/queries/get-card-order-metadata"
import { useCardOrderContext } from "../../contexts/CardOrderContext"
import { CardOrderQuantity } from "./sections/CardOrderQuantity"
import { CardsInStock } from "./sections/CardsInStock"
import { EmployeesWithoutCards } from "./sections/EmployeesWithoutCards"
import { SelectTypeOfCardOrder } from "./sections/SelectTypeOfCardOrder"
import {
  CardOrderInfoTitle,
  SelectCardOrderTypeSection,
  SelectCardOrderTypeContainer,
  CardOrderInfoText,
} from "./styled"

export interface LimitData {
  allowedToOrder: boolean;
  limit?: number;
  warnLimit?: number;
  errorMessage?: string;
  messageType?: string;
}

const getLimitMetadata = (companyMetadata: GetCardOrderMetadataResponse) => {
  if (companyMetadata?.getCardOrderMetadata?.isExpenseManagement) {
    return {
      allowedToOrder: true,
    }
  }

  if (companyMetadata?.getCardOrderMetadata?.isFirstOrder) {
    if (companyMetadata?.getCardOrderMetadata?.additionalCardsLimit > 0) {
      return {
        limit:
          companyMetadata?.getCardOrderMetadata?.metadata?.allowedCards || 0,
        allowedToOrder: true,
      }
    } else {
      if (companyMetadata?.getCardOrderMetadata?.hasBilletNonCancelled) {
        return {
          limit:
            companyMetadata?.getCardOrderMetadata?.metadata?.allowedCards || 0,
          allowedToOrder: true,
        }
      } else {
        return {
          allowedToOrder: false,
          messageType: "error",
          errorMessage:
            "É necessário efetuar o seu primeiro depósito antes de fazer o seu pedido.",
        }
      }
    }
  } else {
    return {
      allowedToOrder: true,
      warnLimit:
        companyMetadata?.getCardOrderMetadata?.metadata?.employeeWithoutCards,
    }
  }
}

export const SelectCardOrderType = () => {
  const {
    cardOrderQuantity,
    handleChangeCardOrderQuantity,
    handleCanContinueWithOrder,
  } = useCardOrderContext()

  const [limitData, setLimitData] = useState<LimitData>({
    allowedToOrder: false,
  })

  const [
    getCompanyMetadata,
    { data: companyMetadata, loading: loadingCompanyMetadata },
  ] = useLazyQuery<GetCardOrderMetadataResponse>(GET_CARD_ORDER_METADATA, {
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    getCompanyMetadata()
  }, [])

  useEffect(() => {
    const limitData = getLimitMetadata(companyMetadata)

    if (limitData.limit && cardOrderQuantity > limitData.limit) {
      if (companyMetadata?.getCardOrderMetadata?.additionalCardsLimit) {
        handleCanContinueWithOrder(false)
        setLimitData({
          ...limitData,
          messageType: "error",
          errorMessage:
            "O valor inserido ultrapassa o limite liberado para sua empresa.",
        })
      } else {
        handleCanContinueWithOrder(false)
        setLimitData({
          ...limitData,
          messageType: "error",
          errorMessage:
            "O valor inserido ultrapassa o limite definido para este pedido.",
        })
      }
    } else {
      if (limitData.warnLimit && cardOrderQuantity > limitData.warnLimit) {
        handleCanContinueWithOrder(true)
        setLimitData({
          ...limitData,
          messageType: "warning",
          errorMessage:
            "O pedido de cartões ultrapassa o número de colaboradores sem cartão. Você ainda pode continuar com o pedido, mas ele pode passar por uma análise antes de ser aprovado.",
        })
      } else {
        handleCanContinueWithOrder(limitData.allowedToOrder)
        setLimitData(limitData)
      }
    }
  }, [companyMetadata, cardOrderQuantity])

  return (
    <SelectCardOrderTypeContainer>
      <SelectCardOrderTypeSection>
        <CardOrderInfoTitle>Quantidade de Cartões</CardOrderInfoTitle>
        <CardOrderInfoText>
          Por conta da escassez mundial de chips, a quantidade máxima de cartões
          enviados será igual a quantidade de colaboradores sem cartão
          cadastrados na plataforma.
        </CardOrderInfoText>
        {loadingCompanyMetadata ? (
          <Skeleton
            variant="rectangular"
            style={{
              marginBottom: "40px",
              borderRadius: "20px",
              height: "250px",
              display: "flex",
              flexGrow: 1,
            }}
          />
        ) : (
          <>
            <CardsInStock
              cardsInStock={
                companyMetadata?.getCardOrderMetadata?.metadata?.cardsInStock
              }
            />
            <EmployeesWithoutCards
              employeesWithoutCards={
                companyMetadata?.getCardOrderMetadata?.metadata
                  ?.employeeWithoutCards
              }
            />
            <CardOrderQuantity
              limitData={limitData}
              cardOrderQuantity={cardOrderQuantity}
              handleChange={handleChangeCardOrderQuantity}
            />
          </>
        )}
        <SelectTypeOfCardOrder />
      </SelectCardOrderTypeSection>
    </SelectCardOrderTypeContainer>
  )
}
