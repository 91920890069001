import { ApolloError, useMutation } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useEffect, useState } from "react"
import { CREATE_VIRTUAL_CARD_PCI } from "../../../api/mutations/create-virtual-card-pci"
import dispatchToast from "../../../utils/dispatchToast"
import { hasFourDigits, hasNonConsecutiveDigits } from "../../../utils/psswd"
import { ModalHeaderStyled } from "../Tables/styled"
import {
  ForgotPasswordMessageStyled,
  ModalContentStyled,
  ModalDescriptionStyled,
  ModalMessageStyled,
  ModalTitleStyled,
  TextInputContainerStyled,
} from "./styled"

export const VIRTUALCARD_MODAL = "createVirtualCard"

export const CreateVirtualCardPciModal = ({ handleOnCloseModals }) => {

  const [transactionPassword, setTransactionPassword] = useState(null)
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const [loadingCreateVirtualCard, setLoadingCreateVirtualCard] = useState(false)
  const [
    createVirtualCardPciMutation,
  ] = useMutation(
    CREATE_VIRTUAL_CARD_PCI,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        result
          ? dispatchDelayedToast()
          : errorToast()
      },
      onError: (error) => {
        errorToast(error)
      }
    }
  )

  const dispatchDelayedToast = () => {
    const timer = setTimeout(() => {
      dispatchToast({
        type: "success",
        title: "Cartão virtual criado com sucesso",
      })
      setLoadingCreateVirtualCard(false)
      handleOnClose(true)
      clearTimeout(timer)
    }, 3000)
  }


  const errorToast = (apolloError?: ApolloError) => {
    const { errorTitle, errorDescription } = apolloError?.message.includes("TOO_MANY_VIRTUAL_CARDS")
      ? { errorTitle: "Você já possui um cartão virtual ativo", errorDescription: "Exclua o atual para criar um novo" }
      : { errorTitle: "Erro ao criar cartão virtual", errorDescription: "Tente novamente mais tarde" }

    dispatchToast({
      type: "error",
      title: errorTitle,
      description: errorDescription
    })
    setLoadingCreateVirtualCard(false)
    handleOnClose(false)
  }

  const handleCreateVirtualCardPciInfo = () => {
    createVirtualCardPciMutation({
      variables: {
        createVirtualCardPci: {
          transactionPassword,
        },
      },
    })
    setLoadingCreateVirtualCard(true)
  }

  const handleOnClose = (needRefetch: boolean) => {
    handleOnCloseModals(VIRTUALCARD_MODAL, needRefetch)
    setTransactionPassword(null)
  }

  const handlePasswordChange = (value: string) => {
    if (hasFourDigits(value)) {
      setTransactionPassword(value)
    }
  }

  useEffect(() => {
    if (hasFourDigits(transactionPassword)) {
      setConfirmDisabled(false)
    }
  }, [transactionPassword])

  return (
    <Modal.Root onClose={() => handleOnClose(false)} open={true}>
      <>
        <ModalHeaderStyled>
          <ModalMessageStyled>
            <ModalTitleStyled variant="headline8">{benefitTitle}</ModalTitleStyled>
            <ModalDescriptionStyled>{description}<b>{boldDescription}</b></ModalDescriptionStyled>
          </ModalMessageStyled>
        </ModalHeaderStyled>
        <Modal.Content>
          <ModalContentStyled>
            <TextInputContainerStyled>
              <PasswordField
                style={{ width: "422px" }}
                label={transactionPasswordInputLabel}
                onChange={({ target: { value } }) => {
                  handlePasswordChange(value)
                }}
                error={transactionPassword && !hasNonConsecutiveDigits(transactionPassword)}
                value={transactionPassword}
                helperText={transactionPasswordInputDescription}
              ></PasswordField>
            </TextInputContainerStyled>
            <ForgotPasswordMessageStyled variant="caption">
              {forgotPasswordLabel}
              <LinkButton variant={"default"} size="small">
                {forgetPasswordEmail}
              </LinkButton>
            </ForgotPasswordMessageStyled>
          </ModalContentStyled>
        </Modal.Content>
        <Modal.Footer>
          <LinkButton variant="default" onClick={() => handleOnClose(false)}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            disabled={confirmDisabled}
            loading={loadingCreateVirtualCard}
            onClick={handleCreateVirtualCardPciInfo}
          >
            {confirmButton}
            <Icons
              name="IconCheck"
              fill="transparent"
              color="#ffffff"
              size={14}
            />
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const benefitTitle = "Criar cartão virtual de Benefícios"
const description =
  "Use o cartão virtual da Flash em carteiras digitais, apps e sites de compras sem depender do cartão físico. "
const boldDescription = "Você cria o cartão e já pode usar onde quiser."
const transactionPasswordInputLabel = "Insira sua senha de transação"
const transactionPasswordInputDescription =
  "A mesma senha que você utiliza para realizar as compras físicas com o cartão e transações no app."
const forgotPasswordLabel =
  "Não lembra da sua senha? Entre em contato com o suporte pelo e-mail"
const forgetPasswordEmail = "falecom@flashapp.com.br"
const confirmButton = "Criar cartão virtual"
