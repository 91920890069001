import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react"
import { SelectGroupCardModelData } from "../../../api/queries/select-group-card-model"
import { CardProviderEnum } from "../../globalInterfaces"

export interface FetchConfigState {
  providers: CardProviderEnum[];
  searchQuery: {};
  filter: {};
  limit: number;
  skip: number;
  sortBy: {
    createdAt: string;
  };
}

interface CardsPageContextData {
  groupCardModel?: SelectGroupCardModelData;
  setGroupCardModel?: (groupCardModel: SelectGroupCardModelData) => void;
  fetchConfigStateForCardOrderTable?: FetchConfigState;
  setFetchConfigStateForCardOrderTable?: Dispatch<
    SetStateAction<FetchConfigState>
  >;
}

interface GenericProviderProps {
  children: ReactNode;
}

export const CardsPageContext = createContext<CardsPageContextData>({})

export const CardsPageProvider = ({ children }: GenericProviderProps) => {
  const [groupCardModel, setGroupCardModel] = useState({
    cardLayout: "",
    cardProviders: [],
  })

  const [
    fetchConfigStateForCardOrderTable,
    setFetchConfigStateForCardOrderTable,
  ] = useState({
    providers: groupCardModel?.cardProviders,
    searchQuery: {},
    filter: {},
    limit: 10,
    skip: 0,
    sortBy: {
      createdAt: "desc",
    },
  })

  return (
    <CardsPageContext.Provider
      value={{
        groupCardModel,
        setGroupCardModel,
        fetchConfigStateForCardOrderTable,
        setFetchConfigStateForCardOrderTable,
      }}
    >
      {children}
    </CardsPageContext.Provider>
  )
}

export const useCardsPageContext = () => {
  const context = useContext(CardsPageContext)

  return context
}
