import { QueryLazyOptions, useLazyQuery } from "@apollo/client"
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination"
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState
} from "react"
import { GET_EMPLOYEE_PHYSICAL_CARDS, GetEmployeePhysicalCardsData } from "../../../api/queries/get-employee-physical-cards"
import { VirtualCardPciInfo } from "../../../api/queries/get-employee-virtual-card-pci"
import { GET_EMPLOYEE_VIRTUAL_CARDS, GetEmployeeVirtualCardsData } from "../../../api/queries/get-employee-virtual-cards"
import { SelectGroupCardModelData } from "../../../api/queries/select-group-card-model"
import { useEmployeeId } from "../../../utils/auth"
import dispatchToast from "../../../utils/dispatchToast"
import { VirtualCardColumn } from "../interfaces"

interface FetchConfigState {
  filter: {}
}

interface GenericProviderProps {
  children: ReactNode
}

interface MyCardsContextType {
  fetchConfigStateForPhysicalCardsTable?: FetchConfigState
  setFetchConfigStateForPhysicalCardsTable?: Dispatch<
    SetStateAction<FetchConfigState>
  >
  fetchConfigStateForVirtualCardsTable?: FetchConfigState
  setFetchConfigStateForVirtualCardsTable?: Dispatch<
    SetStateAction<FetchConfigState>
  >
  physicalCardsPagination?: PaginationState
  setPhysicalCardsPagination?: Dispatch<SetStateAction<PaginationState>>
  virtualCardsPagination?: PaginationState
  setVirtualCardsPagination?: Dispatch<SetStateAction<PaginationState>>
  selectedEmployeeId?: string
  setVirtualCardSelected?: Dispatch<SetStateAction<VirtualCardColumn>>
  virtualCardSelected?: VirtualCardColumn
  setPciModalVisible?: Dispatch<SetStateAction<boolean>>
  pciModalVisible?: boolean
  setVirtualCardPciInfo?: Dispatch<SetStateAction<VirtualCardPciInfo>>
  virtualCardPciInfo?: VirtualCardPciInfo
  transactionPassword?: string
  setTransactionPassword?: Dispatch<SetStateAction<string>>
  cvv?: string | null
  setCvv?: Dispatch<SetStateAction<string>>
  printedId?: string
  setPrintedId?: Dispatch<SetStateAction<string>>
  groupCardModel?: SelectGroupCardModelData
  setGroupCardModel?: (groupCardModel: SelectGroupCardModelData) => void
  userHasCards: boolean | null
  setUserHasCards: Dispatch<SetStateAction<boolean | null>>
  getEmployeePhysicalCardsData?: GetEmployeePhysicalCardsData
  getEmployeePhysicalCardsLoading: boolean
  refetchEmployeePhysicalCards: (options?: QueryLazyOptions<{ employeeId: string }>) => void
  getEmployeeVirtualCardsData?: GetEmployeeVirtualCardsData
  getEmployeeVirtualCardsLoading: boolean
  refetchEmployeeVirtualCards: (options?: QueryLazyOptions<{ employeeId: string }>) => void
  hasBenefits: boolean
  hasCorporateCards: boolean
  setHasCorporateCards: Dispatch<SetStateAction<boolean>>
  setHasBenefits: Dispatch<SetStateAction<boolean>>
}

export const MyCardsPageContext = createContext<MyCardsContextType | undefined>(undefined)

export const MyCardsPageProvider = ({ children }: GenericProviderProps) => {
  const [physicalCardsPagination, setPhysicalCardsPagination] =
    useState<PaginationState>({
      pageNumber: 1,
      pageSize: 5,
    })

  const [virtualCardsPagination, setVirtualCardsPagination] =
    useState<PaginationState>({
      pageNumber: 1,
      pageSize: 5,
    })

  const selectedEmployeeId = useEmployeeId()

  const [
    fetchConfigStateForPhysicalCardsTable,
    setFetchConfigStateForPhysicalCardsTable,
  ] = useState({
    filter: {},
    skip: 0,
    limit: 5,
  })

  const [
    fetchConfigStateForVirtualCardsTable,
    setFetchConfigStateForVirtualCardsTable,
  ] = useState({
    filter: {},
    skip: 0,
    limit: 5,
  })

  const [virtualCardSelected, setVirtualCardSelected] = useState(null)

  const [pciModalVisible, setPciModalVisible] = useState(false)

  const [virtualCardPciInfo, setVirtualCardPciInfo] = useState(null)

  const [cvv, setCvv] = useState(null)

  const [printedId, setPrintedId] = useState("")

  const [transactionPassword, setTransactionPassword] = useState(undefined)

  const [groupCardModel, setGroupCardModel] = useState({
    cardLayout: "",
    cardProviders: [],
  })

  const [userHasCards, setUserHasCards] = useState<boolean | null>(null)

  const [getEmployeePhysicalCards, { data: getEmployeePhysicalCardsData, loading: getEmployeePhysicalCardsLoading }] =
    useLazyQuery<GetEmployeePhysicalCardsData>(GET_EMPLOYEE_PHYSICAL_CARDS, {
      fetchPolicy: "network-only",
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro ao buscar os dados dos cartões.",
        })
      },
    })

  const [getEmployeeVirtualCards, { data: getEmployeeVirtualCardsData, loading: getEmployeeVirtualCardsLoading }] =
    useLazyQuery<GetEmployeeVirtualCardsData>(GET_EMPLOYEE_VIRTUAL_CARDS, {
      fetchPolicy: "network-only",
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro ao buscar os dados dos cartões virtuais.",
        })
      },
    })

  const refetchEmployeePhysicalCards = () => {
    getEmployeePhysicalCards({
      variables: fetchConfigStateForPhysicalCardsTable,
    })
    }

  const refetchEmployeeVirtualCards = () => {
    getEmployeeVirtualCards({
      variables: fetchConfigStateForVirtualCardsTable,
    })
  }

  const [hasBenefits, setHasBenefits] = useState(null)

  const [hasCorporateCards, setHasCorporateCards] = useState(null)

  return (
    <MyCardsPageContext.Provider
      value={{
        physicalCardsPagination,
        setPhysicalCardsPagination,
        virtualCardsPagination,
        setVirtualCardsPagination,
        fetchConfigStateForPhysicalCardsTable,
        setFetchConfigStateForPhysicalCardsTable,
        fetchConfigStateForVirtualCardsTable,
        setFetchConfigStateForVirtualCardsTable,
        selectedEmployeeId,
        setVirtualCardSelected,
        virtualCardSelected,
        setPciModalVisible,
        pciModalVisible,
        setVirtualCardPciInfo,
        virtualCardPciInfo,
        cvv,
        setCvv,
        printedId,
        setPrintedId,
        transactionPassword,
        setTransactionPassword,
        groupCardModel,
        setGroupCardModel,
        userHasCards,
        setUserHasCards,
        getEmployeePhysicalCardsData,
        getEmployeePhysicalCardsLoading,
        refetchEmployeePhysicalCards,
        getEmployeeVirtualCardsData,
        getEmployeeVirtualCardsLoading,
        refetchEmployeeVirtualCards,
        hasBenefits,
        hasCorporateCards,
        setHasCorporateCards,
        setHasBenefits,
      }}
    >
      {children}
    </MyCardsPageContext.Provider>
  )
}

export const useMyCardsPageContext = () => {
  const context = useContext(MyCardsPageContext)
  if (context === undefined) {
    throw new Error('useMyCardsPageContext must be used within a MyCardsPageProvider')
  }
  return context
}
