import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { GridPaginationSkeleton } from "./GridPaginationSkeleton"
import * as SC from "./styled"

export interface RefetchProps {
  pageIndex: number;
  pageSize: number;
}

export interface IRowsPerPageOption {
  label: string | React.ReactNode;
  value: number;
}

export interface GridPaginationProps {
  /**
   * Sets pages number
   */
  totalItems: number;

  /**
   * Sets current page number
   */
  selectedPage: number;

  /**
   * Sets the number of items to be displayed
   */
  rowsPerPage?: number;

  /**
   * if "true" displays the skeleton
   */
  isLoading: boolean;

  /**
   * Sets page size options
   */
  rowsPerPageOptions?: IRowsPerPageOption[];

  /**
   * If "true" it presents the options of items per page
   */
  showRowsPerPageOptions?: boolean;

  onPageClick(page: number): void;

  onRowsPerPageClick?(itemsPerPage: number): void;
  setPageSize?(pageSize: number): void;
}

export const GridPagination = ({
  isLoading,
  totalItems = 1,
  rowsPerPage = 1,
  rowsPerPageOptions,
  selectedPage = 1,
  showRowsPerPageOptions = true,
  setPageSize,
  onPageClick,
  onRowsPerPageClick,
}: GridPaginationProps) => {
  const { t } = useTranslation()
  const hasPagination = totalItems >= rowsPerPage

  const getRowsPerPageOptions = () => {
    const maxOptions: number = 20
    const options: IRowsPerPageOption[] = []

    for (let value = 5; value <= maxOptions; value = value + 5) {
      options.push({ label: `${value} por página`, value })
    }

    return options
  }

  useEffect(() => {
    setPageSize?.(rowsPerPage)
  }, [rowsPerPage])

  if (!isLoading)
    return (
      <SC.GridPaginationContainer>
        <SC.DescriptionContainer>
          {Boolean(totalItems) && showRowsPerPageOptions && (
            <SC.SelectItemsPerPage
              options={rowsPerPageOptions || getRowsPerPageOptions()}
              value={rowsPerPage}
              className="data-grid-custom-select"
              onSelectChange={(_, itemsPerPage) => {
                onRowsPerPageClick(itemsPerPage.value)
              }}
            />
          )}
          <SC.Description>{`${Math.min(
            (selectedPage - 1) * rowsPerPage + 1,
            totalItems,
          )} - ${
            !totalItems
              ? 0
              : Math.min(Math.ceil(rowsPerPage * selectedPage), totalItems)
          } de ${totalItems} items`}</SC.Description>
        </SC.DescriptionContainer>
        {hasPagination && (
          <SC.Pages
            count={!totalItems ? 1 : Math.ceil(totalItems / rowsPerPage)}
            page={selectedPage}
            onChange={(_, page) => onPageClick(page)}
          />
        )}
      </SC.GridPaginationContainer>
    )
  else return <GridPaginationSkeleton />
}
