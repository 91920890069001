import { gql } from "@apollo/client"

export const CHECK_CARD_ON_ACTIVATION = gql`
  query CheckCardOnActivation($printedId: String!, $cvv: String) {
    checkCardOnActivation(printedId: $printedId, cvv: $cvv) {
      isValidCard
      error {
        title
        message
      }
    }
  }
`

export type CheckCardOnActivationReponse = {
  checkCardOnActivation: {
    isValidCard: boolean
    error: {
      title: string
      message: string
    }
  }
}
