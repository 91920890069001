import axios from 'axios'

type UnleashConfig = {
  url: string
  token: string
  environment: string
}

type UnleashProperties = {
  employeeNanoId?: { value: string }
  companyNanoId?: { value: string }
}

export enum UnleashFeatureEnum {
  FLASH_OS_XP_CARDS_NEW_CARDS_PAGE = 'FLASH_OS_XP_CARDS_NEW_CARDS_PAGE',
}

const APP_NAME = 'hros-web-cards'

export const isFeatureEnabled = async (
  featureName: UnleashFeatureEnum,
  properties?: UnleashProperties
): Promise<boolean> => {
  try {
    const config: UnleashConfig = {
      url: process.env.UNLEASH_URL || '',
      token: process.env.UNLEASH_TOKEN || '',
      environment: process.env.UNLEASH_ENVIRONMENT || 'development',
    }
    const response = await axios.post(
      config.url,
      {
        toggles: [featureName],
        context: {
          appName: APP_NAME,
          environment: config.environment,
          properties: {
            ...(properties?.employeeNanoId && {
              employeeNanoId: properties.employeeNanoId.value,
            }),
            ...(properties?.companyNanoId && {
              companyNanoId: properties.companyNanoId.value,
            }),
          },
        },
      },
      {
        headers: {
          Authorization: config.token,
          'Content-Type': 'application/json',
        },
      }
    )

    return response.data.toggles[0].enabled === true
  } catch (error) {
    console.error(`Error checking feature toggle ${featureName}:`, error)
    return false
  }
}
