import { z } from "zod"
import { isValidCpf, isValidPhone } from "../../../../../utils/helpers"
import { validateZipCodeForm } from "../Steps/CreateCardOrderForm/sections/SingleCardOrderForm"

const createCardOrderSchema = z
  .object({
    cardOrderQuantity: z.string({ required_error: "Campo obrigatório" }),
    recipientName: z
      .string()
      .min(1, { message: "Campo obrigatório" })
      .refine(
        (value) => {
          return /^[^\d@!#&]+$/.test(value)
        },
        {
          message: "O nome não pode conter números",
          path: ["recipientName"],
        },
      ),
    zipCode: z
      .string()
      .min(1, { message: "Campo obrigatório" })
      .refine((value) => validateZipCodeForm(value), {
        message: "Digite um CEP válido.",
      }),
    address: z.string().min(1, { message: "Campo obrigatório" }),
    addressNumber: z.string().min(1, {
      message: "Campo obrigatório, caso não haja preencha o campo com S/N)",
    }),
    complement: z
      .string()
      .max(50, { message: "Complemento deve conter no máximo 50 dígitos" }),
    neighborhood: z.string().min(1, { message: "Campo obrigatório" }),
    city: z.string().min(1, { message: "Campo obrigatório" }),
    state: z.enum(
      [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MS",
        "MT",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
      ],
      {
        errorMap: () => {
          return { message: "Sigla inválida" }
        },
      },
    ),
    referencePoint: z.string().max(50, {
      message: "Ponto de referência deve conter no máximo 50 dígitos",
    }),
    recipientEmail: z
      .string()
      .min(1, { message: "Campo obrigatório" })
      .email({ message: "E-mail inválido" }),
    recipientPhone: z
      .string()
      .min(1, { message: "Campo obrigatório" })
      .refine((phone) => isValidPhone(phone), {
        message: "O telefone deve seguir o padrão DD 99999-9999",
      }),
    cardOrders: z
      .object({
        name: z.string(),
        uri: z.string(),
      })
      .optional(),
    isSearchEmployeeByCpfEnable: z.boolean().optional(),
    cpf: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.isSearchEmployeeByCpfEnable) {
        return isValidCpf(data?.cpf)
      }
      return true
    },
    {
      message: "Digite um CPF válido no formato 999.999.999-99",
      path: ["cpf"],
    }
  )

export { createCardOrderSchema }
