import { z } from "zod"
import { isValidCpf } from "../../../../../utils/helpers"

const resolveDeliveryInconsistenciesSchema = z.object({
  cpf: z.string().refine((value) => isValidCpf(value), {
    message: "Digite um CPF válido.",
  }),
})

export { resolveDeliveryInconsistenciesSchema }
