import { useLazyQuery } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useContext } from "react"
import {
  CHECK_CARD_ON_ACTIVATION,
  CheckCardOnActivationReponse,
} from "../../../../api/queries/check-card-on-activation"
import { isVisa } from "../../../../utils/cardProviders"
import dispatchToast from "../../../../utils/dispatchToast"
import { hasThreeDigits } from "../../../../utils/psswd"
import { MyCardsPageContext } from "../../Context/MyCardsContext"
import { ModalHeaderStyled } from "../../Tables/styled"
import { TextInputContainerStyled } from "../styled"
import { DescriptionStyled, InputLabelStyled, MessageStyled, ModalBodyStyled, ModalContainerStyled, ModalTitleStyled, WarningStyled, WarningTextStyled } from "./styled"

export const ValidateCardModal = ({ onClose, onConfirm, getBack }) => {
  const {
    selectedEmployeeId,
    printedId,
    setPrintedId,
    cvv,
    setCvv,
    groupCardModel,
  } = useContext(MyCardsPageContext)

  const [checkCardOnActivation, { loading: checkCardOnActivationLoading }] =
    useLazyQuery<CheckCardOnActivationReponse>(CHECK_CARD_ON_ACTIVATION, {
      fetchPolicy: "network-only",
      onCompleted: ({ checkCardOnActivation }) => {
        if (!checkCardOnActivation.isValidCard) {
          dispatchToast({
            type: "error",
            title: checkCardOnActivation.error.title,
            description: checkCardOnActivation.error.message,
          })
          return
        }
        onConfirm()
      },
      onError: (error) => {
        dispatchToast({
          type: "error",
          title:
            "Parece que estamos enfrentando alguns problemas técnicos no momento",
          description: "Sentimos muito. Tente novamente mais tarde.",
        })
      },
    })

  const handlePrintedIdChange = (value: string) => {
    const trimmedValue = value.trim()
    if (trimmedValue.length < 9) {
      setPrintedId(trimmedValue)
    }
  }
  const handleCvvChange = (value: string) => {
    if (hasThreeDigits(value)) {
      setCvv(value)
    }
  }

  const isValidPrintedId = () => printedId?.length === 8
  const isValidCvvWhenMaster = () => cvv?.length === 3
  const isValidInput = () => {
    const isValidCvv = isVisa(groupCardModel) ? true : isValidCvvWhenMaster()
    return isValidPrintedId() && isValidCvv
  }

  return (
    <Modal.Root onClose={onClose} open={true}>
      <>
        <Modal.Content>
          <ModalHeaderStyled>
            <WarningStyled>
              <Icons
                name="IconAlertCircle"
                fill="transparent"
                color="orange"
                size={16}
              />
              <WarningTextStyled variant="body4">Atenção!</WarningTextStyled>
            </WarningStyled>
            <MessageStyled>
              <ModalTitleStyled variant="headline8">{title}</ModalTitleStyled>
              <DescriptionStyled>
                {description}
              </DescriptionStyled>
            </MessageStyled>
          </ModalHeaderStyled>
          <ModalContainerStyled>
            <ModalBodyStyled>
              <InputLabelStyled variant="headline9">
                {instructionTitle}
              </InputLabelStyled>
              <TextInputContainerStyled>
                <PasswordField
                  style={{ width: "422px" }}
                  label={printedIdLabel}
                  onChange={({ target: { value } }) => {
                    handlePrintedIdChange(value)
                  }}
                  helperText={printedIdHelperText}
                  error={printedId && !isValidPrintedId()}
                  value={printedId}
                ></PasswordField>
              </TextInputContainerStyled>
              {!isVisa(groupCardModel) && (
                <TextInputContainerStyled>
                  <PasswordField
                    style={{ width: "422px" }}
                    label={cvvLabel}
                    onChange={({ target: { value } }) => {
                      handleCvvChange(value)
                    }}
                    error={cvv && !isValidCvvWhenMaster()}
                    helperText={cvvHelperText}
                    value={cvv}
                  ></PasswordField>
                </TextInputContainerStyled>
              )}
            </ModalBodyStyled>
          </ModalContainerStyled>
        </Modal.Content>
        <Modal.Footer style={{ paddingTop: "12px", marginTop: "12px" }}>
          <LinkButton variant="neutral" onClick={getBack}>
            <Icons name="IconArrowLeft"></Icons>
            {backButtonText}
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            onClick={() =>
              checkCardOnActivation({
                variables: {
                  printedId,
                  employeeId: selectedEmployeeId,
                  ...(!isVisa(groupCardModel) && { cvv }),
                },
              })
            }
            disabled={!isValidInput()}
            loading={checkCardOnActivationLoading}
          >
            {confirmButtonText}
            <Icons name="IconCheck"></Icons>
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const title = "Ativar novo cartão físico"
const description =
  "Ao ativar novo cartão físico, seu cartão antigo será cancelado e não poderá ser utilizado novamente."
const instructionTitle = "Preencha os dados do cartão novo"
const printedIdLabel = "Número da conta"
const printedIdHelperText = "São os 8 números localizados abaixo do código QR do seu cartão"
const cvvLabel = "Código de verificação (CVV)"
const cvvHelperText = "O código de verificação (CVV) impresso no verso do seu cartão Flash"
const backButtonText = "Voltar"
const confirmButtonText = "Continuar"
