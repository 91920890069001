import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { useIsNewCardsXpEnabled } from "../api/hooks/is-new-cards-xp-enabled.hook"
import { CenteredSpinner } from "../components/CenteredSpinner"
import { Cards } from "../pages"
import { CardsPageProvider } from "../pages/Cards/Context/CardsContext"
import { CardOperationsProvider } from "../pages/MyCards/Context/CardOperationsContext"
import { MyCardsPageProvider } from "../pages/MyCards/Context/MyCardsContext"
import { MyCards } from "../pages/MyCards/MyCards"
import { RoutesGuard } from "./routesGuard"

const NewCardsRoute = () => {
  const { isEnabled, isLoading, error } = useIsNewCardsXpEnabled()

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (error || !isEnabled) {
    return <Navigate to="/corporateCard/cards" replace />
  }

  return (
    <MyCardsPageProvider>
      <CardOperationsProvider>
        <MyCards />
      </CardOperationsProvider>
    </MyCardsPageProvider>
  )
}

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RoutesGuard adminPages={true} />}>
          <Route
            path="/cards"
            element={
              <CardsPageProvider>
                <Cards />
              </CardsPageProvider>
            }
          />
        </Route>

        <Route element={<RoutesGuard adminPages={false} />}>
          <Route path="/cards/me" element={<NewCardsRoute />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter