import { createContext, ReactElement, useReducer } from "react"
import { reducer } from "./reducer"
import { IGlobalContext } from "./types"
import { globalContextInitial } from "./store"

const GlobalContext = createContext<IGlobalContext>(globalContextInitial)

const GlobalContextProvider = (props: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(reducer, globalContextInitial)

  const value: IGlobalContext = { dispatch, ...state }

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  )
}

export { GlobalContextProvider, GlobalContext }
