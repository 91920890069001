import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ModalMessageStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ModalTitleStyled = styled(Typography)`
  font-family: Cabinet Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #1d161b;
`

export const ModalDescriptionStyled = styled(Typography)`
  font-family: Nunito Sans;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
  color: #6b5b66;
`

export const WarningStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

export const WarningTextStyled = styled(Typography)`
font-family: Nunito Sans;
font-size: 14px;
font-weight: 700;
line-height: 20px;
color: #c96c01;
`

export const MessageStyled = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
margin-bottom: 30px;
`

export const DescriptionStyled = styled.div`
justify-content: justify;
gap: 8px;
`

export const InputLabelStyled = styled(Typography)`
font-family: Cabinet Grotesk;
font-size: 16px;
font-weight: 700;
line-height: 19px;
text-align: left;
color: #53464f;
`

export const ModalContainerStyled = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
`
