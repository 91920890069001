import { useMutation, useQuery } from "@apollo/client"
import {
  Checkbox,
  Icons,
  SelectField,
  TextField,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import {
  FieldErrors,
  useFormContext,
  UseFormGetValues,
  UseFormReturn,
} from "react-hook-form"
import { DELETE_CARD_DELIVERY_ADDRESS } from "../../../../../../../api/mutations/delete-card-delivery-address"
import {
  CardDeliveryAddress,
  CardDeliveryAddressData,
  GET_CARD_DELIVERY_ADDRESSES,
} from "../../../../../../../api/queries/get-card-delivery-addresses"
import { WarningModal } from "../../../../../../../components/WarningModal/styled"
import { isValidCep } from "../../../../../../../utils/helpers"
import {
  type ApiResponse,
  type BrasilAPIResponse,
  getAddressInfoFromZipCode,
  maskZipCode,
  ViaCepAPIResponse,
} from "../../../../../../../utils/zipCode"
import { ICreateCardOrderForm } from "../../../CreateCardOrderForm/createCardOrderForm.interfaces"
import {
  CardOrderInfoText,
  CardOrderInfoTitle,
} from "../../SelectCardOrderType/styled"

type SingleCardOrderFields =
  | "cardOrderQuantity"
  | "zipCode"
  | "address"
  | "addressNumber"
  | "complement"
  | "neighborhood"
  | "city"
  | "state"
  | "referencePoint"
  | "saveAddress"
  | "addressName"
  | "selectedAddress";

export const SINGLE_CARD_ORDER_FIELDS: SingleCardOrderFields[] = [
  "zipCode",
  "address",
  "addressNumber",
  "complement",
  "neighborhood",
  "city",
  "state",
  "referencePoint",
  "saveAddress",
  "addressName",
  "selectedAddress",
]

interface ISelectOption extends CardDeliveryAddress {
  value: string | null;
  label: string;
  icon?: JSX.Element;
}

export const checkSingleCardOrderFields = (
  errors: FieldErrors<ICreateCardOrderForm>,
  getValues: UseFormGetValues<ICreateCardOrderForm>,
) => {
  const result = Object.entries(errors).filter(([error]) => {
    if (!getValues("saveAddress") && error === "addressName") {
      return false
    }
    return (
      SINGLE_CARD_ORDER_FIELDS.indexOf(error as SingleCardOrderFields) >= 0
    )
  })

  return result.reduce((prev, [key, value]) => (prev[key] = value), {})
}

export const formattedAddressFields = (
  json?: ApiResponse,
): BrasilAPIResponse => {
  if (!json) {
    return {
      cep: undefined,
      street: undefined,
      state: undefined,
      neighborhood: undefined,
      city: undefined,
    }
  }
  const formattedAddressFields =
    json.zipCodeValidator === "brasilApi"
      ? (json as BrasilAPIResponse)
      : {
          cep: (json as ViaCepAPIResponse).cep,
          street: (json as ViaCepAPIResponse).logradouro,
          neighborhood: (json as ViaCepAPIResponse).bairro,
          city: (json as ViaCepAPIResponse).localidade,
          state: (json as ViaCepAPIResponse).uf,
        }
  return formattedAddressFields
}
export const setZipCodeToForm = async (
  zipCodeInserted: string,
  methods: UseFormReturn<ICreateCardOrderForm, any>,
) => {
  if (methods.watch("selectedAddress") !== "0") return
  methods.setValue("zipCode", maskZipCode(zipCodeInserted))

  if (isValidCep(zipCodeInserted)) {
    getAddressInfoFromZipCode(zipCodeInserted).then((addressFields) => {
      if (!addressFields.cep) {
        return false
      }
      if (addressFields.street) {
        methods.setValue("address", addressFields.street)
      }
      if (addressFields.neighborhood) {
        methods.setValue("neighborhood", addressFields.neighborhood)
      }
      if (addressFields.city) {
        methods.setValue("city", addressFields.city)
      }
      if (addressFields.state) {
        methods.setValue("state", addressFields.state)
      }
    })
  }
}

const lastZipCode: { cep?: string; isValid?: boolean } = {}
export const validateZipCodeForm = async (cep: string): Promise<boolean> => {
  try {
    if (isValidCep(cep)) {
      if (lastZipCode.cep === cep) {
        return lastZipCode.isValid
      }

      lastZipCode.cep = cep
      const addressFields = await getAddressInfoFromZipCode(cep)
      if (!addressFields.cep) {
        lastZipCode.isValid = false
      } else {
        lastZipCode.isValid = true
      }

      return lastZipCode.isValid
    }
  } catch (error) {
    if (!isValidCep(cep)) {
      return false
    }
    return true
  }
}

interface AddressNameInputProps {
  disabled: boolean;
  context: UseFormReturn<ICreateCardOrderForm, any>;
}

const AddressNameInput = ({
  disabled,
  context: {
    watch,
    register,
    formState: { errors },
  },
}: AddressNameInputProps) => {
  const { saveAddress } = watch()
  if (saveAddress) {
    return (
      <Grid item sm={12}>
        <TextField
          disabled={disabled}
          {...register("addressName")}
          label="Nome do endereço*"
          fullWidth
          error={!!errors.addressName}
          helperText={errors.addressName?.message as string}
        />
        <CardOrderInfoText>
          O nome do endereço te ajuda a identificá-lo caso seja reutilizado em
          outros pedidos
        </CardOrderInfoText>
      </Grid>
    )
  }
  return null
}

export const SingleCardOrderForm = () => {
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext()
  const initialOptions = {
    value: "0",
    label: "Novo endereço",
    ...(getValues() as CardDeliveryAddress),
  }
  const [options, setOptions] = useState<ISelectOption[]>([initialOptions])
  const [openModal, setOpenModal] = useState(false)
  const [addressToDelete, setAddressToDelete] = useState({
    companyId: "",
    externalAddressId: "",
  })
  const watchSelectedAddress = watch("selectedAddress")

  const { refetch: getCardDeliveryAddress } = useQuery<CardDeliveryAddressData>(
    GET_CARD_DELIVERY_ADDRESSES,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const { addresses } = data.getCardDeliveryAddress
        setOptions([initialOptions, ...addresses.map(buildSelectableOption)])
      },
    }
  )
  const [
    deleteCardDeliveryAddress,
    { loading: deleteCardDeliveryAddressLoading },
  ] = useMutation(DELETE_CARD_DELIVERY_ADDRESS, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      getCardDeliveryAddress()
    },
  })

  const buildSelectableOption = (item: ISelectOption) => {
    const { selectedAddress } = watch()
    if (item.value === "0") {
      return item
    }
    if (item.value === selectedAddress) {
      const { icon, ...newItem } = item
      return newItem
    }

    return {
      ...item,
      value: item.externalAddressId,
      icon: (
        <Icons
          name="IconTrash"
          color="rgb(254, 43, 143)"
          fill="transparent"
          size={24}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleDeleteSelectableItem(item)
          }}
        />
      ),
      label: item.addressName,
    }
  }

  const handleDeleteSelectableItem = (item: ISelectOption) => {
    setAddressToDelete({
      companyId: item.companyId,
      externalAddressId: item.externalAddressId,
    })
    setOpenModal(true)
  }

  useEffect(() => {
    const { selectedAddress } = watch()

    setOptions(options.map(buildSelectableOption))

    const selectedOption = options.find(
      (item) => item.value === selectedAddress
    )
    if (selectedOption) {
      setValue("zipCode", selectedOption.zipCode)
      setValue("addressName", selectedOption.addressName)
      setValue("address", selectedOption.address)
      setValue("addressNumber", selectedOption.addressNumber)
      setValue("complement", selectedOption.complement ?? "")
      setValue("neighborhood", selectedOption.neighborhood)
      setValue("city", selectedOption.city)
      setValue("state", selectedOption.state)
      setValue("referencePoint", selectedOption.referencePoint ?? "")
      setValue("saveAddress", watchSelectedAddress !== options[0].value)
    }
  }, [watchSelectedAddress])

  const deleteAddress = () => {
    try {
      deleteCardDeliveryAddress({
        variables: {
          deleteCardDeliveryAddress: {
            companyId: addressToDelete.companyId,
            externalAddressId: addressToDelete.externalAddressId,
          },
        },
      })
    } catch (error) {
    } finally {
      setValue("selectedAddress", options[0].value)
      setValue("zipCode", "")
      setValue("addressName", "")
      setValue("address", "")
      setValue("addressNumber", "")
      setValue("complement", "")
      setValue("neighborhood", "")
      setValue("city", "")
      setValue("state", "")
      setValue("referencePoint", "")
      setValue("saveAddress", false)
      setOpenModal(false)
    }
  }

  const hasMaxStoredAddresses = () => options.length > 5

  return (
    <>
      <CardOrderInfoTitle>Dados da Entrega</CardOrderInfoTitle>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <SelectField
            label="Selecionar um endereço salvo"
            options={options}
            value={watchSelectedAddress}
            onSelectChange={(element, event) => {
              return setValue("selectedAddress", event.value)
            }}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            disabled={watchSelectedAddress !== options[0].value}
            InputLabelProps={{ shrink: !!watch("zipCode") }}
            {...register("zipCode")}
            fullWidth
            label="CEP*"
            error={!!errors.zipCode}
            helperText={errors.zipCode?.message as string}
            inputProps={{
              maxLength: 9,
            }}
          />
        </Grid>
        <Grid item sm={8}>
          <TextField
            disabled={watchSelectedAddress !== options[0].value}
            InputLabelProps={{ shrink: !!watch("address") }}
            {...register("address")}
            fullWidth
            label="Endereço*"
            error={!!errors.address}
            helperText={errors.address?.message as string}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            disabled={watchSelectedAddress !== options[0].value}
            InputLabelProps={{ shrink: !!watch("addressNumber") }}
            {...register("addressNumber")}
            fullWidth
            label="Número*"
            error={!!errors.addressNumber}
            helperText={errors.addressNumber?.message as string}
          />
        </Grid>
        <Grid item sm={8}>
          <TextField
            disabled={watchSelectedAddress !== options[0].value}
            InputLabelProps={{ shrink: !!watch("complement") }}
            {...register("complement")}
            fullWidth
            label="Complemento"
            error={!!errors.complement}
            helperText={errors.complement?.message as string}
          />
        </Grid>
        <Grid item sm={5}>
          <TextField
            disabled={watchSelectedAddress !== options[0].value}
            InputLabelProps={{ shrink: !!watch("neighborhood") }}
            {...register("neighborhood")}
            fullWidth
            label="Bairro*"
            error={!!errors.neighborhood}
            helperText={errors.neighborhood?.message as string}
          />
        </Grid>
        <Grid item sm={5}>
          <TextField
            disabled={watchSelectedAddress !== options[0].value}
            InputLabelProps={{ shrink: !!watch("city") }}
            {...register("city")}
            fullWidth
            label="Cidade*"
            error={!!errors.city}
            helperText={errors.city?.message as string}
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            disabled={watchSelectedAddress !== options[0].value}
            InputLabelProps={{ shrink: !!watch("state") }}
            {...register("state")}
            fullWidth
            label="Estado*"
            error={!!errors.state}
            helperText={errors.state?.message as string}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            disabled={watchSelectedAddress !== options[0].value}
            InputLabelProps={{ shrink: !!watch("referencePoint") }}
            {...register("referencePoint")}
            fullWidth
            label="Ponto de referência"
            error={!!errors.referencePoint}
            helperText={errors.referencePoint?.message as string}
          />
        </Grid>
        <Tooltip
          arrow={true}
          title={
            hasMaxStoredAddresses()
              ? "Você já possui 5 endereços salvos. Para salvar um novo endereço, é necessário excluir um dos endereços salvos na lista acima."
              : ""
          }
          children={
            <Grid item sm={4}>
              <Checkbox
                {...register("saveAddress")}
                disabled={
                  watchSelectedAddress !== options[0].value ||
                  hasMaxStoredAddresses()
                }
                checked={watch("saveAddress")}
                onChange={() => setValue("saveAddress", !watch("saveAddress"))}
              />
              Salvar endereço
            </Grid>
          }
        />
        <AddressNameInput
          disabled={watchSelectedAddress !== options[0].value}
          context={useFormContext()}
        />
      </Grid>
      <WarningModal
        open={openModal}
        disableButtons={deleteCardDeliveryAddressLoading}
        title={[
          "Tem certeza que deseja",
          "excluir esse endereço da sua",
          "lista?",
        ]}
        subtitle={[
          "Ao excluir esse endereço, ele será removido da lista de",
          "endereços salvos para entrega de cartões.",
        ]}
        onClick={deleteAddress}
        onClose={() => {
          setOpenModal(false)
        }}
      />
    </>
  )
}
