import { useState } from "react"
import { useMyCardsPageContext } from "../../Context/MyCardsContext"
import { ActivateFirstCardIntroModal } from "./ActivateFirstCardIntroModal"
import { CreateTransactionPasswordModal } from "./CreateTransactionPassword"
import { ValidateCardModal } from "./ValidateCardModal"

export const ActivateFirstCardFlow = ({ onClose }) => {
  const { setCvv, setTransactionPassword, setPrintedId, refetchEmployeePhysicalCards } =
    useMyCardsPageContext()

  const [flowStep, setFlowStep] = useState(1)

  const handleOnClose = () => {
    setTransactionPassword(undefined)
    setCvv(undefined)
    setPrintedId("")
    setFlowStep(0)
    onClose()
  }

  const handleOnFinish = () => {
    refetchEmployeePhysicalCards()
    handleOnClose()
  }

  return (
    <>
      {flowStep === 1 && (
        <ActivateFirstCardIntroModal
          onClose={handleOnClose}
          onConfirm={() => setFlowStep(2)}
        />
      )}
      {flowStep === 2 && (
        <ValidateCardModal
          onClose={handleOnClose}
          onConfirm={() => setFlowStep(3)}
          onCardAlreadyActive={() => handleOnFinish()}
        />
      )}
      {flowStep === 3 && (
        <CreateTransactionPasswordModal
          onClose={handleOnClose}
          onConfirm={handleOnFinish}
          onError={() => setFlowStep(2)}
        />
      )}
    </>
  )
}
