import { useQuery } from "@apollo/client"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"
import { useState } from "react"
import {
  CHECK_REPLAST_BANNER_STATUS,
  CheckReplastBannerStatusResponse,
} from "../../../api/queries/check-replast-banner-status"
import dispatchToast from "../../../utils/dispatchToast"
import { BannerLink, BannerStyled, BannerTitle, InfoIcon } from "./styled"

export const ReplastBanner = () => {
  const { selectedCompany } = useSelectedCompany()
  const [replastBannerState, setReplastBannerState] = useState({
    shouldShowOrderInProgressBanner: false,
    shouldShowOrderDeliveredBanner: false,
  })

  const _ = useQuery<CheckReplastBannerStatusResponse>(
    CHECK_REPLAST_BANNER_STATUS,
    {
      variables: { companyId: selectedCompany.id },
      onCompleted: (data) => {
        const { checkReplastBannerStatus } = data
        setReplastBannerState(checkReplastBannerStatus)
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro ao buscar os dados da empresa.",
        })
      },
    }
  )

  return (
    <>
      {replastBannerState.shouldShowOrderInProgressBanner && (
        <InprogressBannerMessage />
      )}
      {replastBannerState.shouldShowOrderDeliveredBanner && (
        <DeliveredBannerMessage />
      )}
    </>
  )
}

export const InprogressBannerMessage = () => (
  <BannerStyled>
    <InfoIcon />
    <div>
      <BannerTitle>
        Seu pedido de novos cartões Flash já está em processo de preparação e
        será entregue em breve, conforme a modalidade de entrega selecionada.
      </BannerTitle>
      <ul>
        <li>
          A partir de agora, todas as informações disponíveis na aba Cartões:
          cartões ativos, estoque e em trânsito estarão relacionadas apenas aos
          cartões <strong>Visa.</strong>
        </li>
        <li>
          Não será mais possível ativar cartões da bandeira Mastercard. Os
          cartões antigos do seu estoque já não funcionam mais e devem ser{" "}
          <strong>descartados.</strong>
        </li>
      </ul>
      <BannerLink href="https://seja.flashapp.com.br/campanhas/empresas-cartao-visa">
        <strong>Saiba mais sobre as mudanças do novo cartão Flash Visa</strong>
      </BannerLink>
    </div>
  </BannerStyled>
)

export const DeliveredBannerMessage = () => (
  <BannerStyled>
    <InfoIcon />
    <div>
      <BannerTitle>
        Boa notícia! Os novos cartões da Flash foram entregues na sua empresa.
      </BannerTitle>
      <p>
        <strong>Distribua imediatamente</strong> os cartões e incentive seus
        colaboradores a <strong>ativá-los o quanto antes!</strong>
      </p>
      <p>
        Sugerimos que você e o seu time sejam os primeiros a realizar a
        ativação.
      </p>
      <BannerLink href="https://seja.flashapp.com.br/campanhas/empresas-cartao-visa">
        <strong>Saiba mais sobre as mudanças do novo cartão Flash Visa</strong>
      </BannerLink>
    </div>
  </BannerStyled>
)
