import axios from "axios"
import { formattedAddressFields } from "../../src/pages/Cards/Stepper/CreateCardOrderStepper/Steps/CreateCardOrderForm/sections/SingleCardOrderForm"

export interface BrasilAPIResponse {
  cep?: string;
  street?: string;
  state?: string;
  neighborhood?: string;
  city?: string;
  zipCodeValidator?: string;
}

export interface GetZipCodeData {
  zipCode?: string;
  street?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zipCodeValidator?: string;
}

export interface ViaCepAPIResponse {
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  ibge?: string;
  gia?: string;
  ddd?: string;
  siafi?: string;
  zipCodeValidator?: string;
}

export type ApiResponse = BrasilAPIResponse | ViaCepAPIResponse;

export const getAddressInfoFromZipCode = async (zipCode: string) => {
  const unmaskedZipCode = unmaskZipCode(zipCode)

  const urlBrasilApi = `https://brasilapi.com.br/api/cep/v1/${unmaskedZipCode}`
  const urlViaCep = `https://viacep.com.br/ws/${unmaskedZipCode}/json/`

  try {
    const viaCepResponse = await axios.get<ViaCepAPIResponse>(urlViaCep, {
      timeout: 3000,
    })

    if (viaCepResponse.status === 200) {
      viaCepResponse.data.zipCodeValidator = "viaCep"
      return formattedAddressFields(viaCepResponse.data)
    }
  } catch (error) {
    console.error(error)
  }

  try {
    const brasilApiResponse = await axios.get<BrasilAPIResponse>(urlBrasilApi)

    if (brasilApiResponse.status === 200) {
      brasilApiResponse.data.zipCodeValidator = "brasilApi"
      return formattedAddressFields(brasilApiResponse.data)
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return formattedAddressFields()
    } else {
      console.error(error)
    }
  }
  throw new Error("Unable to obtain zip code")
}

export const maskZipCode = (zipCode: string) => {
  const maskedZipCode = zipCode.replace(/\D/g, "")
  return maskedZipCode.replace(/(\d{5})(\d)/, "$1-$2")
}

export const unmaskZipCode = (zipCode: string) => zipCode.replace("-", "")
