import { gql } from "@apollo/client"

export const TOGGLE_NFC_PCI = gql`
  mutation cardNfcTogglePci(
    $cardNfcTogglePci: Employee_CardNfcTogglePciInput!
  ) {
    cardNfcTogglePci(
      cardNfcTogglePci: $cardNfcTogglePci
    )
  }
`

export interface Employee_CardNfcTogglePciInput {
  enabled: Boolean
  origin: String
  transactionPassword: String
  financeCardId: String
  printedId: String
}
