import {
  Button,
  Icons,
  LinkButton,
  Modal,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"
import { ModalHeaderStyled } from "../../Tables/styled"
import { ModalWarningTextStyled } from "../styled"
import { ModalDescriptionStyled, ModalMessageStyled, ModalTitleStyled, WarningStyled } from "./styled"

export const ActivateNewCardIntroModal = ({ onClose, onConfirm }) => {
  return (
    <Modal.Root onClose={onClose} open={true}>
      <>
        <Modal.Content>
          <ModalHeaderStyled>
            <WarningStyled>
              <Icons
                name="IconAlertCircle"
                fill="transparent"
                color="orange"
                size={16}
              />
              <ModalWarningTextStyled variant="body4">
                Atenção!
              </ModalWarningTextStyled>
            </WarningStyled>
            <ModalMessageStyled>
              <ModalTitleStyled variant="headline8">{title}</ModalTitleStyled>
              <ModalDescriptionStyled variant="body3">
                {description}
              </ModalDescriptionStyled>
            </ModalMessageStyled>
          </ModalHeaderStyled>
        </Modal.Content>
        <Modal.Footer style={{ paddingTop: "12px", marginTop: "12px" }}>
          <LinkButton variant="neutral" onClick={onClose}>
            Cancelar
          </LinkButton>
          <Button variant="primary" size="large" onClick={onConfirm}>
            {buttonText}
            <Icons name="IconCheck"></Icons>
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const title = "Ativar novo cartão físico"
const description =
  "Ao ativar novo cartão físico, seu cartão antigo será cancelado e não poderá ser utilizado novamente. Deseja prosseguir?"
const buttonText = "Continuar"
