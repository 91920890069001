import { useMutation } from "@apollo/client"
import { Toast, useToasts } from "@flash-tecnologia/hros-web-ui-v2"
import { zodResolver } from "@hookform/resolvers/zod"
import { Box } from "@mui/material"
import { useContext, useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { CREATE_BATCH_CARD_ORDER } from "../../../../api/mutations/create-batch-card-order"
import { CREATE_CARD_DELIVERY_ADDRESS } from "../../../../api/mutations/create-card-delivery-address"
import { CREATE_CARD_ORDER } from "../../../../api/mutations/create-card-order"
import { useStepper } from "../../../../components/Stepper/Stepper"
import { formatPhone, removeDuplicateSpaces } from "../../../../utils/helpers"
import { maskZipCode, unmaskZipCode } from "../../../../utils/zipCode"
import { CardsPageContext } from "../../Context/CardsContext"
import { useCardOrderContext } from "./contexts/CardOrderContext"
import { ICreateCardOrderForm } from "./CreateCardOrderForm/createCardOrderForm.interfaces"
import { createCardOrderSchema } from "./CreateCardOrderForm/createCardOrderSchema"
import { SheetCardOrderValidationView } from "./Steps/ConcludedCardOrder/ConcludedCardOrderView"
import {
  SHEETS_CARD_ORDER_FIELDS,
  SheetsCardOrderForm,
} from "./Steps/CreateCardOrderForm/sections/SheetsCardOrderForm"
import {
  checkSingleCardOrderFields,
  setZipCodeToForm,
  SINGLE_CARD_ORDER_FIELDS,
  SingleCardOrderForm,
} from "./Steps/CreateCardOrderForm/sections/SingleCardOrderForm"
import {
  SheetsValidation,
  validateCardOrdersWorksheet,
} from "./Steps/CreateCardOrderForm/sections/validateCardOrdersWorksheet"
import { SheetCardOrderValidation } from "./Steps/FinishCardOrder/sections/SheetCardOrderValidation"
import {
  checkSingleCardOrderRecipientFields,
  SINGLE_CARD_ORDER_RECIPIENT_FIELDS,
  SingleCardOrderFormRecipient,
} from "./Steps/FinishCardOrder/sections/SingleCardOrderFormRecipient"
import { SelectCardOrderType } from "./Steps/SelectCardOrderType/SelectCardOrderType"

interface Props {
  onClose: () => void;
}

interface SingleOrderForm {
  cardDeliveries: {
    cardAmount: number;
    recipient: {
      name: string;
      email: string;
      phone: string;
      cpf?: string;
    };
    deliveryAddress: {
      zipCode: string;
      address: string;
      addressNumber: string;
      complement?: string;
      neighborhood: string;
      city: string;
      referencePoint?: string;
      state: string;
    };
  }[];
}

const generateDefaultFormValues = () => {
  return {
    zipCode: "",
    address: "",
    addressNumber: "",
    complement: "",
    neighborhood: "",
    city: "",
    referencePoint: "",
    state: "",
    recipientName: "",
    recipientEmail: "",
    recipientPhone: "",
    saveAddress: false,
    addressName: "",
    selectedAddress: "0",
    cpf: "",
  }
}

export const CreateCardOrderStepper = ({ onClose }: Props) => {
  const { cardOrderQuantity, cardOrderType, canContinueWithOrder } =
    useCardOrderContext()

  const {
    groupCardModel,
    setFetchConfigStateForCardOrderTable,
    fetchConfigStateForCardOrderTable,
  } = useContext(CardsPageContext)

  const [sheetsValidation, setSheetsValidation] = useState<SheetsValidation>({
    isValid: false,
  })

  const { showToast } = useToasts()

  const methods = useForm<ICreateCardOrderForm>({
    resolver: zodResolver(createCardOrderSchema),
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: generateDefaultFormValues(),
  })

  useEffect(() => {
    if (methods.formState?.errors?.cardOrderQuantity) {
      goToStep(0)
    }
  }, [methods.formState])

  const checkCardAmount = async () => {
    const { trigger, getValues, setError } = methods

    await trigger("cardOrderQuantity")

    if (Number(getValues("cardOrderQuantity")) <= 0) {
      setError("cardOrderQuantity", {
        type: "min",
        message: "Deve ser maior que 0.",
      })
    }
  }

  const checkSingleCardOrderForm = async () => {
    const {
      trigger,
      getValues,
      formState: { errors },
    } = methods
    await trigger(SINGLE_CARD_ORDER_FIELDS)

    if (Object.keys(checkSingleCardOrderFields(errors, getValues)).length > 0) {
      goToStep(1)
    }
  }

  const checkSheetsCardOrderForm = async () => {
    const { trigger, getValues } = methods
    await trigger(SHEETS_CARD_ORDER_FIELDS)
    const cardOrders = getValues("cardOrders")
    if (!cardOrders || !cardOrders.uri) {
      showToast("", {
        content: (key) => {
          return (
            <div>
              <Toast
                id={key}
                type={"error"}
                title={"Atenção"}
                description={
                  "É necessário fazer upload de uma planilha válida para continuar."
                }
              />
            </div>
          )
        },
      })
      goToStep(1)
      return
    }
    const { validation } = await validateCardOrdersWorksheet(
      cardOrders.uri,
      cardOrderQuantity,
    )
    setSheetsValidation(validation)
  }

  useEffect(() => {
    const { zipCode } = methods.getValues()

    setZipCodeToForm(maskZipCode(zipCode), methods)
  }, [methods.watch("zipCode")])

  const [createCardOrder, { loading: createCardOrderLoading }] =
    useMutation<SingleOrderForm>(CREATE_CARD_ORDER, {
      onCompleted: () => {
        try {
          const { selectedAddress, saveAddress } = methods.getValues()
          if (selectedAddress === "0" && saveAddress) {
            createCardDeliveryAddress({
              variables: buildCardDeliveryAddressForm(methods.getValues()),
            })
          }
        } catch (error) {}
        methods.reset(generateDefaultFormValues())
        setFetchConfigStateForCardOrderTable({
          providers: groupCardModel?.cardProviders,
          searchQuery: {},
          filter: {},
          limit: 10,
          skip: 0,
          sortBy: {
            createdAt: "desc",
          },
        })
      },
      onError: () =>
        showToast("", {
          content: (key) => {
            return (
              <div>
                <Toast
                  id={key}
                  type={"error"}
                  title={"Erro"}
                  description={
                    "Algo aconteceu na criação do seu pedido. Por favor, tente novamente mais tarde."
                  }
                />
              </div>
            )
          },
        }),
    })

  const [createBatchCardOrder, { loading: createBatchCardOrderLoading }] =
    useMutation<SingleOrderForm>(CREATE_BATCH_CARD_ORDER, {
      onCompleted: () => {
        methods.reset(generateDefaultFormValues())
        setFetchConfigStateForCardOrderTable({
          providers: groupCardModel?.cardProviders,
          searchQuery: {},
          filter: {},
          limit: 10,
          skip: 0,
          sortBy: {
            createdAt: "desc",
          },
        })
      },
      onError: () =>
        showToast("", {
          content: (key) => {
            return (
              <div>
                <Toast
                  id={key}
                  type={"error"}
                  title={"Erro"}
                  description={
                    "Algo aconteceu na criação do seu pedido. Por favor, tente novamente mais tarde."
                  }
                />
              </div>
            )
          },
        }),
    })

  const [createCardDeliveryAddress] = useMutation(
    CREATE_CARD_DELIVERY_ADDRESS,
    {
      onCompleted: () => {
        return
      },
      onError: () => {
        return
      },
    },
  )

  const onSubmit = async () => {
    const {
      trigger,
      getValues,
      formState: { errors },
    } = methods
    if (cardOrderType === "formOrder") {
      await trigger(SINGLE_CARD_ORDER_RECIPIENT_FIELDS)
      if (Object.keys(checkSingleCardOrderRecipientFields(errors)).length > 0) {
        goToStep(2)
      } else {
        const singleOrderForm = buildSingleOrderForm(getValues())
        if (cardOrderType === "formOrder") {
          createCardOrder({
            variables: {
              createCardOrder: singleOrderForm,
            },
          })
        }
      }
    }
    if (cardOrderType === "sheetsOrder") {
      createBatchCardOrder({
        variables: {
          createBatchCardOrder: buildSheetsOrderForm(getValues()),
        },
      })
    }
  }

  const onFinish = async () => {
    onClose()
  }

  const buildSheetsOrderForm = ({ cardOrders }: ICreateCardOrderForm) => {
    return {
      cardOrders,
    }
  }

  const buildSingleOrderForm = ({
    cardOrderQuantity,
    address,
    addressNumber,
    city,
    complement,
    neighborhood,
    referencePoint,
    recipientEmail,
    recipientPhone,
    recipientName,
    zipCode,
    state,
    cpf,
  }: ICreateCardOrderForm): SingleOrderForm => {
    return {
      cardDeliveries: [
        {
          deliveryAddress: {
            state,
            address: removeDuplicateSpaces(address.trim()),
            addressNumber: removeDuplicateSpaces(addressNumber.trim()),
            city: removeDuplicateSpaces(city.trim()),
            complement: removeDuplicateSpaces(complement.trim()),
            neighborhood: removeDuplicateSpaces(neighborhood.trim()),
            referencePoint: removeDuplicateSpaces(referencePoint.trim()),
            zipCode: unmaskZipCode(zipCode),
          },
          recipient: {
            name: removeDuplicateSpaces(recipientName.trim()),
            phone: formatPhone(recipientPhone),
            email: recipientEmail,
            ...(cpf && { cpf: cpf.replace(/\D/g, "") }),
          },
          cardAmount: Number(cardOrderQuantity),
        },
      ],
    }
  }

  const buildCardDeliveryAddressForm = ({
    state,
    address,
    addressNumber,
    city,
    complement,
    neighborhood,
    referencePoint,
    zipCode,
    addressName,
  }: ICreateCardOrderForm) => {
    return {
      createCardDeliveryAddress: {
        state,
        address,
        addressNumber,
        city,
        complement,
        neighborhood,
        referencePoint,
        zipCode,
        addressName,
      },
    }
  }

  const steps = useMemo(() => {
    return [
      {
        label: "",
        isOptional: false,
        component: <SelectCardOrderType />,
        callback: checkCardAmount,
        disableNext: !canContinueWithOrder,
        nextButtonLabel: "Próximo",
      },
      {
        label: "",
        isOptional: false,
        component:
          cardOrderType === "formOrder" ? (
            <SingleCardOrderForm />
          ) : (
            <SheetsCardOrderForm />
          ),
        callback:
          cardOrderType === "formOrder"
            ? checkSingleCardOrderForm
            : checkSheetsCardOrderForm,
        nextButtonLabel: "Próximo",
      },
      {
        label: "",
        isOptional: false,
        component:
          cardOrderType === "formOrder" ? (
            <SingleCardOrderFormRecipient />
          ) : (
            <SheetCardOrderValidation validation={sheetsValidation} />
          ),
        disableNext:
          cardOrderType === "formOrder"
            ? createCardOrderLoading
            : createBatchCardOrderLoading || !sheetsValidation.isValid,
        callback: onSubmit,
        finishButtonLabel: "Finalizar",
      },
      {
        label: "",
        isOptional: false,
        disableBack: true,
        component: <SheetCardOrderValidationView />,
        callback: onFinish,
        finishButtonLabel: "Sair",
      },
    ]
  }, [
    cardOrderQuantity,
    cardOrderType,
    canContinueWithOrder,
    methods.getValues,
    sheetsValidation,
    createCardOrderLoading,
    createBatchCardOrderLoading,
  ])

  const { Stepper, goToStep } = useStepper({
    steps,
    stepperSx: {
      width: "200px",
    },
  })

  return (
    <Box sx={{ margin: "20px 30px" }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>{Stepper}</form>
      </FormProvider>
    </Box>
  )
}
