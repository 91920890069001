import { useQuery } from "@apollo/client"
import { PageContainer, Tab, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"
import { useEffect } from "react"
import styled from "styled-components"
import { CHECK_EMPLOYEE_CORPORATE_CARD_ENABLED, CheckEmployeeCorporateCardEnabledResponse } from "../../api/queries/check-employee-corporate-card-enabled"
import { CenteredSpinner } from "../../components/CenteredSpinner"
import { HeaderContainer } from "../../components/Header/styled"
import dispatchToast from "../../utils/dispatchToast"
import { useMyCardsPageContext } from "./Context/MyCardsContext"
import { CorporateCardSection } from "./CorporateCardSection/CorporateCardSection"
import { MyBenefitsCards } from "./MyBenefitsCards/MyBenefitsCards"

export const MyCards = () => {
  const { hasCorporateCards, setHasCorporateCards } = useMyCardsPageContext()
  const selectedCompany = useSelectedCompany()

  const { data: corporateCardData, loading: isCorporateCardsLoading, refetch: refetchCorporateCard } = useQuery<CheckEmployeeCorporateCardEnabledResponse>(
    CHECK_EMPLOYEE_CORPORATE_CARD_ENABLED,
    {
      fetchPolicy: "network-only",
      onError: (_) => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro ao buscar os dados do usuário.",
        })
      }
    }
  )

  useEffect(() => {
    setHasCorporateCards(corporateCardData?.checkEmployeeCorporateCardEnabled?.hasCorporateCardsEnabled || false)
  }, [corporateCardData])

  useEffect(() => {
    refetchCorporateCard()
  }, [selectedCompany])

  const isLoadingEmployeeProducts = isCorporateCardsLoading

  const tabItens = [
    {
      label: "Benefícios",
      component: <MyBenefitsCards />,
    },
    ...(hasCorporateCards ? [{
      label: "Corporativo",
      component: <CorporateCardSection />,
    }] : []),
  ]

  return (
    <>
      {isLoadingEmployeeProducts ? (
        <CenteredSpinner />
      ) : (
        <MyCardsPageContainer>
          <HeaderContainer>
            <Typography variant="headline6" style={{ color: "#3B2E37" }}>
              Meus Cartões
            </Typography>
          </HeaderContainer>
          <Tab tabItens={tabItens} />
        </MyCardsPageContainer>
      )}
    </>
  )
}

const MyCardsPageContainer = styled(PageContainer)`
  height: "fit-content";
  min-height: "100%";
  width: "100%";
  padding: 40px 24px 40px 24px;
`