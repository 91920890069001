import { useMutation } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useContext, useEffect, useState } from "react"
import { TOGGLE_INTERNATIONAL_PCI } from "../../../api/mutations/toggle-international-pci"
import dispatchToast from "../../../utils/dispatchToast"
import { CardOperationsContext } from "../Context/CardOperationsContext"
import { ModalHeaderStyled } from "../Tables/styled"
import {
  ForgotPasswordMessageStyled,
  ModalContentStyled,
  ModalDescriptionStyled,
  ModalMessageStyled,
  ModalTitleStyled,
  TextInputContainerStyled,
} from "./styled"
import { hasNonConsecutiveDigits, hasFourDigits } from "../../../utils/psswd"

export const INTERNATIONAL_MODAL = 'toggleInternational'

export const ToggleInternationalPciModal = ({ handleOnCloseModals }) => {
  const {
    toggleInternationalVariables,
    setToggleInternationalVariables,
  } = useContext(CardOperationsContext)

  const [transactionPassword, setTransactionPassword] = useState(null)
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const [
    toggleInternationalPciMutation, 
    { loading }
  ] = useMutation(
    TOGGLE_INTERNATIONAL_PCI,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        result
          ? dispatchToast({
              type: "success",
              title: toggleInternationalVariables.international
                ? "Compras internacionais desativadas com sucesso"
                : "Compras internacionais ativadas com sucesso",
            })
          : errorToast()
        handleOnClose(true)
      },
      onError: (error) => {
        handleOnClose(false)
        errorToast()
      },
    },
  )

  const errorToast = () =>
    dispatchToast({
      type: "error",
      title: toggleInternationalVariables.international
        ? "Erro ao desativar compras internacionais"
        : "Erro ao ativar compras internacionais",
      description: "Tente novamente mais tarde"
    })

  const handleToggleInternationalPciInfo = () => {
    toggleInternationalPciMutation({
      variables: {
        cardInternationalTogglePci: {
          enabled: !toggleInternationalVariables.international,
          origin: "flashos",
          financeCardId: toggleInternationalVariables.financeCardId,
          transactionPassword,
          ...(toggleInternationalVariables.printedId && {
            printedId: toggleInternationalVariables.printedId,
          }),
        },
      },
    })
  }

  const handleOnClose = (needRefetch: boolean) => {
    handleOnCloseModals(INTERNATIONAL_MODAL, needRefetch)
    setToggleInternationalVariables(null)
    setTransactionPassword(null)
  }

  const handlePasswordChange = (value: string) => {
    if (hasFourDigits(value)) {
      setTransactionPassword(value)
    }
  }

  useEffect(() => {
    if (hasFourDigits(transactionPassword)) {
      setConfirmDisabled(false)
    }
  }, [transactionPassword])

  return (
    <Modal.Root onClose={() => handleOnClose(false)} open={true}>
      <>
        <ModalHeaderStyled>
          <ModalMessageStyled>
            <ModalTitleStyled variant="headline8">{toggleInternationalVariables.international ? untoggleTitle : toggleTitle}</ModalTitleStyled>
            <ModalDescriptionStyled>{toggleInternationalVariables.international ? untoggleDescription : toggleDescription}</ModalDescriptionStyled>
          </ModalMessageStyled>
        </ModalHeaderStyled>
        <Modal.Content>
          <ModalContentStyled>
            <TextInputContainerStyled>
              <PasswordField
                style={{ width: "422px" }}
                label={transactionPasswordInputLabel}
                onChange={({ target: { value } }) => {
                  handlePasswordChange(value)
                }}
                error={transactionPassword && !hasNonConsecutiveDigits(transactionPassword)}
                value={transactionPassword}
                helperText={transactionPasswordInputDescription}
              ></PasswordField>
            </TextInputContainerStyled>
            <ForgotPasswordMessageStyled variant="caption">
              {forgotPasswordLabel}
              <LinkButton variant={"default"} size="small">
                {forgetPasswordEmail}
              </LinkButton>
            </ForgotPasswordMessageStyled>
          </ModalContentStyled>
        </Modal.Content>
        <Modal.Footer>
          <LinkButton variant="default" onClick={() => handleOnClose(false)}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            disabled={confirmDisabled}
            loading={loading}
            onClick={handleToggleInternationalPciInfo}
          >
            {confirmButton}
            <Icons
              name="IconCheck"
              fill="transparent"
              color="#ffffff"
              size={14}
            />
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const toggleTitle = "Ativar compras internacionais"
const untoggleTitle = "Desativar compras internacionais"
const toggleDescription =
  "Ative essa função para permitir compras em outros países ou em outras moedas no seu cartão."
const untoggleDescription =
  "Desative essa função para não permitir compras em outros países ou em outras moedas no seu cartão."
const transactionPasswordInputLabel = "Insira sua senha de transação"
const transactionPasswordInputDescription =
  "A mesma senha que você utiliza para realizar as compras físicas com o cartão e transações no app."
const forgotPasswordLabel =
  "Não lembra da sua senha? Entre em contato com o suporte pelo e-mail"
const forgetPasswordEmail = "falecom@flashapp.com.br"
const confirmButton = "Confirmar"
