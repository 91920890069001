import { CardsInfoText, CardsInfoValue, InfoCardsBox } from "./styled"

interface EmployeesWithoutCardsProps {
  employeesWithoutCards: number;
}

const EmployeesWithoutCards = ({
  employeesWithoutCards,
}: EmployeesWithoutCardsProps) => {
  return (
    <InfoCardsBox>
      <CardsInfoText>Colaboradores sem Cartão</CardsInfoText>
      <CardsInfoValue>{employeesWithoutCards}</CardsInfoValue>
    </InfoCardsBox>
  )
}

export { EmployeesWithoutCards }
