import {
  Button,
  Icons,
  Menu,
  PillButton,
  Table,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2"

import { Action } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types"
import dayjs from "dayjs"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { VirtualCardPciInfo } from "../../../api/queries/get-employee-virtual-card-pci"
import {
  GetEmployeeVirtualCardsData
} from "../../../api/queries/get-employee-virtual-cards"
import { formatCardNumber } from "../../../utils/helpers"
import {
  businessUnitToAssociatedBenefitMap,
  cardProviderToProviderNameMap,
} from "../../../utils/maps"
import { useDidUpdateEffect } from "../../../utils/react-hooks"
import { CardOperationsContext, CardTypeEnum } from "../Context/CardOperationsContext"
import { useMyCardsPageContext } from "../Context/MyCardsContext"
import { AssociatedBalanceEnum, CardStatusEnum, VirtualCardColumn } from "../interfaces"
import { CardHardUnlockModal } from "../Modals/CardHardUnlockModal"
import { CardLockPciModal, LOCK_MODAL } from "../Modals/CardLockPciModal"
import { INTERNATIONAL_MODAL, ToggleInternationalPciModal } from "../Modals/CardToggleInternationalPciModal"
import { NFC_MODAL, ToggleNfcPciModal } from "../Modals/CardToggleNfcPciModal"
import { CardUnlockPciModal, UNLOCK_MODAL } from "../Modals/CardUnlockPciModal"
import { ConfirmTransactionPasswordPciModal } from "../Modals/ConfirmTransactionPasswordPciModal"
import { CreateVirtualCardPciModal, VIRTUALCARD_MODAL } from "../Modals/CreateVirtualCardPciModal"
import { DELETEVIRTUALCARD_MODAL, DeleteVirtualCardPciModal } from "../Modals/DeleteVirtualCardPciModal"
import { UpdateTransactionPasswordPciModal } from "../Modals/UpdateTransactionPasswordPciModal"
import { CardProviderLogo } from "../Utils/CardProviderLogo"
import { CardStatusCell } from "../Utils/CardStatusCell"
import { StyledTableCell, TableTitle } from "./styled"

const disabledStatus = [CardStatusEnum.canceled, CardStatusEnum.inactive, CardStatusEnum.disabled, CardStatusEnum.lost]

export const MyVirtualCardsTable = () => {
  const {
    setFetchConfigStateForVirtualCardsTable,
    fetchConfigStateForVirtualCardsTable,
    setVirtualCardsPagination,
    virtualCardsPagination,
    setVirtualCardSelected,
    setPciModalVisible,
    pciModalVisible,
    setVirtualCardPciInfo,
    virtualCardPciInfo,
    refetchEmployeeVirtualCards,
    getEmployeeVirtualCardsData,
    getEmployeeVirtualCardsLoading,
  } = useMyCardsPageContext()

  const {
    cardLockPciVirtualModalVisible,
    setCardLockPciVirtualModalVisible,
    setCardLockPciVariables,
    cardUnlockPciVirtualModalVisible,
    setCardUnlockPciVirtualModalVisible,
    setCardUnlockPciVariables,
    cardHardUnlockModalVisible,
    setCardHardUnlockModalVisible,
    toggleInternationalVirtualModalVisible,
    setToggleInternationalVirtualModalVisible,
    setToggleInternationalVariables,
    toggleNfcVirtualModalVisible,
    setToggleNfcVirtualModalVisible,
    setToggleNfcVariables,
    updateTransacPsswdModalVisible,
    setUpdateTransacPsswdModalVisible,
    setUpdateTransacPsswdVariables,
    createVirtualCardModalVisible,
    setCreateVirtualCardModalVisible,
    deleteVirtualCardModalVisible,
    setDeleteVirtualCardModalVisible,
    setDeleteVirtualCardVariables,
  } = useContext(CardOperationsContext)

  const navigate = useNavigate()

  useDidUpdateEffect(() => {
    refetchEmployeeVirtualCards()
  }, [fetchConfigStateForVirtualCardsTable])

  const refetchWithPagination = (pageSize: number, pageIndex: number) => {
    const limit = pageSize
    const skip = (pageIndex - 1) * pageSize

    setVirtualCardsPagination({ pageNumber: pageIndex, pageSize })

    setFetchConfigStateForVirtualCardsTable((previousState) => ({
      ...previousState,
      limit,
      skip,
    }))
  }

  const buildTableData = (
    data: GetEmployeeVirtualCardsData,
    sensitiveData?: VirtualCardPciInfo,
  ): VirtualCardColumn[] => {
    const cards = data?.getEmployeeVirtualCards?.cards || []

    return cards.map((card) => {
      const cardPciInfo =
        sensitiveData && sensitiveData.financeCardId === card.financeCardId

      return {
        cardNumber: cardPciInfo
          ? formatCardNumber(sensitiveData.cardNumber)
          : "––– " + card.last4Digits,
        status: card.status,
        cvv: cardPciInfo ? sensitiveData.cvv : "–––",
        expirationDate: dayjs(card.expirationDate).format("MM/YY"),
        provider: cardProviderToProviderNameMap[card.provider],
        businessUnit: businessUnitToAssociatedBenefitMap[card.businessUnit],
        nameOnCard: card.nameOnCard.toUpperCase(),
        financeCardId: card.financeCardId,
        showPci: !!cardPciInfo,
        international: card.international,
        nfc: card.nfc,
        bpCardId: card.bpCardId,
        options: [],
      }
    })
  }

  const handleOnCloseModals = (modalName: string, needRefetch: boolean) => {
    switch(modalName) {
      case LOCK_MODAL: setCardLockPciVirtualModalVisible(false)
      case UNLOCK_MODAL: setCardUnlockPciVirtualModalVisible(false)
      case NFC_MODAL: setToggleNfcVirtualModalVisible(false)
      case INTERNATIONAL_MODAL: setToggleInternationalVirtualModalVisible(false)
      case VIRTUALCARD_MODAL: setCreateVirtualCardModalVisible(false)
      case DELETEVIRTUALCARD_MODAL: setDeleteVirtualCardModalVisible(false)
      default: break
    }

    if (needRefetch) {
      setFetchConfigStateForVirtualCardsTable((previousState) => ({
        ...previousState
      }))
    }
  }

  const handleShowVirtualCardPciClick = (data: VirtualCardColumn) => {
    setVirtualCardSelected(data)
    setPciModalVisible(true)
  }

  const handleHideVirtualCardPciClick = () => {
    setVirtualCardPciInfo(null)
  }

  const handleCardLockPci = (data: VirtualCardColumn) => {
    setCardLockPciVariables({
      financeCardId: data.financeCardId,
      cardType: CardTypeEnum.virtual
    })
    setCardLockPciVirtualModalVisible(true)
  }

  const hanldeCardUnlockPci = (data: VirtualCardColumn) => {
    setCardUnlockPciVariables({
      financeCardId: data.financeCardId,
      cardType: CardTypeEnum.virtual
    })
    setCardUnlockPciVirtualModalVisible(true)
  }

  const hanldeCardHardUnlock = () => {
    setCardHardUnlockModalVisible(true)
  }

  const handleToggleInternationalPci = (data: VirtualCardColumn) => {
    setToggleInternationalVariables({
      financeCardId: data.financeCardId,
      cardType: CardTypeEnum.virtual,
      international: data.international
    })
    setToggleInternationalVirtualModalVisible(true)
  }

  const handleToggleNfcPci = (data: VirtualCardColumn) => {
    setToggleNfcVariables({
      financeCardId: data.financeCardId,
      cardType: CardTypeEnum.virtual,
      nfc: data.nfc
    })
    setToggleNfcVirtualModalVisible(true)
  }

  const handleUpdateTransacPsswdPci = (data: VirtualCardColumn) => {
    setUpdateTransacPsswdVariables({
      financeCardId: data.financeCardId,
    })
    setUpdateTransacPsswdModalVisible(true)
  }

  const handleDeleteVirtualCardPci = (data: VirtualCardColumn) => {
    setDeleteVirtualCardVariables({
      bpCardId: data.bpCardId,
    })
    setDeleteVirtualCardModalVisible(true)
  }

  const renderActions = (columnData: VirtualCardColumn) => {
    const options: Action<VirtualCardColumn>[] = []
    const isBenefitsCard = columnData.businessUnit === AssociatedBalanceEnum.benefits
    const isDisabled = disabledStatus.includes(columnData.status)

    if(columnData.status === CardStatusEnum.active) {
      options.push({
        label: "Bloquear cartão",
        key: "",
        onClick: () => handleCardLockPci(columnData),
      })
    }

    if(columnData.status === CardStatusEnum.locked) {
      options.push({
        label: "Desbloquear cartão",
        key: "",
        onClick: () => hanldeCardUnlockPci(columnData),
      })
    }

    if(columnData.status === CardStatusEnum.hardLocked) {
      options.push({
        label: "Desbloquear cartão",
        key: "",
        onClick: () => hanldeCardHardUnlock(),
      })
    }

    if(!isDisabled) {
      if (isBenefitsCard) {
        columnData.international
        ? options.push({
          label: "Desativar compras internacionais",
          key: "",
          onClick: () => handleToggleInternationalPci(columnData),
        })
        : options.push({
          label: "Ativar compras internacionais",
          key: "",
          onClick: () => handleToggleInternationalPci(columnData),
          })
      }

      options.push({
        label: "Excluir cartão",
        key: "",
        onClick: () => handleDeleteVirtualCardPci(columnData),
      })
    }

    options.push({
      label: "Ver extrato da conta",
      key: "",
      onClick: () => navigate(`/corporateCard/statement`),
    })

    return options
  }

  // Ideal sugerir a implementação deste comportamento no componente Table do DS
  const renderActionButton = (row: VirtualCardColumn) => {
    const options = renderActions(row)
    if (options.length === 0) {
      return <span></span>
    }

    return (
      <Menu
        type="select"
        options={options.map((option) => ({
          key: option.key,
          onClick: () => option.onClick(row),
          children: (
            <MenuOption>
              {option.icon && <Icons name={option.icon} fill="transparent" />}
              {option.label}
            </MenuOption>
          ),
        }))}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 50, horizontal: -150 }}
      >
        <PillButton
          variant="default"
          size="small"
          icon="IconDotsVertical"
        />
      </Menu>
    )
  }

  const table = tableControllers.useTableColumns<VirtualCardColumn>({
    defaultColumn: {
      minSize: 200,
    },
    total:
      getEmployeeVirtualCardsData?.getEmployeeVirtualCards?.totalCards || 0,
    columns: [
      {
        header: "Emissora",
        accessorKey: "provider",
        cell: ({ row }) => (
          <StyledTableCell>
            <CardProviderLogo provider={row.original.provider} />
          </StyledTableCell>
        ),
        size: 140,
      },
      {
        header: "Cartão",
        accessorKey: "cardNumber",
        cell: ({ row }) => (
          <StyledTableCell>
            <p>{row.original.cardNumber}</p>
          </StyledTableCell>
        ),
        size: 235,
      },
      {
        header: "Nome no cartão",
        accessorKey: "nameOnCard",
        minSize: 215,
      },
      {
        header: "Validade",
        accessorKey: "expirationDate",
        minSize: 130,
      },
      {
        header: "CVV",
        accessorKey: "cvv",
        minSize: 100,
      },
      {
        header: "Saldo Associado",
        accessorKey: "businessUnit",
        minSize: 190,
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => <CardStatusCell status={row.original.status} />,
        minSize: 160,
      },
      {
        header: "",
        accessorKey: "showPci",
        cell: ({ row }) => (
          !disabledStatus.includes(row.original.status) && (
            <PillButton
              icon={row.original.showPci ? "IconEyeOff" : "IconEye"}
              size="small"
              variant="default"
              onClick={() =>
                row.original.showPci
                  ? handleHideVirtualCardPciClick()
                  : handleShowVirtualCardPciClick(row.original)
              }
            />
          )
        ),
        size: 100,
        sticky: "right",
      },
      {
        header: "Ações",
        accessorKey: "options",
        cell: ({ row }) => renderActionButton(row.original),
        size: 93,
        sticky: "right",
      },
    ],
    data: buildTableData(getEmployeeVirtualCardsData, virtualCardPciInfo),
    pagination: virtualCardsPagination,
    onPaginationChange: () => {},
  })

  return (
    <>
      <Table.Root>
        <MyVirtualCardsTableContainerStyled>
          <TableTitle variant="headline8">Virtual</TableTitle>
          <Button
            variant="primary"
            minWidth="244px"
            minHeight="40px"
            size="small"
            onClick={() => setCreateVirtualCardModalVisible(true)}
          >
            Criar cartão virtual
            <Icons name="IconPlus"></Icons>
          </Button>
        </MyVirtualCardsTableContainerStyled>
        <Table.Grid.Root loading={getEmployeeVirtualCardsLoading}>
          <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          {table.rows.map((row, index) => (
            <Table.Grid.Row key={index + row.id} row={row} />
          ))}
        </Table.Grid.Root>
        <Table.Pagination
          count={
            getEmployeeVirtualCardsData?.getEmployeeVirtualCards?.totalCards ||
            0
          }
          onPaginationChange={({ pageSize, pageNumber }) =>
            refetchWithPagination(pageSize, pageNumber)
          }
          pagination={virtualCardsPagination}
          pageSizeOptions={pageSizeOptions}
        />
      </Table.Root>
      {pciModalVisible && <ConfirmTransactionPasswordPciModal />}
      {cardLockPciVirtualModalVisible && <CardLockPciModal handleOnCloseModals={handleOnCloseModals} />}
      {cardUnlockPciVirtualModalVisible && <CardUnlockPciModal handleOnCloseModals={handleOnCloseModals}/>}
      {cardHardUnlockModalVisible && <CardHardUnlockModal />}
      {toggleInternationalVirtualModalVisible && <ToggleInternationalPciModal handleOnCloseModals={handleOnCloseModals}/>}
      {toggleNfcVirtualModalVisible && <ToggleNfcPciModal handleOnCloseModals={handleOnCloseModals}/>}
      {updateTransacPsswdModalVisible && <UpdateTransactionPasswordPciModal />}
      {createVirtualCardModalVisible && <CreateVirtualCardPciModal handleOnCloseModals={handleOnCloseModals}/>}
      {deleteVirtualCardModalVisible && <DeleteVirtualCardPciModal handleOnCloseModals={handleOnCloseModals}/>}
    </>
  )
}

const pageSizeOptions = [
  {
    label: "5 itens",
    value: 5,
  },
]

const MyVirtualCardsTableContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px 12px 0px;
  gap: 0px;
`

const MenuOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
