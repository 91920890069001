import { Spinner } from "@flash-tecnologia/hros-web-ui-v2"

export const CenteredSpinner = () => (
  <SpinnerContainer>
    <Spinner size={48} />
  </SpinnerContainer>
)

import styled from 'styled-components'

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`