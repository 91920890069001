import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

interface MessageDisplayProps {
  type: string;
  marginTop?: string;
}

export const MessageDisplay = styled.div<MessageDisplayProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => {
    if (props.type === "success") return "var(--color-feedback-success-light1)"
    if (props.type === "error") return "var(--color-feedback-error-light1)"
    if (props.type === "warning") return "var(--color-feedback-error-light1)"
  }};
  border: 1px solid
    ${(props) => {
      if (props.type === "success")
        return "var(--color-feedback-success-light2)"
      if (props.type === "error") return "var(--color-feedback-error-light2)"
      if (props.type === "warning") return "var(--color-feedback-error-light2)"
    }};

  ${({ marginTop }) =>
    marginTop &&
    `
      margin-top: ${marginTop};
  `}

  border-radius: 8px;
  padding: 15px;
`

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MessageIconError = styled(Icons)`
  background: var(--color-feedback-error-pure);
  color: var(--color-feedback-error-light1);
  fill: transparent;
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  margin-right: 20px;
`

export const MessageIconSuccess = styled(Icons)`
  background: var(--color-feedback-success-pure);
  color: var(--color-feedback-success-light1);
  fill: transparent;
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  margin-right: 20px;
`

interface AlertMessageProps extends MessageDisplayProps {
  fontSize?: string;
}

export const AlertMessage = styled.div<AlertMessageProps>`
  color: ${(props) => {
    if (props.type === "success") return "var(--color-feedback-success-dark2)"
    if (props.type === "error") return "var(--color-feedback-error-dark2)"
    if (props.type === "warning") return "var(--color-feedback-error-dark2)"
  }};

  ${({ fontSize }) =>
    fontSize &&
    `
      font-size: ${fontSize};
  `}

  font-weight: 600;
`

export const MessageErrors = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  border: 1px solid var(--color-feedback-error-light2);
  border-radius: 8px;
  padding: 24px;
  max-height: 400px;
  overflow-y: scroll;
`

export const MessageErrorTitle = styled.div`
  color: var(--color-feedback-error-dark2);
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 10px;
`

export const MessageErrorRow = styled.div`
  color: var(--color-feedback-error-dark2);
  font-size: 14px;
  font-weight: 400;
`
