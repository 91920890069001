import styled from "styled-components"
import { Icons, Modal } from "@flash-tecnologia/hros-web-ui-v2"

export interface WarningModalProps {
  open: boolean;
  disableButtons?: boolean;
  title: string[];
  subtitle: string[];
  onClick: (...props: any) => Promise<void> | void;
  onClose: (...props: any) => Promise<void> | void;
}

export const WarningModal = ({
  open = false,
  disableButtons = false,
  title,
  subtitle,
  onClick,
  onClose,
}: WarningModalProps) => {
  return (
    <Modal.Root
      open={open}
      onClose={onClose}
      children={
        <>
          <HeaderContainer>
            <IconBackground>
              <Icons
                style={{ margin: 10 }}
                color="#C96C01"
                name="IconAlertCircle"
                fill="transparent"
                size={72}
              />
            </IconBackground>
            <HeaderText>Atenção!</HeaderText>
          </HeaderContainer>
          {title.map((text) => (
            <Title>{text}</Title>
          ))}
          {subtitle.map((text) => (
            <Subtitle>{text}</Subtitle>
          ))}
          <FooterContainer>
            <CancelButton disabled={disableButtons} onClick={onClose}>
              Cancelar
            </CancelButton>
            <DeleteButton disabled={disableButtons} onClick={onClick}>
              Confirmar exclusão
              <Icons
                name="IconTrash"
                fill="transparent"
                style={{ marginLeft: 8 }}
              />
            </DeleteButton>
          </FooterContainer>
        </>
      }
    />
  )
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IconBackground = styled.div`
  background-color: #ffefcc;
  border-radius: 100px;
`

const HeaderText = styled.text`
  margin-top: 10px;
  font-family: Nunito;
  font-weight: 700;
  font-size: 16px;
  color: #c96c01;
`

const Title = styled.p`
  font-family: Cabinet Grotesk;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
`

const Subtitle = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #83727d;
`

const FooterContainer = styled.footer`
  width: 100%;
  border-top: 1px solid #ebe6e9;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 20px;
`

const CancelButton = styled.button`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #83727d;
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-thickness: 2px;
`

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #c96c01;
  background-color: #ffefcc;
  border: 1px solid #ffefcc;
  padding: 15px;
  border-radius: 12px;

  &:hover {
    background-color: transparent;
    border: 1px solid #c96c01;
  }

  &:active {
    background-color: #feb867;
    border: 1px solid #feb867;
  }

  &:disabled {
    color: #b9acb4;
    background-color: #e9e2e7;
    border: 1px solid #e9e2e7;
  }
`
