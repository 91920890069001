import { CardProviderNameEnum } from "../interfaces"
import FlashLogo from "../../../assets/images/logos/flash.png"
import MasterCardLogo from "../../../assets/images/logos/mastercard.png"

export const CardProviderLogo = ({
  provider,
}: {
  provider: CardProviderNameEnum;
}) => {
  const providerLogoMap = {
    [CardProviderNameEnum.FLASH]: {
      src: FlashLogo,
      width: "70px",
    },
    [CardProviderNameEnum.MASTER]: {
      src: MasterCardLogo,
      width: "36px",
    },
  }

  return (
    <img
      src={providerLogoMap[provider].src}
      width={providerLogoMap[provider].width}
      alt={`Logo do Cartão ${provider}`}
    />
  )
}
