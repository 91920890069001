import { useQuery } from "@apollo/client"
import {
  Loader,
  PageContainer,
  Tab,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"
import styled from "styled-components"
import { SELECT_GROUP_CARD_MODEL } from "../../api/queries/select-group-card-model"
import dispatchToast from "../../utils/dispatchToast"
import { useEmployeePermissions } from "../../utils/permissions"
import { useCardsPageContext } from "./Context/CardsContext"
import { Header } from "./Header"
import { ReplastBanner } from "./Header/ReplastBanner"
import { Report } from "./Report"
import { CardOrdersTable } from "./Tables/CardOrdersTable/CardOrdersTable"
import { CardsTable } from "./Tables/CardsTable/CardsTable"

export const Cards = () => {
  const { setGroupCardModel, groupCardModel } = useCardsPageContext()

  const viewCardOrdersTable = useEmployeePermissions([
    "operations_view_card_orders",
  ])
  const viewCarsTable = useEmployeePermissions(["operations_view_cards"])
  const tabItens = []
  if (viewCardOrdersTable) {
    tabItens.push({
      label: <StyledLabel> Pedidos </StyledLabel>,
      component: <CardOrdersTable />,
    })
  }

  if (viewCarsTable) {
    tabItens.push({
      label: <StyledLabel> Cartões </StyledLabel>,
      component: <CardsTable />,
    })
  }

  const { selectedCompany } = useSelectedCompany()

  const { loading } = useQuery(SELECT_GROUP_CARD_MODEL, {
    variables: { companyId: selectedCompany.id },
    onCompleted: (data) => {
      const { selectGroupCardModel } = data
      if (data?.selectGroupCardModel) {
        setGroupCardModel(selectGroupCardModel)
      }
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: "Ocorreu um erro ao buscar os dados da empresa.",
      })
    },
  })

  return (
    <PageContainer
      style={{ height: "fit-content", minHeight: "100%", width: "100%" }}
    >
      {loading || !groupCardModel.cardProviders?.length ? (
        <Loader />
      ) : (
        <>
          <ReplastBanner />
          <Header />
          <Report />
          <StyledTab tabItens={tabItens} />
        </>
      )}
    </PageContainer>
  )
}

const StyledTab = styled(Tab)`
  margin-top: 40px;
`

const StyledLabel = styled(Typography).attrs({
  variant: "body3",
  style: { fontWeight: "bolder" },
})``
