import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useState } from "react"
import styled from "styled-components"
import { StyledTagCheckBoxFilter } from "../styles"

export interface FilterOption {
  label: string;
  value: string;
}

export interface FilterField {
  id: string;
  checked: boolean;
  options?: FilterOption[];
  values?: string[];
  label: string;
}

export interface Filter {
  [key: string]: string[];
}
export interface FilterInput {
  id: string;
  values: string;
}

export const TableFilters = ({
  fields,
  onFilterUpdate,
}: {
  fields: FilterField[];
  onFilterUpdate: (filter: Filter) => void;
}) => {
  const [filterState, setFilterState] = useState({})

  const handleFilterCheck = (filter: FilterInput) => {
    const currentFilterState = filterState
    const newFilter = {}

    if (filter.values.length === 0) {
      delete currentFilterState[filter.id]
    } else {
      newFilter[filter.id] = filter.values
    }

    const newFilterState = {
      ...currentFilterState,
      ...newFilter,
    }

    setFilterState(newFilterState)
    onFilterUpdate(newFilterState)
  }

  const filterList = fields
    ? fields?.map((field, index) => {
        const options = field?.options?.map((option) => {
          return {
            label: option?.label,
            value: option?.value,
            checked: false,
          }
        })
        return (
          <StyledTagCheckBoxFilter
            variant={"secondary"}
            options={options}
            key={index}
            hasLeftIcon={false}
            filterLabel={field?.label}
            onClick={(values) => handleFilterCheck({ id: field?.id, values })}
            style={{ marginBottom: "16px" }}
          ></StyledTagCheckBoxFilter>
        )
      })
    : []

  return (
    <TableFiltersContainer>
      <Typography variant="body3">Filtrar por</Typography>
      {filterList}
    </TableFiltersContainer>
  )
}

export const TableFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 32px;
  width: 100%;
  height: 40px;
  align-items: center;
`
