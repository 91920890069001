const cnpjMask = (value: string) => {
  return value
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
}

const cpfMask = (value: string) => {
  return value
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
}

const onlyNumbers = (value: string) => {
  return value.replace(/\D+/g, "")
}

export const toDateString = (date: Date) => {
  return new Intl.DateTimeFormat("pt-BR").format(date)
}

const capitalizeWords = (str?: string) => {
  if (!str) return ""
  const splitStr = str.toLowerCase().split(" ")
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }

  return splitStr.join(" ")
}

const isValidEmail = (email: string) => {
  const filter =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  return filter.test(email)
}

export function isValidPhone(phone: string) {
  return /^\(?0?[0-9]{2}\)?\s?[0-9]{4,5}-?[0-9]{4}$/.test(phone)
}

const formatPhone = (phone: string) => phone.replace(/[()\-\s+]/g, "")

const isValidCep = (cep: string | undefined) => {
  if (!cep) return false
  return cep.replace("-", "").length === 8
}

export const sanitizedZipCode = (zipCode: string) =>
  zipCode.replace("-", "").length === 7 ? `0${zipCode}` : zipCode

function flatten<T>(arr: Array<T>) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten,
    )
  }, [])
}

export const specialCharactersValidation = (input: string) => {
  const regex = /[^a-zA-Z0-9\sÀ-ÖØ-öø-ÿ-,.]/
  return !regex.test(input)
}

export const addressNumberValidation = (input: string) => {
  const regex = /^([0-9]+[a-zA-Z]*|[sS]\/[nN])$/
  return regex.test(input)
}

export const removeDuplicateSpaces = (input: string) =>
  input.replace(/  +/g, " ")

export const inputPhoneMask = (phone: string) =>
  phone
    .replace(/\D/g, "")
    .replace(/^(\d)/, "($1")
    .replace(/^(\(\d{2})(\d)/, "$1) $2")
    .replace(/(\d{4,5})(\d{4})/, "$1-$2")
    .replace(/(-\d{4})\d*?$/, "$1")

export const isValidCpf = (cpf: string) => {
  cpf = onlyNumbers(cpf)
  const firstMultiplier = [10, 9, 8, 7, 6, 5, 4, 3, 2]
  const secondMultiplier = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2]

  const firstSum = cpf
    .split("")
    .slice(0, 9)
    .map((digit, index) => {
      const intDigit = parseInt(digit)
      return intDigit * firstMultiplier[index]
    })
    .reduce((acc, digit) => (acc += digit), 0)
  let firstMod = (firstSum * 10) % 11
  if (firstMod === 10 || firstMod === 11) {
    firstMod = 0
  }
  if (firstMod !== parseInt(cpf.slice(9, 10))) {
    return false
  }

  const secondSum = cpf
    .split("")
    .slice(0, 10)
    .map((digit, index) => {
      const intDigit = parseInt(digit)
      return intDigit * secondMultiplier[index]
    })
    .reduce((acc, digit) => (acc += digit), 0)
  let secondMod = (secondSum * 10) % 11
  if (secondMod === 10 || secondMod === 11) {
    secondMod = 0
  }
  if (secondMod !== parseInt(cpf.slice(10, 11))) {
    return false
  }

  return true
}

const formatCardNumber = (cardNumber: string) => {
  return cardNumber.replace(/(\d{4})/g, "$1 ").trim()
}

export {
  capitalizeWords,
  cnpjMask,
  cpfMask,
  flatten,
  formatCardNumber,
  formatPhone,
  isValidCep,
  isValidEmail,
  onlyNumbers
}

