import { useLazyQuery } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  GET_EMPLOYEE_VIRTUAL_CARD_PCI,
  GetEmployeeVirtualCardPciData,
} from "../../../api/queries/get-employee-virtual-card-pci"
import dispatchToast from "../../../utils/dispatchToast"
import { useMyCardsPageContext } from "../Context/MyCardsContext"
import { ModalHeaderStyled } from "../Tables/styled"
import {
  ForgotPasswordMessageStyled,
  ModalContentStyled,
  ModalWarningStyled,
  ModalWarningTextStyled,
  TexInputHelperStyled,
  TextInputContainerStyled,
} from "./styled"

export const ConfirmTransactionPasswordPciModal = () => {
  const {
    setPciModalVisible,
    setVirtualCardSelected,
    setVirtualCardPciInfo,
    virtualCardSelected,
    transactionPassword,
    setTransactionPassword,
  } = useMyCardsPageContext()

  const [
    getEmployeeVirtualCardPci,
    { loading: getEmployeeVirtualCardPciLoading },
  ] = useLazyQuery<GetEmployeeVirtualCardPciData>(
    GET_EMPLOYEE_VIRTUAL_CARD_PCI,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data?.getEmployeeVirtualCardPci) {
          setVirtualCardPciInfo(data.getEmployeeVirtualCardPci)
          handleOnClose()
        }
      },
      onError: () => {
        dispatchToast({
          type: "error",
          title: "Ocorreu um erro ao buscar os dados sensíveis do cartão",
        })
      },
    },
  )

  const handleGetPciCardInfo = () => {
    getEmployeeVirtualCardPci({
      variables: {
        financeCardId: virtualCardSelected.financeCardId,
        transactionPassword: transactionPassword,
      },
    })
  }

  const handleOnClose = () => {
    setVirtualCardSelected(null)
    setPciModalVisible(false)
    setTransactionPassword(null)
  }

  const handlePasswordChange = (value: string) => {
    if (value.length < 5) {
      setTransactionPassword(value)
    }
  }

  return (
    <Modal.Root onClose={handleOnClose} open={true}>
      <>
        <ModalHeaderStyled>
          <ModalWarningStyled>
            <Icons
              name="IconAlertCircle"
              fill="transparent"
              color="orange"
              size={16}
            />
            <ModalWarningTextStyled variant="body4">
              Atenção!
            </ModalWarningTextStyled>
          </ModalWarningStyled>
          <Modal.Header title={titulo} description={descricao} />
        </ModalHeaderStyled>
        <Modal.Content>
          <ModalContentStyled>
            <TextInputContainerStyled>
              <PasswordField
                style={{ width: "422px" }}
                label={textoDentroInput}
                onChange={({ target: { value } }) => {
                  handlePasswordChange(value)
                }}
                value={transactionPassword}
              ></PasswordField>
              <TexInputHelperStyled variant="caption">
                {textoAbaixoInput}
              </TexInputHelperStyled>
            </TextInputContainerStyled>
            <ForgotPasswordMessageStyled variant="caption">
              {mensagemEsqueceuSenha}
              <LinkButton variant={"default"} size="small">
                {linkAbaixoEsqueceuSenha}
              </LinkButton>
            </ForgotPasswordMessageStyled>
          </ModalContentStyled>
        </Modal.Content>
        <Modal.Footer>
          <LinkButton variant="neutral" onClick={handleOnClose}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            loading={getEmployeeVirtualCardPciLoading}
            onClick={handleGetPciCardInfo}
          >
            {textoConfirmar}
            <Icons name="IconCheck"></Icons>
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const titulo = "Acessar informações do seu cartão"
const descricao =
  "Para visualizar as informações do seus cartões, digite sua senha de transação."
const textoAbaixoInput =
  "A mesma senha que você utiliza para realizar as compras físicas com o cartão e transações no app."
const textoDentroInput = "Insira sua senha de transação"
const mensagemEsqueceuSenha =
  "Não lembra da sua senha? Entre em contato com o suporte pelo e-mail"
const linkAbaixoEsqueceuSenha = "falecom@flashapp.com.br"
const textoConfirmar = "Acessar informações"
