import { AssociatedBalanceEnum } from "../interfaces"

export const getPhysicalCardsBusinessUnit = (hasBenefits: boolean, hasCorporateCards: boolean): AssociatedBalanceEnum => {
  if (hasBenefits && hasCorporateCards) {
    return AssociatedBalanceEnum.physical
  }

  if (hasBenefits) {
    return AssociatedBalanceEnum.benefits
  }

  if (hasCorporateCards) {
    return AssociatedBalanceEnum.expense
  }

  return AssociatedBalanceEnum.physical
}