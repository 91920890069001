import { gql } from "@apollo/client"
import { CardProviderEnum } from "../../pages/globalInterfaces"
import {
  BusinessUnitEnum,
  CardStatusEnum,
} from "../../pages/MyCards/interfaces"

export const GET_EMPLOYEE_VIRTUAL_CARDS = gql`
  query getEmployeeVirtualCards(
    $limit: Int
    $skip: Int
    $filter: Operations_Cards_GetEmployeeCardsFilterInput
  ) {
    getEmployeeVirtualCards(limit: $limit, skip: $skip, filter: $filter) {
      cards {
        provider
        last4Digits
        nameOnCard
        businessUnit
        status
        expirationDate
        financeCardId
        international
        nfc
        bpCardId
      }
      totalCards
    }
  }
`

export type GetEmployeeVirtualCardsData = {
  getEmployeeVirtualCards: {
    cards: Array<{
      provider: CardProviderEnum
      last4Digits: string
      nameOnCard: string
      businessUnit: BusinessUnitEnum
      status: CardStatusEnum
      expirationDate: string
      financeCardId: string
      international: boolean
      nfc: boolean
      bpCardId: string
    }>
    totalCards: number
  }
}
