import { gql } from "@apollo/client"

export const CHECK_REPLAST_BANNER_STATUS = gql`
  query CheckReplastBannerStatus($companyId: String!) {
    checkReplastBannerStatus(companyId: $companyId) {
      shouldShowOrderInProgressBanner
      shouldShowOrderDeliveredBanner
    }
  }
`

export type CheckReplastBannerStatusResponse = {
  checkReplastBannerStatus: {
    shouldShowOrderInProgressBanner: boolean;
    shouldShowOrderDeliveredBanner: boolean;
  };
};
