import { gql } from "@apollo/client"

export const CREATE_VIRTUAL_CARD_PCI = gql`
  mutation createVirtualCardPci(
    $createVirtualCardPci: Employee_CreateVirtualCardPciInput!
  ) {
    createVirtualCardPci(
      createVirtualCardPci: $createVirtualCardPci
    )
  }
`

export interface Employee_CreateVirtualCardPciInput {
  transactionPassword: String,
}
