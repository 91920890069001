import { gql } from "@apollo/client"

export const TOGGLE_INTERNATIONAL_PCI = gql`
  mutation cardInternationalTogglePci(
    $cardInternationalTogglePci: Employee_CardInternationalTogglePciInput!
  ) {
    cardInternationalTogglePci(
      cardInternationalTogglePci: $cardInternationalTogglePci
    )
  }
`

export interface Employee_CardInternationalTogglePciInput {
  enabled: Boolean
  origin: String
  transactionPassword: String
  financeCardId: String
  printedId: String
}
