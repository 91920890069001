import { gql } from "@apollo/client"
import { CardProviderEnum } from "../../pages/globalInterfaces"

export const SELECT_GROUP_CARD_MODEL = gql`
  query SelectGroupCardModel($companyId: String!) {
    selectGroupCardModel(companyId: $companyId) {
      cardLayout
      cardProviders
    }
  }
`

export type SelectGroupCardModelData = {
  cardLayout: string;
  cardProviders: CardProviderEnum[];
};
