import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react"

interface CardOperationsState {
  printedId?: String
  financeCardId?: String
  cardType?: CardTypeEnum
  international?: Boolean
  nfc?: Boolean
  bpCardId?: String
}

export enum CardTypeEnum {
  virtual = "virtual",
  physical = "physical",
}

interface CardOperationsContextData {
  // Card Lock
  cardLockPciVirtualModalVisible?: boolean
  setCardLockPciVirtualModalVisible?: Dispatch<SetStateAction<boolean>>

  cardLockPciPhysicalModalVisible?: boolean
  setCardLockPciPhysicalModalVisible?: Dispatch<SetStateAction<boolean>>

  cardLockPciVariables?: CardOperationsState
  setCardLockPciVariables?: Dispatch<SetStateAction<CardOperationsState>>

  // Card Unlock
  cardUnlockPciPhysicalModalVisible?: boolean
  setCardUnlockPciPhysicalModalVisible?: Dispatch<SetStateAction<boolean>>

  cardUnlockPciVirtualModalVisible?: boolean
  setCardUnlockPciVirtualModalVisible?: Dispatch<SetStateAction<boolean>>

  cardUnlockPciVariables?: CardOperationsState
  setCardUnlockPciVariables?: Dispatch<SetStateAction<CardOperationsState>>

  // Card Hard Unlock
  cardHardUnlockModalVisible?: boolean
  setCardHardUnlockModalVisible?: Dispatch<SetStateAction<boolean>>

  // Card Toggle International
  toggleInternationalPhysicalModalVisible?: boolean
  setToggleInternationalPhysicalModalVisible?: Dispatch<SetStateAction<boolean>>

  toggleInternationalVirtualModalVisible?: boolean
  setToggleInternationalVirtualModalVisible?: Dispatch<SetStateAction<boolean>>

  toggleInternationalVariables?: CardOperationsState
  setToggleInternationalVariables?: Dispatch<SetStateAction<CardOperationsState>>

  // Card Toggle NFC
  toggleNfcPhysicalModalVisible?: boolean
  setToggleNfcPhysicalModalVisible?: Dispatch<SetStateAction<boolean>>

  toggleNfcVirtualModalVisible?: boolean
  setToggleNfcVirtualModalVisible?: Dispatch<SetStateAction<boolean>>

  toggleNfcVariables?: CardOperationsState
  setToggleNfcVariables?: Dispatch<SetStateAction<CardOperationsState>>

  // Card Update Transacion Password
  updateTransacPsswdModalVisible?: boolean
  setUpdateTransacPsswdModalVisible?: Dispatch<SetStateAction<boolean>>

  updateTransacPsswdVariables?: CardOperationsState
  setUpdateTransacPsswdVariables?: Dispatch<SetStateAction<CardOperationsState>>

  // Create Virtual Card
  createVirtualCardModalVisible?: boolean
  setCreateVirtualCardModalVisible?: Dispatch<SetStateAction<boolean>>

  // Delete Virtual Card
  deleteVirtualCardModalVisible?: boolean
  setDeleteVirtualCardModalVisible?: Dispatch<SetStateAction<boolean>>

  deleteVirtualCardVariables?: CardOperationsState
  setDeleteVirtualCardVariables?: Dispatch<SetStateAction<CardOperationsState>>
}

interface GenericProviderProps {
  children: ReactNode
}

export const CardOperationsContext = createContext<CardOperationsContextData>({})

export const CardOperationsProvider = ({ children }: GenericProviderProps) => {

  const [cardLockPciVirtualModalVisible, setCardLockPciVirtualModalVisible] = useState(false)
  const [cardLockPciPhysicalModalVisible, setCardLockPciPhysicalModalVisible] = useState(false)

  const [cardUnlockPciPhysicalModalVisible, setCardUnlockPciPhysicalModalVisible] = useState(false)
  const [cardUnlockPciVirtualModalVisible, setCardUnlockPciVirtualModalVisible] = useState(false)

  const [cardHardUnlockModalVisible, setCardHardUnlockModalVisible] = useState(false)

  const [toggleInternationalPhysicalModalVisible, setToggleInternationalPhysicalModalVisible] = useState(false)
  const [toggleInternationalVirtualModalVisible, setToggleInternationalVirtualModalVisible] = useState(false)

  const [toggleNfcPhysicalModalVisible, setToggleNfcPhysicalModalVisible] = useState(false)
  const [toggleNfcVirtualModalVisible, setToggleNfcVirtualModalVisible] = useState(false)

  const [updateTransacPsswdModalVisible, setUpdateTransacPsswdModalVisible] = useState(false)

  const [createVirtualCardModalVisible, setCreateVirtualCardModalVisible] = useState(false)
  
  const [deleteVirtualCardModalVisible, setDeleteVirtualCardModalVisible] = useState(false)
  
  const [
    cardLockPciVariables,
    setCardLockPciVariables,
  ] = useState(null)

  const [
    cardUnlockPciVariables,
    setCardUnlockPciVariables,
  ] = useState(null)

  const [
    toggleInternationalVariables,
    setToggleInternationalVariables,
  ] = useState(null)

  const [
    toggleNfcVariables,
    setToggleNfcVariables,
  ] = useState(null)

  const [
    updateTransacPsswdVariables,
    setUpdateTransacPsswdVariables,
  ] = useState(null)

  const [
    deleteVirtualCardVariables,
    setDeleteVirtualCardVariables,
  ] = useState(null)

  return (
    <CardOperationsContext.Provider
      value={{
        cardLockPciVirtualModalVisible,
        setCardLockPciVirtualModalVisible,
        cardLockPciPhysicalModalVisible, 
        setCardLockPciPhysicalModalVisible,
        cardUnlockPciPhysicalModalVisible,
        setCardUnlockPciPhysicalModalVisible,
        cardUnlockPciVirtualModalVisible, 
        setCardUnlockPciVirtualModalVisible,
        cardLockPciVariables,
        setCardLockPciVariables,
        cardUnlockPciVariables,
        setCardUnlockPciVariables,
        cardHardUnlockModalVisible, 
        setCardHardUnlockModalVisible,
        toggleInternationalPhysicalModalVisible, 
        setToggleInternationalPhysicalModalVisible,
        toggleInternationalVirtualModalVisible, 
        setToggleInternationalVirtualModalVisible,
        toggleInternationalVariables,
        setToggleInternationalVariables,
        toggleNfcPhysicalModalVisible, 
        setToggleNfcPhysicalModalVisible,
        toggleNfcVirtualModalVisible, 
        setToggleNfcVirtualModalVisible,
        toggleNfcVariables,
        setToggleNfcVariables,
        updateTransacPsswdModalVisible,
        setUpdateTransacPsswdModalVisible,
        updateTransacPsswdVariables,
        setUpdateTransacPsswdVariables,
        createVirtualCardModalVisible, 
        setCreateVirtualCardModalVisible,
        deleteVirtualCardModalVisible, 
        setDeleteVirtualCardModalVisible,
        deleteVirtualCardVariables,
        setDeleteVirtualCardVariables,
      }}
    >
      {children}
    </CardOperationsContext.Provider>
  )
}

export const useCardOperationsContext = () => {
  const context = useContext(CardOperationsContext)

  return context
}