import { useQuery } from "@apollo/client"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"
import { useEffect } from "react"
import { SELECT_GROUP_CARD_MODEL } from "../../../api/queries/select-group-card-model"
import { CenteredSpinner } from "../../../components/CenteredSpinner"
import dispatchToast from "../../../utils/dispatchToast"
import { useMyCardsPageContext } from "../Context/MyCardsContext"
import { ActivateFirstCardScreen } from "../Screens/ActivateFirstCardScreen"
import { MyCardsTablesScreen } from "../Screens/MyCardsTablesScreen"

export const MyBenefitsCards = () => {
  const {
    setGroupCardModel,
    setUserHasCards,
    userHasCards,
    refetchEmployeePhysicalCards,
    refetchEmployeeVirtualCards,
    getEmployeePhysicalCardsData,
    getEmployeeVirtualCardsData,
    getEmployeePhysicalCardsLoading,
    getEmployeeVirtualCardsLoading,
  } = useMyCardsPageContext()
  const { selectedCompany } = useSelectedCompany()

  const { loading: groupCardModelLoading } = useQuery(SELECT_GROUP_CARD_MODEL, {
    variables: { companyId: selectedCompany.id },
    onCompleted: (data) => {
      const { selectGroupCardModel } = data
      if (data?.selectGroupCardModel) {
        setGroupCardModel(selectGroupCardModel)
      }
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: "Ocorreu um erro ao buscar os dados da empresa.",
      })
    },
  })

  const checkIfUserHasCards = () => {
    refetchEmployeePhysicalCards()
    refetchEmployeeVirtualCards()
  }

  useEffect(() => {
    checkIfUserHasCards()
  }, [])

  useEffect(() => {
    if (userHasCards) return
    if (getEmployeePhysicalCardsData?.getEmployeePhysicalCards?.totalCards > 0 || getEmployeeVirtualCardsData?.getEmployeeVirtualCards?.totalCards > 0  ) {
      setUserHasCards(true)
    }
    else if (getEmployeePhysicalCardsData?.getEmployeePhysicalCards?.totalCards === 0 && getEmployeeVirtualCardsData?.getEmployeeVirtualCards?.totalCards === 0) {
      setUserHasCards(false)
    }
  }, [getEmployeePhysicalCardsData, getEmployeeVirtualCardsData])

  const loading = groupCardModelLoading || getEmployeePhysicalCardsLoading || getEmployeeVirtualCardsLoading

  return (
    <>
      {loading ? (
        <CenteredSpinner />
      ) : (
        <>
          {userHasCards ? (
            <MyCardsTablesScreen />
          ) : (
            <ActivateFirstCardScreen />
          )}
        </>
      )}
    </>
  )
}