import { useMutation } from "@apollo/client"
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  PasswordField,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useContext, useEffect, useState } from "react"
import { CARD_LOCK_PCI } from "../../../api/mutations/card-lock-pci"
import dispatchToast from "../../../utils/dispatchToast"
import {
  CardOperationsContext,
  CardTypeEnum,
} from "../Context/CardOperationsContext"
import { ModalHeaderStyled } from "../Tables/styled"
import {
  ForgotPasswordMessageStyled,
  ModalContentStyled,
  ModalDescriptionStyled,
  ModalMessageStyled,
  ModalTitleStyled,
  ModalWarningStyled,
  ModalWarningTextStyled,
  TextInputContainerStyled,
} from "./styled"
import { hasNonConsecutiveDigits, hasFourDigits } from "../../../utils/psswd"

export const LOCK_MODAL = 'lock'

export const CardLockPciModal = ({ handleOnCloseModals }) => {
  const {
    cardLockPciVariables,
    setCardLockPciVariables,
  } = useContext(CardOperationsContext)

  const [transactionPassword, setTransactionPassword] = useState(null)
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const [
    cardLockPciMutation,
    { loading }
  ] = useMutation(
    CARD_LOCK_PCI,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        result
          ? dispatchToast({
            type: "success",
            title: "Cartão bloqueado com sucesso",
          })
          : errorToast()
        handleOnClose(true)
      },
      onError: (error) => {
        handleOnClose(false)
        errorToast()
      }
    }
  )

  const errorToast = () =>
    dispatchToast({
      type: "error",
      title: "Erro ao bloquear o cartão",
      description: "Tente novamente mais tarde"
    })

  const handleCardLockPciInfo = () => {
    cardLockPciMutation({
      variables: {
        cardLockPci: {
          lockReason: "blockRequestedByEmployee",
          origin: "flashos",
          financeCardId: cardLockPciVariables.financeCardId,
          transactionPassword,
          ...(cardLockPciVariables.printedId && { printedId: cardLockPciVariables.printedId })
        },
      },
    })
  }

  const handleOnClose = (needRefetch: boolean) => {
    handleOnCloseModals(LOCK_MODAL, needRefetch)
    setCardLockPciVariables(null)
    setTransactionPassword(null)
  }

  const handlePasswordChange = (value: string) => {
    if (hasFourDigits(value)) {
      setTransactionPassword(value)
    }
  }

  useEffect(() => {
    if (hasFourDigits(transactionPassword)) {
      setConfirmDisabled(false)
    }
  }, [transactionPassword])

  return (
    <Modal.Root onClose={() => handleOnClose(false)} open={true}>
      <>
        <ModalHeaderStyled>
          <ModalWarningStyled>
            <Icons
              name="IconAlertCircle"
              fill="transparent"
              color="orange"
              size={16}
            />
            <ModalWarningTextStyled variant="body4">Atenção!</ModalWarningTextStyled>
          </ModalWarningStyled>
          <ModalMessageStyled>
            <ModalTitleStyled variant="headline8">{cardLockPciVariables?.cardType === CardTypeEnum.physical ? physicalCardTitle : virtualCardTitle}</ModalTitleStyled>
            <ModalDescriptionStyled>{titleDescription}</ModalDescriptionStyled>
          </ModalMessageStyled>
        </ModalHeaderStyled>
        <Modal.Content>
          <ModalContentStyled>
            <TextInputContainerStyled>
              <PasswordField
                style={{ width: "422px" }}
                label={transactionPasswordInputLabel}
                onChange={({ target: { value } }) => {
                  handlePasswordChange(value)
                }}
                error={transactionPassword && !hasNonConsecutiveDigits(transactionPassword)}
                value={transactionPassword}
                helperText={transactionPasswordInputDescription}
              ></PasswordField>
            </TextInputContainerStyled>
            <ForgotPasswordMessageStyled variant="caption">
              {forgotPasswordLabel}
              <LinkButton variant={"default"} size="small">
                {forgetPasswordEmail}
              </LinkButton>
            </ForgotPasswordMessageStyled>
          </ModalContentStyled>
        </Modal.Content>
        <Modal.Footer>
          <LinkButton variant="default" onClick={() => handleOnClose(false)}>
            Cancelar
          </LinkButton>
          <Button
            variant="primary"
            size="large"
            disabled={confirmDisabled}
            loading={loading}
            onClick={handleCardLockPciInfo}
          >
            {confirmButton}
            <Icons
              name="IconCheck"
              fill="transparent"
              color="#ffffff"
              size={14}
            />
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const physicalCardTitle = "Tem certeza que deseja bloquear seu cartão?"
const virtualCardTitle = "Tem certeza que deseja bloquear seu cartão virtual?"
const titleDescription =
  "Enquanto estiver bloqueado, nenhuma compra realizada neste cartão será aprovada."
const transactionPasswordInputLabel = "Insira sua senha de transação"
const transactionPasswordInputDescription =
  "A mesma senha que você utiliza para realizar as compras físicas com o cartão e transações no app."
const forgotPasswordLabel =
  "Não lembra da sua senha? Entre em contato com o suporte pelo e-mail"
const forgetPasswordEmail = "falecom@flashapp.com.br"
const confirmButton = "Confirmar bloqueio"
