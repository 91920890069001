import styled from "styled-components"

export const HeaderContainer = styled.div`
  margin-top: 34px;
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`
