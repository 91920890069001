import { gql } from "@apollo/client"

export const UPDATE_TRANSACTION_PSSWD = gql`
  mutation updateTransactionPasswordPci(
    $updateTransactionPasswordPci: Employee_UpdateTransactionPasswordPciInput!
  ) {
    updateTransactionPasswordPci(
      updateTransactionPasswordPci: $updateTransactionPasswordPci
    )
  }
`

export interface Employee_UpdateTransactionPasswordPciInput {
  financeCardId: String
  oldTransactionPassword: String
  newTransactionPassword: String
}
