import { gql } from "@apollo/client"
import { CardProviderEnum } from "../../pages/globalInterfaces"
import {
  BusinessUnitEnum,
  CardStatusEnum,
} from "../../pages/MyCards/interfaces"

export const GET_EMPLOYEE_PHYSICAL_CARDS = gql`
  query getEmployeePhysicalCards(
    $limit: Int
    $skip: Int
    $filter: Operations_Cards_GetEmployeeCardsFilterInput
  ) {
    getEmployeePhysicalCards(limit: $limit, skip: $skip, filter: $filter) {
      cards {
        provider
        printedId
        businessUnit
        status
        financeCardId
        international
        nfc
      }
      totalCards
      hasBenefitsBalance
      hasExpenseBalance
    }
  }
`

export type GetEmployeePhysicalCardsData = {
  getEmployeePhysicalCards: {
    cards: Array<{
      provider: CardProviderEnum
      printedId: string
      businessUnit: BusinessUnitEnum
      status: CardStatusEnum
      financeCardId: string
      international: boolean
      nfc: boolean
    }>
    totalCards: number
    hasBenefitsBalance: boolean
    hasExpenseBalance: boolean
  }
}
