import * as forge from "node-forge"
import { CompanyLS } from "./localStorage.interfaces"

function encode(str: string) {
  return forge.util.encode64(forge.util.encodeUtf8(str))
}

function decode(encoded: string) {
  return encoded ? forge.util.decodeUtf8(forge.util.decode64(encoded)) : ""
}

function getActiveCompanyFromLS() {
  return {
    subOrdersEnabled: isSubOrdersEnabled(),
  }
}

function isSubOrdersEnabled() {
  const clientData = getClientDataFromLS() as CompanyLS
  return clientData?.featureConfig?.subOrdersEnabled || false
}

function getClientDataFromLS() {
  const value = decode(localStorage.getItem(encode("clientData"))) as string
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

export { decode, encode, getActiveCompanyFromLS }

